import jwt_decode from "jwt-decode";
import axios from 'axios';
import moment from 'moment';

export default {
  state: {
    reminders:[]
  },
  mutations: {
    SET_REMINDERS(state, payload) {
        state.reminders = payload;
    }
  },
  getters: {
    REMINDERS(state){
      return state.reminders;
    },

  },
  actions: {
   setReminders({ commit }, payload) {
      commit("SET_REMINDERS", payload);
    },
    async resetReminders({commit}){
      try{
        let {data:rData} =  await axios.get('/api/reminders/all');

      if(rData == null) return;
      if(rData.items == null) return;
      if( rData.items.length > 0 ){
        let formReminders = rData && rData.items.filter((item)=>item.type == 'form');
        for(let i = 0; i < formReminders.length;i++){
          let fData = await axios.get(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_form_fills/${formReminders[i].formId}`);
          let filteredFills = fData.data.items.filter((item)=>{
            return moment(item.created_at).isAfter(moment(moment().format('MM/DD/YYYY')))
          })
          if(filteredFills.length){
            await axios.put(`/api/reminders/edit/${formReminders[i]._id}`, { dismissed:true })
            console.log('HAS A FORM FILL')
          }else{
            console.log('DOES NOT HAVE A FORM FILL')
          }
        }
        
        let {data:sData} =  await axios.get('/api/reminders/all');
        commit("SET_REMINDERS", sData.items);
      }
      }catch(e){
        console.log(e);
      }
      
      
    }
  }
};
