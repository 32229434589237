<template>
    <div class="inner-stage" style="background: #f8fafb; display:flex; justify-content: center;">
        
        <div v-if="isLoading"><v-progress-circular color="#eaeaea" indeterminate :size="50" :width="5"></v-progress-circular></div>
        <div v-if="formDetails != null && !isLoading" class="form-container" style="max-width:800px !important;">
            <div class="f-name"><i class="fad fa-file"></i>{{formDetails.name}}</div>
            <div class="f-instructions" v-if="formDetails.instructions != null"><i class="fad fa-info-circle"></i><span v-html="formDetails.instructions"></span></div>
            <div class="f-form">
                <div v-for="(section, s) in formDetails.layout" :class="section.className != null ? section.className : ''" :key="s" class="f-section" >
                    <div class="s-title" v-if="section.section_name != ''">{{ section.section_name }}</div>
                    <div class="s-desc" v-if="section.alternative_text != null" v-html="section.alternative_text"></div>
                    <div v-if="section.iterable == null || section.iterable == false" class="section-inner">
                        <div class="s-row" v-for="(row,r) in section.rows" :key="r">
                            <div  v-for="(item, i) in getFieldsFromRow(row.props)" class="f-item" :class="{'textarea-c':(item.type == 'textarea' || item.type == 'array'), 'date-row':item.prop == 'date', 'total-time':item.prop == 'total_time' }" :key="i">
                        <div class="f-item-inner " v-if="item.type == 'string'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill[item.prop] != 'N/A' && item.prop != 'total_time'"><v-text-field :readonly="editing" :color="appColor" hide-details style="max-width:250px; width:100%" outlined small dense v-model="formFill[item.prop]"></v-text-field></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="item.prop == 'total_time'">

                                <div v-if="formFill.start_time != '' && formFill.end_time != '' && !editing"> {{ moment(moment(formFill.date).format('MM/DD/YYYY') + ' ' + formFill.end_time).diff(moment(moment(formFill.date).format('MM/DD/YYYY') + ' ' + formFill.start_time), 'm') }} minutes </div>
                                <div v-if="editing">{{ formFill.total_time }}</div>
                            </div>
                        </div>

                        <div class="f-item-inner "  v-if="item.type == 'array'">
                            <div class="r-item" v-for="(child,c) in item.children" :key="c">
                                <div class="f-label" style="padding-left:10px;">{{ child.name }}</div>
                                <div class="f-value2">
                                <div v-for="(childField, cf) in child.fields" class="f-item"  :key="cf">
                                    <div class="f-item-inner " v-if="childField.type == 'boolean'">
                                    <div class="f-label" :class="{'has-error':childField.error}">{{ childField.label }}<span class="alt-text" v-if="childField.alternative_text != null">{{ childField.alternative_text }}</span></div>
                                    <div class="f-value" :class="{'readonly': editing}" style="display:flex; align-items:center; gap:10px;" >
                                        <div class="ynoption" :class="{'active':formFill[item.prop][c].fields[cf]}" @click="!editing ? formFill[item.prop][c].fields[cf].value = true : null">
                                            <i v-if="formFill[item.prop][c].fields[cf].value == ''" class="fal fa-square"></i> 
                                            <i v-if="formFill[item.prop][c].fields[cf].value == false && formFill[item.prop][c].fields[cf].value != ''" class="fal fa-square"></i>
                                            <i v-if="formFill[item.prop][c].fields[cf].value == true && formFill[item.prop][c].fields[cf].value != ''" class="fal fa-check-square"></i>
                                            Yes</div>
                                        <div class="ynoption" :class="{'active':(!formFill[item.prop][c].fields[cf].value || formFill[item.prop][c].fields[cf].value == '')}" @click="!editing ? formFill[item.prop][c].fields[cf].value = false : null">
                                            <i v-if="formFill[item.prop][c].fields[cf].value === ''" class="fal fa-square"></i>
                                            <i v-if="formFill[item.prop][c].fields[cf].value === true && formFill[item.prop][c].fields[cf].value.toString() != ''" class="fal fa-square"></i>
                                            <i v-if="formFill[item.prop][c].fields[cf].value === false && formFill[item.prop][c].fields[cf].value.toString() != ''" class="fal fa-check-square"></i>
                                            
                                            No</div>
                                        <!-- <v-checkbox :label="formFill[item.prop] ? 'Yes' : 'No'" :readonly="editing" :color="appColor" hide-details v-model="formFill[item.prop]"></v-checkbox> -->
                                    
                                    </div>
                                    </div>
                                    <div class="f-item-inner " v-if="childField.type == 'boolean_w_comment'">
                                    <div class="f-label" :class="{'has-error':childField.error}">{{ childField.label }}<span class="alt-text" v-if="childField.alternative_text != null">{{ childField.alternative_text }}</span></div>
                                    <div class="f-value" :class="{'readonly': editing}" style="display:flex; align-items:center; gap:10px;" >
                                        <div class="ynoption" :class="{'active':formFill[item.prop][c].fields[cf]}" @click="!editing ? formFill[item.prop][c].fields[cf].value = true : null">
                                            <i v-if="formFill[item.prop][c].fields[cf].value == ''" class="fal fa-square"></i> 
                                            <i v-if="formFill[item.prop][c].fields[cf].value == false && formFill[item.prop][c].fields[cf].value != ''" class="fal fa-square"></i>
                                            <i v-if="formFill[item.prop][c].fields[cf].value == true && formFill[item.prop][c].fields[cf].value != ''" class="fal fa-check-square"></i>
                                            Satisfactory</div>
                                        <div class="ynoption" :class="{'active':(!formFill[item.prop][c].fields[cf].value || formFill[item.prop][c].fields[cf].value == '')}" @click="!editing ? formFill[item.prop][c].fields[cf].value = false : null">
                                            <i v-if="formFill[item.prop][c].fields[cf].value === ''" class="fal fa-square"></i>
                                            <i v-if="formFill[item.prop][c].fields[cf].value === true && formFill[item.prop][c].fields[cf].value.toString() != ''" class="fal fa-square"></i>
                                            <i v-if="formFill[item.prop][c].fields[cf].value === false && formFill[item.prop][c].fields[cf].value.toString() != ''" class="fal fa-check-square"></i>
                                            
                                            Unsatisfactory</div>
                                        <v-textarea></v-textarea>
                                        <!-- <v-checkbox :label="formFill[item.prop] ? 'Yes' : 'No'" :readonly="editing" :color="appColor" hide-details v-model="formFill[item.prop]"></v-checkbox> -->
                                    
                                    </div>
                                    </div>
                                    <div class="f-item-inner " v-if="childField.type == 'string'">
                                        <div class="f-label" :class="{'has-error':childField.error}">{{ childField.label }}<span class="alt-text" v-if="childField.alternative_text != null">{{ childField.alternative_text }}</span></div>
                                        <div class="f-value" :class="{'readonly': editing}" v-if=" item.prop != 'total_time'"><v-text-field :readonly="editing" :color="appColor" hide-details style="max-width:250px; width:100%" outlined small dense v-model="formFill[item.prop]"></v-text-field></div>
                                        <div class="f-value" :class="{'readonly': editing}" v-if="item.prop == 'total_time'">

                                            <div v-if="formFill.start_time != '' && formFill.end_time != '' && !editing"> {{ moment(moment(formFill.date).format('MM/DD/YYYY') + ' ' + formFill.end_time).diff(moment(moment(formFill.date).format('MM/DD/YYYY') + ' ' + formFill.start_time), 'm') }} minutes </div>
                                            <div v-if="editing">{{ formFill.total_time }}</div>
                                        </div>
                                    </div>
                                    <div class="f-item-inner " v-if="childField.type == 'number'">
                                        <div class="f-label" :class="{'has-error':childField.error}">{{ childField.label }}<span class="alt-text" v-if="childField.alternative_text != null">{{ childField.alternative_text }}</span></div>
                                        <div class="f-value" :class="{'readonly': editing}" ><v-text-field :readonly="editing" :color="appColor" hide-details type="number" style="max-width:120px; width:100%" outlined small dense v-model="formFill[item.prop][c].fields[cf].value"></v-text-field></div>
                                    </div>
                                    <div class="f-item-inner " v-if="childField.type == 'textarea'">
                                        <div class="f-label" :class="{'has-error':childField.error}">{{ childField.label }}<span class="alt-text" v-if="childField.alternative_text != null">{{ childField.alternative_text }}</span></div>
                                        <div class="f-value textarea" :class="{'readonly': editing}" >
                                            <v-textarea  :readonly="editing" :color="appColor" hide-details style="max-width:100%; width:100%" outlined small dense v-model="formFill[item.prop][c].fields[cf].value"></v-textarea>
                                        </div>
                                    </div>
                                    <div class="f-item-inner " v-if="childField.type == 'date'">
                                        <div class="f-label" :class="{'has-error':childField.error}">{{ childField.label }}<span class="alt-text" v-if="childField.alternative_text != null">{{ childField.alternative_text }}</span></div>
                                        <div class="f-value" :class="{'readonly': editing}" >
                                            <v-menu
                                                v-model="childField.menu"
                                                :close-on-content-click="false"
                                                :readonly="editing"
                                                max-width="290"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    :color="appColor"
                                                    v-model="childField.dateFormatted"
                                                    style="padding-top:0 !important; max-width:250px;"
                                                    @blur="childField.date = parseDate(childField.dateFormatted), childField.dateFormatted = formatDate(childField.date), remindMe = 'custom'"
                                                    class="fixMe"
                                                    outlined
                                                    hide-details
                                                    small
                                                    dense
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click:clear="childField.date = null"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    :color="appColor"
                                                    :readonly="editing"
                                                    
                                                    v-model="childField.date"
                                                    @change="($event)=>{formFill[item.prop][c].fields[cf].value = $event; childField.dateFormatted = formatDate($event); childField.menu = false}"
                                                    
                                                ></v-date-picker>
                                                </v-menu>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                            </div>
                            <!-- <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value textarea">
                                <v-textarea :readonly="editing" :color="appColor" hide-details style="max-width:100%; width:100%" outlined small dense v-model="formFill[item.prop]"></v-textarea>
                            </div> -->
                        </div>
                        
                        <div class="f-item-inner " v-if="item.type == 'textarea'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value textarea" :class="{'readonly': editing}">
                                <v-textarea :readonly="editing" :color="appColor" hide-details style="max-width:100%; width:100%" outlined small dense v-model="formFill[item.prop]"></v-textarea>
                            </div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'number'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}"><v-text-field :readonly="editing" :color="appColor" hide-details type="number" style="max-width:120px; width:100%" outlined small dense v-model="formFill[item.prop]"></v-text-field></div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'date'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}">
                                <v-menu
                                    v-model="item.menu"
                                    :close-on-content-click="false"
                                    :readonly="editing"
                                    max-width="290"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        :color="appColor"
                                        v-model="item.dateFormatted"
                                        style="padding-top:0 !important; max-width:250px;"
                                        @blur="item.date = parseDate(item.dateFormatted), item.dateFormatted = formatDate(item.date), remindMe = 'custom'"
                                        class="fixMe"
                                        outlined
                                        hide-details
                                        small
                                        dense
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="item.date = null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        :color="appColor"
                                        :readonly="editing"
                                        
                                        v-model="item.date"
                                        @change="($event)=>{formFill[item.prop] = $event; item.dateFormatted = formatDate($event), item.menu = false}"
                                        
                                    ></v-date-picker>
                                    </v-menu>    
                            </div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'datetime'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill[item.prop] != 'N/A' && !editing">
                                <div style="display:flex; align-items: center; gap:5px;">
                                <input :readonly="editing" @change="setItemTime(item)" :color="appColor" class="time-input" hide-details style="max-width:40px;" name="hr"  type="number" onblur="if(Number(this.value) > 12) this.value = 12" v-model="item.hr"/>
                                :
                                <input :readonly="editing" @change="setItemTime(item)" :color="appColor" class="time-input" hide-details style="max-width:40px;" name="min" type="number" onblur="if(Number(this.value) > 59) this.value = 59 " v-model="item.min"/>
                                <v-select :readonly="editing" @change="setItemTime(item)" :color="appColor" hide-details style="max-width:90px;" outlined small dense :items="ampmOptions" v-model="item.pm"></v-select>
                            </div>
                                

                            </div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill[item.prop] != 'N/A' && editing">
                            <v-text-field outlined style="max-width:120px !important;" :color="appColor" v-model="formFill[item.prop]" small dense readonly />
                            </div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'select'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}"><v-select :readonly="editing" :color="appColor" hide-details style="max-width:250px;" :items="[ ...item.options]" outlined small dense v-model="formFill[item.prop]"></v-select></div>
                        </div>

                        <div class="f-item-inner " v-if="item.type == 'dbselect'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}">
                                <v-select :readonly="editing" :color="appColor" hide-details style="max-width:250px;" :items="lookups[item.prop] == null ?getDbSelectOptions(item): lookups[item.prop]" outlined small dense v-model="formFill[item.prop]"></v-select>
                            </div>
                        </div>
                        
                        <div class="f-item-inner " v-if="item.type == 'multi-select'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}"><v-select :readonly="editing" :color="appColor" multiple hide-details style="max-width:250px;" chips :items="[ ...item.options]" outlined small dense v-model="formFill[item.prop]">
                                <template v-slot:selection="{item:item2, index }">
                                <v-chip v-if="index < 1">
                                    <span style="width:100%; text-overflow: ellipsis; overflow:hidden;">{{ item2.text }}</span>
                                </v-chip>
                                <span
                                    v-if="index === 1"
                                    class="text-grey text-caption align-self-center"
                                >
                                    (+{{ formFill[item.prop].length - 1 }} others)
                                </span>
                                </template>
                            </v-select></div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'boolean'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" style="display:flex; align-items:center; gap:10px;">
                                <div class="ynoption" :class="{'active':formFill[item.prop]}" @click="!editing ? formFill[item.prop] = true : null">
                                    <i v-if="formFill[item.prop] == ''" class="fal fa-square"></i> 
                                    <i v-if="formFill[item.prop] == false && formFill[item.prop] != ''" class="fal fa-square"></i>
                                    <i v-if="formFill[item.prop] == true && formFill[item.prop] != ''" class="fal fa-check-square"></i>
                                    Yes</div>
                                <div class="ynoption" :class="{'active':(!formFill[item.prop] || formFill[item.prop] == '')}" @click="!editing ? formFill[item.prop] = false : null">
                                    <i v-if="formFill[item.prop] === ''" class="fal fa-square"></i>
                                    <i v-if="formFill[item.prop] === true && formFill[item.prop].toString() != ''" class="fal fa-square"></i>
                                    <i v-if="formFill[item.prop] === false && formFill[item.prop].toString() != ''" class="fal fa-check-square"></i>
                                    
                                    No</div>
                                <!-- <v-checkbox :label="formFill[item.prop] ? 'Yes' : 'No'" :readonly="editing" :color="appColor" hide-details v-model="formFill[item.prop]"></v-checkbox> -->
                            
                            </div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'boolean_w_comment'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value bool-w-comment" :class="{'readonly': editing}">
                                <div class="ynoption" :class="{'active':formFill[item.prop]}" @click="!editing ? formFill[item.prop] = true : null">
                                    <i v-if="formFill[item.prop] == ''" class="fal fa-square"></i> 
                                    <i v-if="formFill[item.prop] == false && formFill[item.prop] != ''" class="fal fa-square"></i>
                                    <i v-if="formFill[item.prop] == true && formFill[item.prop] != ''" class="fal fa-check-square"></i>
                                    Satisfactory</div>
                                <div class="ynoption" :class="{'active':(!formFill[item.prop] || formFill[item.prop] == '')}" @click="!editing ? formFill[item.prop] = false : null">
                                    <i v-if="formFill[item.prop] === ''" class="fal fa-square"></i>
                                    <i v-if="formFill[item.prop] === true && formFill[item.prop].toString() != ''" class="fal fa-square"></i>
                                    <i v-if="formFill[item.prop] === false && formFill[item.prop].toString() != ''" class="fal fa-check-square"></i>
                                    
                                    Unsatisfactory</div>
                                    <div class="comment-b">
                                    <v-textarea hide-details v-model="formFill[item.prop + '_comments']" :color="appColor" dense block  small compact  no-resize rows="1" outlined label="Comments"></v-textarea>
                                    </div>
                                    
                                <!-- <v-checkbox :label="formFill[item.prop] ? 'Yes' : 'No'" :readonly="editing" :color="appColor" hide-details v-model="formFill[item.prop]"></v-checkbox> -->
                            
                            </div>
                        </div>
                        <div class="mark-na" v-if="item.type != 'date' && item.type != 'datetime' && !editing && item.allowNa" :class="{'active': formFill[item.prop] == 'N/A'}" @click="markNA(item)">
                            N/A
                        </div>
                        <div v-if="editing && formFill[item.prop] == 'N/A'">N/A</div>
                    
                    </div>
                        </div>
                    </div>
                    <div class="section-inner iterable-item" v-for="(entry,e) in formFill.entries" :class="{'ierror':entry.error}" :key="e" v-else>
                        <div v-if="formFill.entries.length > 1 && !editing" @click="removeEntry(e)" class="remove-entry"><i class="fal fa-trash"></i></div>
                        <div class="s-row" v-for="(row,r) in section.rows" :key="r"> 
                            <div  v-for="(item, i) in section.template.filter((item)=>{return row.props.indexOf(item.prop) > -1})" class="f-item" :class="{'textarea-c':(item.type == 'textarea' || item.type == 'array'), 'date-row':item.prop == 'date', 'total-time':item.prop == 'total_time' }" :key="i">
                        <div class="f-item-inner " v-if="item.type == 'string'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill.entries[e][item.prop] != 'N/A' && item.prop != 'total_time'"><v-text-field :readonly="editing" :color="appColor" hide-details style="max-width:250px; width:100%" outlined small dense v-model="formFill.entries[e][item.prop]"></v-text-field></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="item.prop == 'total_time'">

                                <div v-if="formFill.start_time != '' && formFill.end_time != '' && !editing"> {{ moment(moment(formFill.date).format('MM/DD/YYYY') + ' ' + formFill.end_time).diff(moment(moment(formFill.date).format('MM/DD/YYYY') + ' ' + formFill.start_time), 'm') }} minutes </div>
                                <div v-if="editing">{{ formFill.total_time }}</div>
                            </div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'textarea'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value textarea" :class="{'readonly': editing}" v-if="formFill.entries[e][item.prop] != 'N/A'">
                                <v-textarea :readonly="editing" :color="appColor" hide-details style="max-width:100%; width:100%" outlined small dense v-model="formFill.entries[e][item.prop]"></v-textarea>
                            </div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'number'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill.entries[e][item.prop] != 'N/A'"><v-text-field :readonly="editing" :color="appColor" hide-details type="number" style="max-width:120px; width:100%" outlined small dense v-model="formFill.entries[e][item.prop]"></v-text-field></div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'date'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill.entries[e][item.prop] != 'N/A'">
                                <v-menu
                                    v-model="item[`menu-${e}`]"
                                    :close-on-content-click="false"
                                    :readonly="editing"
                                    max-width="290"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        :color="appColor"
                                        v-model="item[`dateFormatted-${e}`]"
                                        style="padding-top:0 !important; max-width:250px;"
                                        @blur="item[`date-${e}`] = parseDate(item[`dateFormatted-${e}`]), item[`dateFormatted-${e}`] = formatDate(item[`date-${e}`]), remindMe = 'custom'"
                                        class="fixMe"
                                        outlined
                                        hide-details
                                        small
                                        dense
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="item[`date-${e}`] = null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        :color="appColor"
                                        :readonly="editing"
                                        
                                        v-model="item[`date-${e}`]"
                                        @change="($event)=>{formFill.entries[e][item.prop] = $event; item[`dateFormatted-${e}`] = formatDate($event), item[`menu-${e}`] = false}"
                                        
                                    ></v-date-picker>
                                    </v-menu>    
                            </div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'datetime'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill.entries[e][item.prop] != 'N/A' && !editing">
                                <div style="display:flex; align-items: center; gap:5px;">
                                <input :readonly="editing" @change="setItemTime(item)" :color="appColor" class="time-input" hide-details style="max-width:40px;" name="hr"  type="number" onblur="if(Number(this.value) > 12) this.value = 12" v-model="item.hr"/>
                                :
                                <input :readonly="editing" @change="setItemTime(item)" :color="appColor" class="time-input" hide-details style="max-width:40px;" name="min" type="number" onblur="if(Number(this.value) > 59) this.value = 59 " v-model="item.min"/>
                                <v-select :readonly="editing" @change="setItemTime(item)" :color="appColor" hide-details style="max-width:90px;" outlined small dense :items="ampmOptions" v-model="item.pm"></v-select>
                            </div>
                                

                            </div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill.entries[e][item.prop] != 'N/A' && editing">
                            <v-text-field outlined style="max-width:120px !important;" :color="appColor" v-model="formFill[item.prop]" small dense readonly />
                            </div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'select'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill.entries[e][item.prop] != 'N/A'"><v-select :readonly="editing" :color="appColor" hide-details style="max-width:250px;" :items="[ ...item.options]" outlined small dense v-model="formFill.entries[e][item.prop]"></v-select></div>
                        </div>
                        
                        <div class="f-item-inner " v-if="item.type == 'multi-select'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" v-if="formFill.entries[e][item.prop] != 'N/A'"><v-select :readonly="editing" :color="appColor" multiple hide-details style="max-width:250px;" chips :items="[ ...item.options]" outlined small dense v-model="formFill.entries[e][item.prop]">
                                <template v-slot:selection="{item:item2, index }">
                                <v-chip v-if="index < 1">
                                    <span style="width:100%; text-overflow: ellipsis; overflow:hidden;">{{ item2.text }}</span>
                                </v-chip>
                                <span
                                    v-if="index === 1"
                                    class="text-grey text-caption align-self-center"
                                >
                                    (+{{ formFill.entries[e][item.prop].length - 1 }} others)
                                </span>
                                </template>
                            </v-select></div>
                        </div>
                        <div class="f-item-inner " v-if="item.type == 'boolean'">
                            <div class="f-label" :class="{'has-error':item.error}">{{ item.label }}<span class="alt-text" v-if="item.alternative_text != null">{{ item.alternative_text }}</span></div>
                            <div class="f-value" :class="{'readonly': editing}" style="display:flex; align-items:center; gap:10px;" v-if="formFill.entries[e][item.prop] != 'N/A'">
                                <div class="ynoption" :class="{'active':formFill.entries[e][item.prop]}" @click="!editing ? formFill.entries[e][item.prop] = true : null">
                                    <i v-if="formFill.entries[e][item.prop] == ''" class="fal fa-square"></i> 
                                    <i v-if="formFill.entries[e][item.prop] == false && formFill.entries[e][item.prop] != ''" class="fal fa-square"></i>
                                    <i v-if="formFill.entries[e][item.prop] == true && formFill.entries[e][item.prop] != ''" class="fal fa-check-square"></i>
                                    Yes</div>
                                <div class="ynoption" :class="{'active':(!formFill.entries[e][item.prop] || formFill.entries[e][item.prop] == '')}" @click="!editing ? formFill.entries[e][item.prop] = false : null">
                                    <i v-if="formFill.entries[e][item.prop] === ''" class="fal fa-square"></i>
                                    <i v-if="formFill.entries[e][item.prop] === true && formFill.entries[e][item.prop].toString() != ''" class="fal fa-square"></i>
                                    <i v-if="formFill.entries[e][item.prop] === false && formFill.entries[e][item.prop].toString() != ''" class="fal fa-check-square"></i>
                                    
                                    No</div>
                                <!-- <v-checkbox :label="formFill.entries[e][item.prop] ? 'Yes' : 'No'" :readonly="editing" :color="appColor" hide-details v-model="formFill.entries[e][item.prop]"></v-checkbox> -->
                            
                            </div>
                        </div>
                        <div class="mark-na" v-if="item.type != 'date' && item.type != 'datetime' && !editing && item.allowNa" :class="{'active': formFill.entries[e][item.prop] == 'N/A'}" @click="markNA(item)">
                            N/A
                        </div>
                        <div v-if="editing && formFill.entries[e][item.prop] == 'N/A'">N/A</div>
                    
                    </div>
                        </div>
                        
                    </div>
                    <v-btn :color="appColor" small depressed dark v-if="section.iterable && !editing" @click="addEntry(section.template)">Add Entry</v-btn>

                </div>



                
            </div>
            <div class="sigy">
                <div v-if="userName != ''" class="userName">{{ userName.first_name + ' ' + userName.last_name}}</div>
                <span v-if="formDetails.name == 'Medication/Substance Disposal'">Signature of client</span>
                <vueSignature
                    v-if="!editing && formDetails.name == 'Medication/Substance Disposal'"
                    ref="signature3"
                    :sigOption="option"
                    :w="'100%'"
                    :h="'150px'"
                    :disabled="disabled"
                    
                ></vueSignature>
                
                <img v-if="editing && formDetails.name == 'Medication/Substance Disposal'" :src="`/api/static/forms/${$route.params.formId}/signature3.jpg`" style="height:150px; width:100%;"/>
                <v-btn v-if="!editing && formDetails.name == 'Medication/Substance Disposal'" text small compact @click="clear(3)">Clear</v-btn>

                <span v-if="formDetails.name == 'Grievance Form'">Signature of Manager</span>
                <vueSignature
                    v-if="!editing && formDetails.name == 'Grievance Form'"
                    ref="signature3"
                    :sigOption="option"
                    :w="'100%'"
                    :h="'150px'"
                    :disabled="disabled"
                    
                ></vueSignature>
                
                <img v-if="editing && formDetails.name == 'Grievance Form'" :src="`/api/static/forms/${$route.params.formId}/signature3.jpg`" style="height:150px; width:100%;"/>
                <v-btn v-if="!editing && formDetails.name == 'Grievance Form'" text small compact @click="clear(3)">Clear</v-btn>


                <span>Signature of Staff</span>
                <vueSignature
                    v-if="!editing"
                    ref="signature"
                    :sigOption="option"
                    :w="'100%'"
                    :h="'150px'"
                    :disabled="disabled"
                    
                ></vueSignature>
                <img v-if="editing && $route.params.formId != null" :src="`/api/static/forms/${$route.params.formId}/signature.jpg`" style="height:150px; width:100%;"/>
                <v-btn v-if="!editing " text small compact @click="clear(1)">Clear</v-btn>



                <span v-if="formDetails.name == 'Medication/Substance Disposal'">Signature of Witness</span>
                <vueSignature
                    v-if="!editing && formDetails.name == 'Medication/Substance Disposal'"
                    ref="signature2"
                    :sigOption="option"
                    :w="'100%'"
                    :h="'150px'"
                    :disabled="disabled"
                    
                ></vueSignature>
                
                <img v-if="editing && formDetails.name == 'Medication/Substance Disposal'" :src="`/api/static/forms/${$route.params.formId}/signature2.jpg`" style="height:150px; width:100%;"/>
                <v-btn v-if="!editing && formDetails.name == 'Medication/Substance Disposal'" text small compact @click="clear(2)">Clear</v-btn>

                


                <div class="sig-error" v-if="sigError">Signature cannot be blank</div>
            </div>
            <div class="form-actions">
                <v-btn v-if="!editing" depressed :color="appColor" :loading="formLoading" @click="submitForm" dark>Submit Form</v-btn>
                <v-btn v-if="editing && role == 'admin'" depressed color="#ff0000" :loading="deleteLoading" @click="deleteForm" dark>Delete Form (Admin Only)</v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import vueSignature from "vue-signature";
export default {
    name:'editForm',
    components:{
        vueSignature
    },
    data(){
        return {
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
            lookups:{},
            disabled: false,
            sigError:false,
            moment,
            isLoading:true,
            formDetails:null,
            formFill:{},
            formLoading:false,
            deleteLoading:false,
            isEmpty:true,
            userName:''

        }
    },
    async mounted(){
        
        await this.getFormDetails();
        if(this.$route.params.formId != null){
            await this.getFormFill();
            this.userName = await this.getUser(this.formFill.userId)
        }
        this.isLoading = false;
    },
    methods:{
        async getUser(userId){
            try{
                let { data } = await axios.get(`/api/users/user/${userId}`);
                return data.result
            }catch(e){
                return {}
            }
        },
        async getDbSelectOptions(item){
            this.lookups[item.prop] = [];
            this.isLoading = true;
            try{
                let {data} = await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_db_select_options`, item);
                let options = data.items.map((ditem)=>{
                    return {
                        text:ditem[item.label_column],
                        value:ditem[item.value_column]
                    }
                })
                this.$nextTick(()=>{
                    
                    this.lookups[item.prop] = options;
                    this.isLoading = false;
                
                })
                return options;
               
            } catch(e){
                console.log('ERROR');
                return [];
            }
           

        },
        getFieldsFromRow(props){
            return this.formDetails.fields.filter((item)=>{
                return props.indexOf(item.prop) > -1
            })
        },
        save() {
            let png = this.$refs.signature.save();
            let jpeg = this.$refs.signature.save("image/jpeg");
            let svg = this.$refs.signature.save("image/svg+xml");
        },
        clear(number) {
            if(number == 1){
                this.$refs.signature.clear();
            }
            if(number == 2){
                this.$refs.signature2.clear();
            }
            if(number == 3){
                this.$refs.signature3.clear();
            }
        },
        undo() {
            this.$refs.signature.undo();
        },
        addWaterMark() {
            this.$refs.signature.addWaterMark({
                text: "mark text", // watermark text, > default ''
                font: "20px Arial", // mark font, > default '20px sans-serif'
                style: "all", // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
                fillStyle: "red", // fillcolor, > default '#333'
                strokeStyle: "blue", // strokecolor, > default '#333'
                x: 100, // fill positionX, > default 20
                y: 200, // fill positionY, > default 20
                sx: 100, // stroke positionX, > default 40
                sy: 200, // stroke positionY, > default 40
            });
        },
        fromDataURL(url) {
            this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
        },
        handleDisabled() {
            this.disabled = !this.disabled;
        },
        //
        async deleteForm(){
            this.deleteLoading = true;
            try{
                let {data} = await axios.delete(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/delete_form_fill/${this.$route.params.formId}`)
                this.deleteLoading = false;
                this.$router.push(`/forms/${this.$route.params.id}`);
            }catch(e){
                this.deleteLoading = false;
                window.alert('Something went wrong. Please try again later.')
            }
        },
        setItemTime(item){
            if(item.hr != null && item.min != null){
                let time = `${Number(item.hr) + Number((item.pm ? 12 : 0))}:${(Number(item.min) < 10) ? `0${Number(item.min)}` : item.min }`;
                if(item.hr == 12 && !item.pm){
                    this.formFill[item.prop] = `0:${(Number(item.min) < 10) ? `0${Number(item.min)}` : item.min }`;
                }else if(item.hr == 12 && item.pm){
                    this.formFill[item.prop] = `12:${(Number(item.min) < 10) ? `0${Number(item.min)}` : item.min }`;
                }else{
                    this.formFill[item.prop] = time;
                }
                if(this.formFill.start_time != '' && this.formFill.end_time != ''){
                    this.formFill.total_time = moment(moment(this.formFill.date).format('MM/DD/YYYY') + ' ' + this.formFill.end_time).diff(moment(moment(this.formFill.date).format('MM/DD/YYYY') + ' ' + this.formFill.start_time), 'm') + ' minutes'
                }
                
            }
        },
        markNA(item){
            if(this.formFill[item.prop] == 'N/A'){
                this.formFill[item.prop] = '';
            }else{
               this.$set(this.formFill, item.prop, 'N/A')
            }
           
        },
        async submitFollowUp(){
            this.formLoading = true;
            this.sigError = false;
            try{
                let post = this.formFill;
                let sections = this.formDetails.layout;
                let errorCheck = false;
                for(let s = 0; s < sections.length; s++){
                    if(sections[s].iterable){
                        for(let r = 0; r < this.formFill.entries.length;r++){
                            this.$set(this.formFill.entries[r], 'error', false);
                            for(let rf = 0; rf < Object.keys(this.formFill.entries[r]).length; rf++){
                                let optionalCheckPropName = Object.keys(this.formFill.entries[r])[rf];
                                let pprops = [];
                                for(let p = 0; p < this.formDetails.layout.length;p++){
                                    if(this.formDetails.layout[p].template != null){
                                        pprops =  pprops.concat(this.formDetails.layout[p].template)
                                    }
                                }
                                let optionalFilter = pprops.filter((item)=>item.prop == optionalCheckPropName);
                                if(this.formFill.entries[r][Object.keys(this.formFill.entries[r])[rf]] === '' && !(optionalFilter.length && optionalFilter[0].optional)){
                                    console.log('err')
                                    
                                    errorCheck = true;
                                    this.$set(this.formFill.entries[r], 'error', true);
                                }
                            }
                        }
                    }
                }
                for(let i = 0; i < this.formDetails.fields.length;i++){
                    let item = this.formDetails.fields[i];
                    if(item.type == 'array'){
                        if(this.formDetails.fields[i].children){

                        
                        for(let c=0;c<this.formDetails.fields[i].children.length;c++){
                            for(let cf = 0; cf < this.formDetails.fields[i].children[c].fields.length; cf++){
                                if(this.formDetails.fields[i].children[c].fields[cf].value === '' && !this.formDetails.fields[i].children[c].fields[cf].optional){
                                    console.log('hit2')
                                    errorCheck = true;
                                    console.log('error?')
                                    this.$set(this.formDetails.fields[i].children[c].fields[cf], 'error',true);
                                }else{
                                    console.log('no error')
                                    this.$set(this.formDetails.fields[i].children[c].fields[cf], 'error',false);
                                }
                            }
                        }
                    }
                    }else if( item.prop != 'entries' && this.formFill[item.prop] === '' && !this.formDetails.fields[this.formDetails.fields.findIndex((item2)=>item2.prop == item.prop)].optional ){
                        errorCheck = true;
                        this.$set(this.formDetails.fields[i], 'error', true);
                       // this.formDetails.fields[i].error = true;
                    }else{
                        this.$set(this.formDetails.fields[i], 'error',false);
                    }
                }
                if(this.$refs.signature3.isEmpty()){
                    errorCheck = true;
                    this.sigError = true;
                }
                if(errorCheck){
                    this.formLoading = false;
                    console.log('HAS ERROR')
                    return;
                }

                 let {data} = await axios.put(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/editFormFill/${this.$route.params.formId}`, {...post, followed_up:true, managerUserId: this.user.id,});
       
                 let formData = new FormData();
                 const dataURLtoFile = (dataurl, filename) => {
                    var arr = dataurl.split(','),
                        mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[arr.length - 1]), 
                        n = bstr.length, 
                        u8arr = new Uint8Array(n);
                    while(n--){
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    return new File([u8arr], filename, {type:mime});
                }
                if(this.formDetails.name == 'Grievance Form'){
                    let jpeg3 = this.$refs.signature3.save("image/jpeg");
                    formData.append('sig', dataURLtoFile(jpeg3), 'signature3.jpg');
                }
                 this.$store.dispatch('resetReminders');
                 let {data:data2} = await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/upload_signature/${this.$route.params.formId}`, formData);
                 this.$router.push(`/forms/${this.$route.params.id}`);
                 this.formLoading = false;

            }catch(e){
                console.log(e);
                this.formLoading = false;
                this.formError = true;
            }
        },
        async submitForm(){
            if(this.formDetails.name == 'Grievance Form' && this.role == 'admin' && this.$route.params.formId != null){
                this.submitFollowUp();
                return;
            }
            this.formLoading = true;
            this.sigError = false;
            try{
                let post = this.formFill;
                let sections = this.formDetails.layout;
                let errorCheck = false;
                for(let s = 0; s < sections.length; s++){
                    if(sections[s].iterable){
                        for(let r = 0; r < this.formFill.entries.length;r++){
                            this.$set(this.formFill.entries[r], 'error', false);
                            for(let rf = 0; rf < Object.keys(this.formFill.entries[r]).length; rf++){
                                let optionalCheckPropName = Object.keys(this.formFill.entries[r])[rf];
                                let pprops = [];
                                for(let p = 0; p < this.formDetails.layout.length;p++){
                                    if(this.formDetails.layout[p].template != null){
                                        pprops =  pprops.concat(this.formDetails.layout[p].template)
                                    }
                                }
                                let optionalFilter = pprops.filter((item)=>item.prop == optionalCheckPropName);
                                if(this.formFill.entries[r][Object.keys(this.formFill.entries[r])[rf]] === '' && !(optionalFilter.length && optionalFilter[0].optional)){
                                    console.log('err')
                                    
                                    errorCheck = true;
                                    this.$set(this.formFill.entries[r], 'error', true);
                                }
                            }
                        }
                    }
                }
                for(let i = 0; i < this.formDetails.fields.length;i++){
                    let item = this.formDetails.fields[i];
                    if(item.type == 'array'){
                        if(this.formDetails.fields[i].children){

                        
                        for(let c=0;c<this.formDetails.fields[i].children.length;c++){
                            for(let cf = 0; cf < this.formDetails.fields[i].children[c].fields.length; cf++){
                                if(this.formDetails.fields[i].children[c].fields[cf].value === '' && !this.formDetails.fields[i].children[c].fields[cf].optional){
                                    console.log('hit2')
                                    errorCheck = true;
                                    console.log('error?')
                                    this.$set(this.formDetails.fields[i].children[c].fields[cf], 'error',true);
                                }else{
                                    console.log('no error')
                                    this.$set(this.formDetails.fields[i].children[c].fields[cf], 'error',false);
                                }
                            }
                        }
                    }
                    }else if( item.prop != 'entries' && this.formFill[item.prop] === '' && !this.formDetails.fields[this.formDetails.fields.findIndex((item2)=>item2.prop == item.prop)].optional ){
                        errorCheck = true;
                        this.$set(this.formDetails.fields[i], 'error', true);
                       // this.formDetails.fields[i].error = true;
                    }else{
                        this.$set(this.formDetails.fields[i], 'error',false);
                    }
                }
                if(this.$refs.signature.isEmpty()){
                    errorCheck = true;
                    this.sigError = true;
                }
                if(errorCheck){
                    this.formLoading = false;
                    console.log('HAS ERROR')
                    return;
                }

                 let {data} = await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/add`, {...post, userId:this.user.id, facility:this.facility, formId:this.$route.params.id});
                 
                 
                 let _id = data.result.insertedId;
       
                 let formData = new FormData();
                 const dataURLtoFile = (dataurl, filename) => {
                    var arr = dataurl.split(','),
                        mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[arr.length - 1]), 
                        n = bstr.length, 
                        u8arr = new Uint8Array(n);
                    while(n--){
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    return new File([u8arr], filename, {type:mime});
                }

                let jpeg = this.$refs.signature.save("image/jpeg");
                formData.append('sig', dataURLtoFile(jpeg), 'signature.jpg');

                if(this.formDetails.name == 'Medication/Substance Disposal'){
                    let jpeg2 = this.$refs.signature2.save("image/jpeg");
                    formData.append('sig', dataURLtoFile(jpeg2), 'signature2.jpg');
                    let jpeg3 = this.$refs.signature3.save("image/jpeg");
                    formData.append('sig', dataURLtoFile(jpeg3), 'signature3.jpg');
                }
                 this.$store.dispatch('resetReminders');
                 let {data:data2} = await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/upload_signature/${_id}`, formData);
                 this.$router.push(`/forms/${this.$route.params.id}`);
                 this.formLoading = false;

            }catch(e){
                console.log(e);
                this.formLoading = false;
                this.formError = true;
            }
        },
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        async getFormFill(){
            try{
                let {data} = await axios.get(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_form_fill/${this.$route.params.formId}`);
                this.formFill = Object.assign({}, data.result);
                this.formDetails.fields = this.formDetails.fields.map((item)=>{
                    if(item.type == 'date'){
                        return {...item, dateFormatted:moment(data.result.date).utc().format('MM/DD/YYYY')}
                    }else{
                        return item;
                    }
                })
            }catch(e){

            }
        },
        async addEntry(template){
            let obj = {};
            template.forEach((item)=>{
                obj[item.prop] = '';
            })
            this.$nextTick(()=>{
                this.formFill.entries.push(obj)
            })
           
        },
        async removeEntry(index){
            this.$nextTick(()=>{
                this.formFill.entries.splice(index, 1)
            })
        },
        async getFormDetails(){
            
            try{
                let {data} = await axios.get(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_form_details/${this.$route.params.id}`)
                
                data = {...data}

                data.result.fields = data.result.fields.map((item)=>{
                    
                    if(item.type == 'date'){
                        return {...item,menu:false, dateFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)), date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),value:null}
                    }else if(item.type == 'datetime'){
                        return {...item, value:null, pm:false}
                    }else{
                        return {...item, value:null}
                    }
                    
                })
                
                this.formDetails = {...data.result};
                let sections = this.formDetails.layout;
                let fields = this.formDetails.fields
                this.formFill = {};
                for(let s = 0; s < sections.length; s++){
                    if(sections[s].iterable){
                        let obj = {};
                        sections[s].template.forEach((item)=>{
                            obj[item.prop] = ''
                        })
                        this.formFill.entries = [obj]
                    }
                }
                for(let i = 0; i < fields.length; i++){
                    if(fields[i].prop == 'entries') continue;
                    this.formFill[fields[i].prop] = ''
                    if(fields[i].prop == 'date'){
                        this.formFill[fields[i].prop] = moment().tz('America/Los_Angeles').format('YYYY-MM-DD')
                    }
                    if(fields[i].prop == 'lights' || fields[i].prop == 'extinguishers' || fields[i].prop == 'detectors' || fields[i].prop == 'aed_check'){
                        this.formFill[fields[i].prop] = [...fields[i].children]
                    }
                    
                    
                }
                this.formFill = Object.assign({}, this.formFill)
               
            }catch(e){
                console.log(e);
            }
        },

        
    },
    computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        ...mapGetters({
            user:'AUTH_USER',
            facility:'FACILITY'
        }),
        
        editing(){
            return (this.$route.params.formId != null && this.role != 'admin') || (this.formFill.followed_up == null ? this.$route.params.formId != null : this.$route.params.formId != null && this.role == 'admin' && this.formFill.followed_up) ;
        },
        role(){
            return this.user.role;
        },
        ampmOptions(){
            return [
                {text:'am', value:false},
                {text:'pm', value:true}
            ]
                
        },

    },
    watch:{
        
    }
}
</script>
<style lang="scss">
.userName{
    font-weight: bold;
}
.alt-me{
    ul{
        margin-bottom:10px;
        li{
            list-style:disc !important;
            margin-left:20px;
            &:last-of-type{
                margin-bottom:10px;
            }
        }
    }
    
   
   margin-left:10px !important;
}
.f-value{

&.readonly{
    .v-text-field--outlined fieldset{
            border:none !important;
            padding:0px !important
    }
    .v-input__slot{
        padding:0px !important;
        .mdi-menu-down{
            opacity: 0 !important;
        }
        min-height:unset !important;
        input{
            padding:0 !important;
        }
    }
    .fixMe {
        
        padding:0px !important;
       
    }
    textarea {
    resize: none !important;
    }
}
}
</style>
<style scoped lang="scss">
@import "@/assets/scss.scss";
.form-container{
    width:100%;
    flex:1;
    
    padding:15px;
    .f-name{
        font-size:15pt;
        
       font-weight: bold;
        
        i{
            color:var(--app-color);
            margin-right:10px;
        }
        
    }
    .f-instructions{
        margin-top:10px;
        font-size:12pt;
        color:#808080;
        font-weight: normal;
        i{
            color:#808080;
            margin-right:10px;
        }
        
    }
    text-align: left;


}
.f-form{
    margin-top:25px;
    grid-template-columns: 1fr 1fr;
    height: auto;

    @include media-query($medium-down){
        grid-template-columns: 1fr;
    }
    @include media-query($small){
        max-width: 100%;
    }
    .f-section{
        background:#fff;
        margin-bottom:5%;
        padding:15px;
        border-radius: 5px;
        box-shadow:0px 10px 10px rgba(0,0,0,0.1);
        .s-title{
            font-size:15pt;
            color:#000;
            font-weight:bold;
            margin-bottom:15px;
        }
        .s-row{
            @include media-query($small){
                flex-wrap: wrap;
            }
            display:flex;
            align-items: flex-start;
        }
    }
    
    position: relative;
    .f-item{
        flex:1;
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding:15px 0px;
        margin-right:5%;
        @include media-query($medium-down){
            padding:15px 0px;
            margin-right:0%;
        }   
        
        .f-item-inner{
            flex-basis: 100%;
            display:flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex:1;
            width:100%;
        .f-label{
            max-width: 70%;
            margin-bottom:10px;
            @include media-query($medium-down){
                max-width: 100%;
                font-size:9pt;
            }

            font-size:10pt;
            color:#38363b;
            font-weight: bold;
            display:flex;
            flex-direction: column;
            .alt-text{
                color:#aeaeaa;
                font-size:1em;
                font-weight: normal;
            }
            &.has-error{
                color:#ff0000 !important;
                text-align: left;
            }
        }
        .f-value{
            &.bool-w-comment{
                display:flex;
                 align-items:center; 
                 gap:10px; 
                 width:100%;
                 .comment-b{
                    margin-left:auto;
                 }
            }
            margin-left:0;
            @include media-query($medium-down){
                max-width: 100%;
            }
            @include media-query($small){
                max-width: 100%;
                &.bool-w-comment{
                    display:flex;
                    flex-direction: column;
                    gap:10px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    .comment-b{
                        margin-left:0 !important; 
                        width:100%;
                    }
                }
            }
            .v-input--selection-controls.v-input{
                margin:0 !important;
            }
            .v-input{
                padding-top:0 !important;
            
            }
        }
        }
        
    }
}
.form-actions{
    margin-top:25px;

}
.f-table-total{
    margin:15px 0px;
    color:#000;
    font-weight: bold;
}
input[type=number]::-webkit-inner-spin-button {
    opacity: 0;
    pointer-events: none;
}
.time-input{
    background:#eaeaea;
    border-radius: 4px;
    height:40px;
    font-size:12pt;
    text-align: center;
    cursor: default;
    &:hover{
        background:darken(#eaeaea, 15%);
    }
    padding:5px;
    padding-right:0px
}
.mark-na{
    border:solid 1px #aeaeae;
    margin-left:10px;
    height:35px; 
    width:35px;
    border-radius: 50%;
    cursor: pointer;
    opacity: .3;
    transition: all .3 ease;
    display:flex; 
    align-items: center;
    justify-content: center;
    &:hover{
        opacity: 1;
    }
    &.active{
        opacity: 1;
    }
}
.sigy{
    margin-top:25px;
    canvas{
        border:solid 1px #aeaeae;
        margin-top:15px;
    }
    width:100%;
    max-width:502px;
    .sig-error{
        font-size:10pt;
        color:#ff0000;
        margin-top:15px;
    }
}
.ynoption{
    font-size:12pt;
    color:#000;
    display:flex;
    align-items: center;
    gap:5px;
    cursor: pointer;
    &.active{
        i{
            
            &.fa-check-square{
  
                color:#000;
            }
        }
    }
    i{
        &.fa-square{

            color:#aeaeae;
        }

    }
}
.textarea{
    width:100% !important;
    max-width:unset !important;
    padding-top:15px;
    @include media-query($small){
        grid-column: span 1;
        padding-left:0px;
    }
    
}
.textarea-c{
    grid-column: span 2 !important;
    text-align:left;
    @include media-query($small){
        grid-column: span 1 !important;
        padding:15px 0 !important;
    }
    .f-item-inner{
        flex-direction: column;
        align-items: start !important;
    }
    
}
.r-item{
    margin-bottom:35px;
    margin-top:15px;
    width:100%;
    background:#f7f7f7;
    padding:15px;
    border:solid 1px #aeaeae;
    border-radius: 3px;
    .f-value2{
        display: grid;
        grid-template-columns: 1fr;
    }
    @include media-query($small){
        .f-value2{
            width:100% !important; max-width:100% !important;
            grid-template-columns: 1fr;        }
        }
    .f-item{
        padding:10px;
        .f-item-inner{
            display:flex;
        }
    }
}
.date-row{
    flex-basis:100% !important;
    @include media-query($small){
        grid-column: span 1;
    }
    grid-column: span 2;
}
.total-time{
    display:none !important;
}

.drill-checklist{
    padding:15px;
    background: #f7f7f7;
    border-radius: 3px;
    
}
.iterable-item{
    background:#f7f7f7;
    border:solid 1px #aeaeae;
    padding:15px;
    margin:15px 0px;
    border-radius: 5px;
    position: relative;
    &.ierror{
        border-color:#ff0000;
    }
    .remove-entry{
        position: absolute;
        top:10px;
        right:10px;
        z-index: 10;
        height:35px;
        width:35px;
        display:flex;
        align-items:center;
        justify-content: center;
        user-select: none;
        transition:all .3s ease;
        cursor: pointer;
        font-size:12pt;
        color:#808080;
        border:solid 1px #aeaeae;
        background: #f1f1f1;
        border-radius: 3px;
        &:hover{
            background:#aeaeae;
        }
    }
}

</style>