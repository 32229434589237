<template>
  <div id="app">
    <v-app style="background-color:#f8fafb">
    <Header />
    <div v-if="!loading">
      <Sidebar v-if="$route.path != '/' && $route.path != '/forgot' && $route.path.indexOf('/reset_password') < 0" />
      <router-view/>
    </div>
    
    </v-app>
  </div>
</template>
<script>
import Header from './components/header';
import Sidebar from './components/sidebar';
import axios from 'axios';
import { mapActions, mapGetters } from "vuex";
import moment from 'moment';
  export default {
    components:{
      Header,
      Sidebar
    },
    name:'App',
    data(){
      return {
        loading:true
      }
    },
    methods:{
      ...mapActions(["setToken", "setUser", "setUsers", "facility_set"]),
    },
    created() {
    const token = localStorage.getItem("token");
    if (token) {
      this.setToken(token);
    } else {
      if (this.$route.path.indexOf("reset") > -1) {
      } else {
        this.$router.push("/");
      }
    }
    
  },
  async mounted() {
    const rootEl = document.querySelector(':root');
    rootEl.style.setProperty('--app-color', this.appColor);
    const token = localStorage.getItem("token");
    this.loading = true;

    let {data:rData} =  await axios.get('/api/reminders/all');
      // if(rData.items.length > 0){
      //   let formReminders = rData.items.filter((item)=>item.type == 'form');
      //   for(let i = 0; i < formReminders.length;i++){
      //     let fData = await axios.get(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_form_fills/${formReminders[i].formId}`);
      //     let filteredFills = fData.data.items.filter((item)=>{
      //       return moment(item.created_at).isAfter(new Date())
      //     })
      //     if(filteredFills.length){
      //       await axios.put(`/api/reminders/edit/${formReminders[i]._id}`, {dismissed:true})
      //       console.log('HAS A FORM FILL')
      //     }else{
      //       console.log('DOES NOT HAVE A FORM FILL')
      //     }
      //   }
        
        
      // }
      // console.log('SETTING REMINDERS', rData.items)
      this.$store.dispatch("setReminders", rData.items);
    if (token) {
      //
    } else {
    if (this.$route.path.indexOf("reset") > -1) {

    } else {
      this.$router.push("/");
    }
    }
    try{
      let {data} = await axios.get('/api/users/get_users');
      
      let d = JSON.parse(JSON.stringify(data.items))
      console.log(d);
      this.setUsers(d)
      
    this.loading = false;
    }catch(e){
      this.loading = false;
    }
   


    

  },
  computed: {
    ...mapGetters({
      facility: "FACILITY",

    }),
    appColor() {
      if (this.facility == null) return '#4db7c9';
      return this.facility == 'oasis' ? '#a6855d' : '#4db7c9';
    },
    path() {
      return this.$route.path;
    },
    }
  }
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
