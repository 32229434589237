<template>
  <div class="main-nav">
    <div class="logo">
      <img v-if="facility != 'oasis'" src="@/assets/blue_logo_small.png" alt="" />
      <img v-if="facility == 'oasis'" src="@/assets/oasis_logo_small.png" alt="" />
      <span style="color:#000;">{{ facility }}</span>
    </div>
    <div v-if="user.id" class="logging">
      <v-menu offset-y="20"  v-model="adminMenu" :close-on-content-click="true">
      <template v-slot:activator="{on}">
        <v-btn  v-on="on" icon  style="margin-right:15px;" >
          <i class="fad fa-cog"></i>
        </v-btn>
      </template>
      <div>
        <v-card>
          <div class="admin-menu-items">
            <div @click="$router.push('/admin')" class="menu-item">Manage</div>

            <div @click="setFacility('oasis')" class="menu-item">The Oasis</div>
            <div @click="setFacility('ohana')" class="menu-item">The Ohana</div>
          </div>
          
        </v-card>
        
      </div>
      </v-menu>

      <v-menu :offset-x="true" offset-y="20"  v-model="notMenu"  :close-on-content-click="true">
      <template v-slot:activator="{on}">
        <v-btn  v-on="on" icon  style="margin-right:15px;" >
          <div style="position:relative;">
            <i class="fad fa-bell"></i>
            <div class="bell-count" v-if="notifications.filter((item)=>!item.openedUsers.includes(user.id)).length">{{ notifications.filter((item)=>!item.opened).length }}</div>
          </div>
        </v-btn>
      </template>
      <div>
        <v-card class="not-cont">
          <h2>Notifications <v-btn style="margin-left:auto" :color="appColor" @click.stop="clearNotifications" small text>clear all</v-btn></h2>
          <div class="notification-items">
            <div v-for="(not, i) in notifications" :key="i" class="notification-item">
              <div class="not-icon">
                <v-btn @click="dismissNotification(i)" icon text><i class="fal fa-times"></i></v-btn>
              </div>
              
              <div class="not-label">
                <div class="not-title">
                  <i style="margin-right:10px;" class="fad fa-bell"></i> 
                  {{ not.title }} <span>{{ moment(not.date).fromNow() }}</span>
                </div>
                <div class="not-body">{{not.desc}}</div>
              </div>
            </div>
          </div>
          <div class="no-nots" v-if="notifications.length == 0">No Notifications</div>
          
        </v-card>
        
      </div>
      </v-menu>
      <div class="welcome-name">
        <i class="fad fa-user-circle"></i>
        {{ user.first_name }} 
        {{ user.last_name }}
      </div>
      
      <v-btn @click="auth.logout" :color="appColor" dark style="margin:0" depressed small><i class="fad fa-sign-out"></i></v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import moment from 'moment';
import axios from 'axios';
import auth from '../auth';
export default {
  name: "Nav",
  data() {
    return {
      adminMenu:false,
      notMenu:false,
      moment,
      auth,
      notifications:[]
      
    };
  },
  mounted(){
    this.getNotifications();
    if(this.user == null){
      this.$router.push('/')
    }
  },
  watch:{
    notMenu(v){
      if(v) this.openNotifications();
    }
  },
  computed: {
    ...mapGetters({
      user:'AUTH_USER',
      facility:'FACILITY'
    }),
    // notifications(){
    //   //return [];
    //   return [
    //     {
    //       title:'Notification 1',
    //       date: '2/17/2024',
    //       type:'form',
    //       desc:'There was a new notification created. Please take a look asap. There is something wrong here.',
    //       dismissed:false,
    //       opened:false
    //     },
    //     {
    //       title:'Notification 2',
    //       date: '2/17/2024',
    //       type:'form',
    //       desc:'There was a new notification created. Please take a look asap.',
    //       dismissed:false,
    //       opened:false
    //     },
    //     {
    //       title:'Notification 3',
    //       date: '2/17/2024',
    //       type:'form',
    //       desc:'There was a new notification created. Please take a look asap.',
    //       dismissed:false,
    //       opened:false
    //     },
    //     {
    //       title:'Notification 4',
    //       date: new Date(),
    //       type:'form',
    //       desc:'There was a new notification created. Please take a look asap.',
    //       dismissed:false,
    //       opened:false
    //     },
    //     {
    //       title:'Notification 5',
    //       date: new Date(),
    //       type:'form',
    //       desc:'There was a new notification created. Please take a look asap.',
    //       dismissed:false,
    //       opened:false
    //     }
    //   ]
    // },
    role(){
      return this.user.role;
    }
  },
  methods: {
    ...mapActions({
      set_facility:'facility_set'
    }),
    setFacility(facility){
      this.set_facility(facility);
      localStorage.setItem('facility', facility);
      window.location.reload();
      
    },
    async openNotifications(_id){
      let _ids = this.notifications.map((item)=>{
        return item._id
      });
      try{
        await axios.post('/api/notifications/open', {userId:this.user.id, notIds:_ids})
      }catch(e){
        
      }
    },
    async dismissNotification(_id){
      try{
        this.notifications.splice(this.notifications.findIndex((item)=>item._id == _id), 1);
        await axios.get(`/api/notifications/dismiss/${_id}`)
      }catch(e){
        console.log(e);
      }
    },
    async getNotifications(){
      try{
        let {data} = await axios.get(`/api/notifications/all/${this.user.id}`);
        this.notifications = data.items;
      }catch(e){

      }
      
    },
    async clearNotifications(){
      try{
        for(let i = 0; i < this.notifications.length; i++){
          await axios.get(`/api/notifications/dismiss/${this.notifications[i]._id}`);
        }
        this.notifications = [];
      }catch(e){
        console.log(e);
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss.scss";
.bell-count{
  position:absolute;
  height:12px;
  width:12px;
  border-radius: 20px;
  background:#ff0000;
  bottom:-6px;
  right:-6px;
  color:#fff;
  font-size:7pt;
  display:flex;
  align-items: center;
  justify-content: center;
}
.no-nots{
  font-size: 12pt;
  text-align: center;
  color:#808080;
  padding:15px 0px;
}
.not-cont{
  padding:15px;
  text-align: left;
  width:350px;
  position:relative;
  h2{
    display:flex;
    align-items: center;
    width:100%;
    font-size:12pt;
  }
}
.notification-items{
  display:flex;
  max-width: 100%;
  max-height:300px;
  overflow: auto;
  
  flex-direction: column;
  .notification-item{
    
    padding:10px 15px;
    min-width:150px;
    transition:all .3s ease;
    background: #fff;
    font-size:12pt;
    color:#808080;
    text-align: left;
    display:flex;
    align-items: center;
    gap:15px;
    cursor: pointer;
    .not-icon{
      display:flex;
      align-items: center;
     
    }
    .not-label{
      display:flex;
      flex-direction: column;
      gap:0px;
      .not-title{
        display:flex;
        span{
          font-size:.8em;
          font-weight: normal;
          color:#808080;
          margin-left: auto;
        }
        white-space: nowrap;
        font-size:12pt;
        font-weight: bold;
        color:#000;
      }
      .not-body{
        color:#808080;
        font-size:.8em;
        
      }
    }
    &:hover{
      background:#f8fafb;
    }
  }
}
.welcome-name{
  margin-right:10px;
  color:#808080;
  display:flex;
  align-items: center;
  gap:7px;
  i{
    color:#808080;
    font-size:12pt;
  }
}
.admin-menu-items{
  display:flex;
  flex-direction: column;
  .menu-item{
    white-space: nowrap;
    padding:10px 15px;
    min-width:150px;
    transition:all .3s ease;
    background: #fff;
    font-size:12pt;
    color:#808080;
    text-align: left;
    cursor: pointer;
    &:hover{
      background:#f8fafb;
    }
  }
}
.logging{
    display:flex;
    align-items: center;
    margin-left:auto;
    height:100%;
}
.main-nav {
    box-sizing: border-box;
  background: #f8fafb;
  padding: 0px 25px;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  position:fixed;
  top:0;
  z-index:100;
  left:0;
  .logo {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
      font-size:12pt;
      color:$blue;
      font-weight: lighter;
    }
    img {
      max-width: 120px;
      margin-right: 10px;
    }
  }
  @include media-query($small) {
  //  position:fixed;
  //  bottom:0;
  //  left:0;
  //  width:100%;
  }
}
</style>