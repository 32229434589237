<template>
    <div class="container">
    <div class="loginForm">
        <div class="innerForm">
        <div class="loginLogo">
                <img src="@/assets/ohana_logo.webp" alt=""></div>
        
        <div>
            <v-text-field outlined :color="appColor" class="animated-fast fadeInUp" v-model="email" background-color="transparent"  label="Email"></v-text-field>
            <v-btn v-if="hasSuccess == ''" @click="register" class="loginBtn" dark :color="appColor" depressed><span style="text-transform: none">SEND RESET PASSWORD LINK</span></v-btn>
            <transition enter-active-class="shake" leave-active-class="fadeOut">
                <div class="errorMessage animated"  v-if="hasError">{{errorMessage}}</div>
            </transition>
            <transition enter-active-class="shake" leave-active-class="fadeOut">
                    <div class="successMessage animated"  v-if="hasSuccess">{{successMessage}}
                        <br>
                        <v-btn @click="$router.push('/')" class="loginBtn" dark :color="appColor" depressed><span style="text-transform: none">Back to Login</span></v-btn>
                    </div>
            </transition>
        </div>
    </div>
        
    </div>
    
</div>
</template>
<script>
import auth from '../auth';
export default {
    name:'login',
    data(){
        return {
            email:'',
            errorMessage:'',
            hasError:false,
            hasSuccess:false,
            successMessage:'Reset link has been sent!'
        }
    },
    methods:{
        register(){
            this.hasError = false;
            auth.forgot({email:this.email}).then(res=>{
                this.hasSuccess = true;
            },err=>{
                this.hasError = true;
                console.log(err);
                this.errorMessage = err.response.data.error
            })

        }
    }
}
</script>
<style  scoped lang="scss">
.loginForm{
    border-radius: 10px;
    min-width: 340px;
    text-align: center;
    background: #fff;
    box-shadow: 0px -0px 39px -10px #00000050;
    overflow: hidden;
}
.innerForm{
    padding:45px;
}
.loginLogo{
    display:inline-block;
    font-size:12pt; font-weight: 500;
    color:#000;
    margin-bottom:50px;
    white-space: nowrap;
    text-align: center;
    width:100%;
}
.loginLogo img{

    width:220px;
    margin-bottom:25px;
}
.container{
    display:flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    width:100vw;
    
 
    background-size:cover;
    background-position: center;
}
.loginBtn{
    width:100%;
    border-radius: 3px;
    margin:0 !important;
    margin-top:25px !important;
    
}
.errorMessage{
    background:#e9eaea;
    color:#ff0000;
    font-weight: 300;
    font-size:10pt;
    padding:15px;
    margin-top:10px;
}
.successMessage{
    background:#e9eaea;
    color:#19b466;
    font-weight: 300;
    font-size:10pt;
    padding:15px;
    margin-top:10px;
}
.link{
    cursor: pointer;
    color:#fff;
    text-decoration: underline;
}
</style>