<template>
    <transition :enter-active-class="direction ? 'fadeInLeft' :'fadeInRight'" mode="out-in" :leave-active-class="!direction ?'fadeOutLeft' :'fadeOutRight'">
        <v-card v-if="activeLead != null" class="active-lead animated-med" style="max-width:1200px;">
            <v-dialog width="500" v-model="updateDialog">
                <v-card>
                    <v-card-title>Assign Rep Checklist</v-card-title>
                    <v-card-text style="text-align:left;">Please MAKE SURE the name is correct. Fill in insurance company
                        and leave a note.<br>
                        <v-text-field :color="appColor" label="Lead Name" outlined small dense ref=""
                            @change="$event=>changeItem({label:'name',value:$event.target.value})" type="text"
                            v-model="activeLead.name"></v-text-field>
                        <v-select label="Insurance Company"
                            @change="$event=>changeItem({label:'insurance_company',value:$event})"
                            v-model="activeLead.insurance_company" :loading="savingEntry" :color="appColor"
                            style="padding-top:0px !important; " hide-no-data outlined dense hide-details
                            :items="availableInsurances"></v-select>
                        <v-text-field
                            @change="$event=>{addInsurance($event);changeItem({label:'insurance_company',value:$event}), editingField = -1, activeLead.insurance_company = $event}"
                            :color="appColor" label="New Insurance Name" v-model="newInsuranceName" outlined dense
                            v-if="activeLead.insurance_company == 'new'" />

                        <v-select label="Tag" @change="$event=>changeTag({label:'tag',value:$event})"
                            v-model="activeLead.tag" :loading="savingEntry || ctm_loading" :color="appColor"
                            style="padding-top:10px !important; " hide-no-data outlined dense hide-details
                            :items="availableTags"></v-select>

                        <v-select @change="$event=>changeItem({label:'status',value:$event})" v-model="activeLead.status"
                            :loading="savingEntry" :color="appColor" style="padding-top:10px !important; " hide-no-data
                            outlined dense hide-details :items="availableStatuses"></v-select>

                        <div @click="editingField = -1" class="done-editing-icon"><i class="fal fa-check"></i></div>


                        <div class="show-add-note">
                            <v-textarea outlined v-model="newNoteMessage" placeholder="Note Message" :color="appColor"
                                label="Note"></v-textarea>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="updateDialog = false, activeLead ={...entry}" depressed text>Cancel</v-btn>
                        <v-btn @click="addAssignedRep" :disabled="newNoteMessage == ''" :dark="newNoteMessage != ''"
                            depressed :color="appColor">Submit</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar :timeout="4000" color="#ff0000" dark elevation="24" v-model="hasError">
                <span style="font-size:1.2em">{{errorMessage}}</span>
            </v-snackbar>
            <div class="active-lead-title flex padding" style="background:#f5f5f5">
                <v-menu transition="slide-y-transition" offset-y v-model="statusDropdown">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs" class="lead-status-tag" :class="activeLead.status">{{
                            activeLead.status }} <i class="fa fa-caret-down"></i></div>
                    </template>
                    <div class="status-dropdown"></div>
                    <v-card>
                        <v-list>
                            <v-list-item @click="changeItem({ label: 'status', value: item.value })" v-for="(item,i) in availableStatuses" :key="i" class="lead-dropdown-item">
                                <div class="lead-status-tag" :class="item.value">{{item.text}}</div>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>


                <div style="margin-left:auto; font-size:.8em; letter-spacing: 0; text-transform: none;"> <i
                        class="fal fa-clock"></i> last call: &nbsp;&nbsp; {{moment(activeLead.time).format('MMM DD, YYYY hh:mm A ')}}</div><br>
                        <v-btn :loading="sendingWelcomePacket" @click="sendWelcomePacket" :disabled="activeLead.email == '' || activeLead.email == null" :dark="activeLead.email != '' && activeLead.email != null" small depressed dense style="margin-left:auto"  :color="appColor"><span v-if="!activeLead.welcome_packet_sent">Send Welcome Packet</span><span v-if="activeLead.welcome_packet_sent">Resend Welcome Packet</span></v-btn>

            </div>
            <div style="display:flex; gap:15px;" class="padding">
                <section>
                    <div class="section-title">Contact Information</div>
                    <div class="section-body" style="margin-top:15px;">
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-user-circle"></i></div>
                            <div class="contact-info-value"><input placeholder="Lead Name"
                                    @change="$event => changeItem({ label: 'name', value: $event.target.value })"
                                    v-model="activeLead.name" /></div>
                        </div>
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-envelope"></i></div>
                            <div class="contact-info-value"><input placeholder="Email"
                                    @change="$event => changeItem({ label: 'email', value: $event.target.value })"
                                    v-model="activeLead.email" /></div>
                        </div>
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-phone"></i></div>
                            <div class="contact-info-value"><input ref="" placeholder="Phone"
                                    @change="$event => changeItem({ label: 'phone', value: $event.target.value })"
                                    type="text" :value="formatPhoneNumber(activeLead.phone)"></div>
                        </div>
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-mars"></i></div>
                            <div class="contact-info-value">
                                <div class="gender-buttons">
                                    <div class="gender-button female" :class="{'active':activeLead.gender == 'f'}"
                                        @click="changeItem({ label: 'gender', value: 'f' })"><i class="fa fa-venus"></i>
                                        female</div>
                                    <div class="gender-button male" :class="{'active':activeLead.gender == 'm'}"
                                        @click="changeItem({ label: 'gender', value: 'm' })"><i class="fa fa-mars"></i> male
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-address-book"></i></div>
                            <div @click.stop="editingAddress = true" class="contact-info-value address">
                                <div v-if="editingAddress == false">
                                    {{ activeLead.street1 }} {{ activeLead.street2 }}<br> {{ activeLead.city }}
                                    {{activeLead.state}} {{ activeLead.zip }}
                                </div>
                                <div v-click-outside="stopEditing" v-if="editingAddress">
                                    <input placeholder="Street 1"
                                        @change="$event => changeItem({ label: 'street1', value: $event.target.value })"
                                        v-model="activeLead.street1" />
                                    <input placeholder="Street 2"
                                        @change="$event => changeItem({ label: 'street2', value: $event.target.value })"
                                        v-model="activeLead.street2" />
                                    <input placeholder="City"
                                        @change="$event => changeItem({ label: 'city', value: $event.target.value })"
                                        v-model="activeLead.city" />
                                    <input placeholder="State"
                                        @change="$event => changeItem({ label: 'state', value: $event.target.value })"
                                        v-model="activeLead.state" />
                                    <input placeholder="Zip"
                                        @change="$event => changeItem({ label: 'zip', value: $event.target.value })"
                                        v-model="activeLead.zip" />
                                </div>
                            </div>
                        </div>
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-birthday-cake"></i></div>
                            <div class="contact-info-value"><input placeholder="DOB" ref=""
                                    @change="$event => changeItem({ label: 'dob', value: $event.target.value })" type="text"
                                    :value="activeLead.dob"></div>
                        </div>
                    </div>
                </section>
                <section>

                    <div class="section-body">
                        <div class="contact-info-item">
                            <div class="section-title">Source</div>
                            <div class="contact-info-value">
                                <div v-if="activeLead.source != ''">
                                    <div v-if="activeLead.source && activeLead.source.indexOf('Psychology Today') > -1"
                                        style="margin-left:auto"><img :src="psychtodaySource" style="width:100px;" alt="">
                                    </div>
                                    <div v-else-if="activeLead.source && activeLead.source.toLowerCase().indexOf('path') > -1" style="margin-left:auto"><img
                                            :src="rehabPathSource" style="width:100px;" alt=""></div>
                                    <div v-else-if="activeLead.source && activeLead.source.indexOf('Google A') > -1" style="margin-left:auto"><img
                                            :src="googleAdsSource" style="width:100px;" alt=""></div>
                                    <div v-else-if="activeLead.source && activeLead.source.indexOf('Google My') > -1" style="margin-left:auto"><img
                                            :src="gmbSource" style="width:100px;" alt=""></div>
                                    <div v-else-if="activeLead.source && activeLead.source.indexOf('Website') > -1" style="margin-left:auto"><img
                                            :src="websiteSource" style="width:100px;" alt=""></div>
                                    <div v-else-if="activeLead.source && activeLead.source.indexOf('Google Organic') > -1" style="margin-left:auto">Google Organic</div>
                                    <div v-else style="margin-left:auto;">{{ activeLead.source }}</div>
                                </div>
                                <div v-else><input ref="" placeholder="Source"
                                    @change="$event => changeItem({ label: 'source', value: $event.target.value })"
                                    type="text" :value="activeLead.source"></div>
                            </div>
                        </div>
                        <div class="contact-info-item">
                            <div class="section-title">Page</div>
                            <div class="contact-info-value">{{activeLead.location && activeLead.location.indexOf('?') > 0 ? activeLead.location.split('?')[0] : activeLead.location}}</div>
                        </div>
                        <div class="contact-info-item">
                            <div class="section-title">From</div>
                            <div class="contact-info-value">{{ activeLead.from }}</div>
                        </div>
                        <div class="contact-info-item">
                            <div class="section-title">ID</div>
                            <div class="contact-info-value">{{ resourceId }}</div>
                        </div>
                        <div class="contact-info-item">
                            <div class="section-title">Keywords</div>
                            <div class="contact-info-value">{{ activeLead.activity && activeLead.activity.length > 0 ?
            activeLead.activity[0].webvisit && activeLead.activity[0].webvisit.keywords : 'none' }}
                            </div>
                        </div>


                    </div>
                </section>
            </div>

            <div style="display:flex; gap:15px; padding-top:0px;" class="padding">
                <section>
                    <div class="section-title">Insurance Information</div>
                    <div class="section-body" style="margin-top:15px;">
                        <div class="contact-info-item">

                            <div class="active-lead-item">
                                <div class="active-lead-item-title">Insurance Company  <div @click="editingField != 'insurance_company' ? activateEditingField('insurance_company') : editingField = -1" class="edit-value-icon" :class="{'active':editingField == 'insurance_company', 'disabled':newInsuranceName == '' && activeLead.insurance_company == 'new'}" >
                                    <i v-if="editingField == -1 || editingField != 'insurance_company'" class="fal fa-pencil"></i>   
                                    <i v-if="editingField == 'insurance_company'"  class="fal fa-check"></i>   
                                </div></div>
                                <div @click="editingField != 'insurance_company' ? activateEditingField('insurance_company') : editingField = -1" class="active-lead-item-value select" :class="{editable:true,editing:editingField == 'insurance_company'}">
                                    <div  v-if="editingField == -1 || editingField != 'insurance_company'">{{ activeLead.insurance_company || 'None'}}</div>
                                    <div @click.stop  v-else v-click-outside="onClickOutside">
                                        <v-select @change="$event=>changeItem({label:'insurance_company',value:$event})" v-model="activeLead.insurance_company" :loading="savingEntry" :color="appColor" style="padding-top:0px !important; " hide-no-data outlined dense hide-details :items="availableInsurances"></v-select>
                                        <v-text-field @change="$event=>{addInsurance($event);changeItem({label:'insurance_company',value:$event}), editingField = -1, activeLead.insurance_company = $event}" :color="appColor" label="New Insurance Name" v-model="newInsuranceName" outlined dense v-if="activeLead.insurance_company == 'new'" />
                                        <div @click="editingField = -1" class="done-editing-icon"><i class="fal fa-check"></i></div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        
                        <div class="contact-info-item">
                            <div class="section-title">Member ID</div>
                            <div class="contact-info-value"><input placeholder="ID" ref=""
                                    @change="$event => changeItem({ label: 'member_id', value: $event.target.value })" type="text"
                                    v-model="activeLead.member_id"></div>
                        </div>
                        <div class="contact-info-item">
                            <div class="section-title">Group #</div>
                            <div class="contact-info-value"><input placeholder="#" ref=""
                                    @change="$event => changeItem({ label: 'group_number', value: $event.target.value })" type="text"
                                    v-model="activeLead.group_number"></div>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="section-title">Family Contact</div>
                    <div class="section-body" style="margin-top:15px;">
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-user-circle"></i></div>
                            <div class="contact-info-value"><input placeholder="Contact Name"
                                    @change="$event => changeItem({ label: 'family_contact_name', value: $event.target.value })"
                                    v-model="activeLead.family_contact_name" /></div>
                        </div>
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-envelope"></i></div>
                            <div class="contact-info-value"><input placeholder="Contact Email"
                                    @change="$event => changeItem({ label: 'family_contact_email', value: $event.target.value })"
                                    v-model="activeLead.family_contact_email" /></div>
                        </div>
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-phone"></i></div>
                            <div class="contact-info-value"><input ref="" placeholder="Contact Phone"
                                    @change="$event => changeItem({ label: 'family_contact_phone', value: $event.target.value })"
                                    type="text" :value="formatPhoneNumber(activeLead.family_contact_phone)"></div>
                        </div>
                        
                        
                        <div class="contact-info-item">
                            <div class="contact-info-icon"><i class="fa fa-heart"></i></div>
                            <div class="contact-info-value"><input placeholder="Relationship to Insured" ref=""
                                    @change="$event => changeItem({ label: 'relationship_to_insured', value: $event.target.value })" type="text"
                                    :value="activeLead.relationship_to_insured"></div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="padding" style="padding-top:0">
                <section style="width:100%; " v-if="activeLead.status == 'admitted' ||activeLead.status == 'waitlist' || activeLead.status == 'discharged' || activeLead.status == 'alumni'">


                    
    <div   class="section-title">Admission Details</div> 
    <div class="admission-dates">
        <div class="admit-date">
                <div class="a-title">Total Stay</div>
                <div style="display:flex; gap:15px;">
                <div class="active-lead-item" style="max-width:300px;">
                    <div class="active-lead-item-title">Admit Date  <div @click="editingField != 'admitDate' ? activateEditingField('admitDate') : editingField = -1" class="edit-value-icon" :class="{'active':editingField == 'admitDate'}">
                        <i v-if="editingField == -1 || editingField != 'admitDate'" class="fal fa-pencil"></i>   
                        <i v-if="editingField == 'admitDate'" class="fal fa-check"></i>   
                    </div></div>
                    <div @click="editingField != 'admitDate' ? activateEditingField('admitDate') : editingField = -1" class="active-lead-item-value select" :class="{editable:true,editing:editingField == 'admitDate'}">
                        <div  v-if="editingField == -1 || editingField != 'admitDate'">{{ admitDateFormatted }}</div>
                        <div @click.stop  v-else >
                            <v-menu
                            v-model="admitDateMenu"
                            :close-on-content-click="false"
                            max-width="290"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="admitDateFormatted"
                                style="padding-top:0 !important;"
                                clearable
                                
                                @blur="activeLead.admitDate = parseDate(admitDateFormatted)"
                                class="fixMe"
                                outlined
                                dense
                                :color="appColor"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="changeItem({label:'admitDate', type:'', value:''})"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            :color="appColor"
                            
                                v-model="activeLead.admitDate"
                                @change="$event => {admitDateMenu = false; changeItem({label:'admitDate', type:'', value:$event})}"
                            ></v-date-picker>
                            </v-menu>
                            <div @click="editingField = -1" class="done-editing-icon"><i class="fal fa-check"></i></div>
                        </div>
                    
                    </div>
                </div>
                <div class="active-lead-item" style="max-width:300px">
                    <div class="active-lead-item-title">Discharge Date  <div @click="editingField != 'dischargeDate' ? activateEditingField('dischargeDate') : editingField = -1" class="edit-value-icon" :class="{'active':editingField == 'dischargeDate'}">
                        <i v-if="editingField == -1 || editingField != 'dischargeDate'" class="fal fa-pencil"></i>   
                        <i v-if="editingField == 'dischargeDate'" class="fal fa-check"></i>   
                    </div></div>
                    <div @click="editingField != 'dischargeDate' ? activateEditingField('dischargeDate') : editingField = -1" class="active-lead-item-value select" :class="{editable:true,editing:editingField == 'dischargeDate'}">
                        <div  v-if="editingField == -1 || editingField != 'dischargeDate'">{{ dischargeDateFormatted }}</div> 
                        <div @click.stop  v-else >
                            <v-menu
                            v-model="dischargeDateMenu"
                            :close-on-content-click="false"
                            max-width="290"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                @change="$event=>changeItem('dischargeDate', $event)"
                                v-model="dischargeDateFormatted"
                                style="padding-top:0 !important;"
                                clearable
                                @blur="activeLead.dischargeDate = parseDate(dischargeDateFormatted)"
                                class="fixMe"
                                outlined
                                dense
                                :color="appColor"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="changeItem({label:'dischargeDate',type:'', value:''})"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            :color="appColor"
                            
                            v-model="activeLead.dischargeDate"
                            @change="$event => {dischargeDateMenu = false; changeItem({label:'dischargeDate',type:'', value:$event})}"
                            ></v-date-picker>
                            </v-menu>
                            <div @click="editingField = -1" class="done-editing-icon"><i class="fal fa-check"></i></div>
                        </div>
                    
                    </div>
                </div>
                <div class="active-lead-item" style="max-width:300px;">
                    <div class="active-lead-item-title">Proposed Admit Date  <div @click="editingField != 'proposedAdmitDate' ? activateEditingField('proposedAdmitDate') : editingField = -1" class="edit-value-icon" :class="{'active':editingField == 'proposedAdmitDate'}">
                        <i v-if="editingField == -1 || editingField != 'proposedAdmitDate'" class="fal fa-pencil"></i>   
                        <i v-if="editingField == 'proposedAdmitDate'" class="fal fa-check"></i>   
                    </div></div>
                    <div @click="editingField != 'proposedAdmitDate' ? activateEditingField('proposedAdmitDate') : editingField = -1" class="active-lead-item-value select" :class="{editable:true,editing:editingField == 'proposedAdmitDate'}">
                        <div  v-if="editingField == -1 || editingField != 'proposedAdmitDate'">{{ proposedAdmitDateFormatted }}</div>
                        <div @click.stop  v-else >
                            <v-menu
                            v-model="proposedAdmitDateMenu"
                            :close-on-content-click="false"
                            max-width="290"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="proposedAdmitDateFormatted"
                                style="padding-top:0 !important;"
                                clearable
                                
                                @blur="activeLead.proposedAdmitDate = parseDate(proposedAdmitDateFormatted)"
                                class="fixMe"
                                outlined
                                dense
                                :color="appColor"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="changeItem({label:'proposedAdmitDate', type:'', value:''})"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            :color="appColor"
                            
                                v-model="activeLead.proposedAdmitDate"
                                @change="$event => {proposedAdmitDateMenu = false; changeItem({label:'proposedAdmitDate', type:'', value:$event})}"
                            ></v-date-picker>
                            </v-menu>
                            <div @click="editingField = -1" class="done-editing-icon"><i class="fal fa-check"></i></div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>

        <admission-level 
            v-for="(item, i) in admissionLevels"  
            @updateEditingField="$event=>editingField = $event" 
            :key="i" @changeItem="changeItem" 
            :editingField="editingField" 
            @activateEditingField="activateEditingField" 
            :startDate="item.startDate" 
            :endDate="item.endDate" 
            :type="item.type" 
            :name="item.name">
        </admission-level>
        
        
            
        
    </div>
    </section>

            </div>
            <div class="padding" style="padding-top:0">
                <section style="width:100%">
                    <div class="section-title">Lead History</div> 
                    <div class="history-container">
                    <div v-for="(item, i) in leadHistory" :key="i" class="history-item">
                        <div class="history-item-title">
                            <span v-if="item.form_data != null || item.form != null"><i class="fal fa-file-alt"></i> Form <br>
                                <span v-if="item.submitted_at != null">{{moment(item.submitted_at).format('MM/DD/YYYY hh:mm')}}</span>
                                <span v-else-if="item.datetime != null">{{moment(item.datetime).format('MM/DD/YYYY hh:mm')}}</span>
                                <span v-else-if="item.time != null">{{moment(item.time).format('MM/DD/YYYY hh:mm')}}</span>
                            </span>
                            <span v-if="item.form_data == null && item.form == null">
                                <span style="display:flex; align-items: center; gap:10px;">
                                    <i v-if="(item.audio != null && item.audio != '') || (item.recording != null && item.recording != '')" class="fal fa-phone"></i>
                                    <i v-if="!(item.audio != null && item.audio != '') && !(item.recording != null && item.recording != '')" class="fal fa-user-circle"></i> 
                                    <span v-if="(item.audio != null && item.audio != '') || (item.recording != null && item.recording != '')">Call</span>
                                    <span v-if="!(item.audio != null && item.audio != '') && !(item.recording != null && item.recording != '')">Contact</span> <br>
                                </span>
                                <span v-if="item.called_at != null">{{moment(item.called_at).format('MM/DD/YYYY hh:mm')}}</span>
                                <span v-else-if="item.datetime != null">{{moment(item.datetime).format('MM/DD/YYYY hh:mm')}}</span>
                                <span v-else-if="item.time != null">{{moment(item.time).format('MM/DD/YYYY hh:mm')}}</span>
                            </span>
                            <i class="fal fa-trash" @click="deleteActivity(i)"></i>

                        </div>
                        <div v-if="item.form_data == null && item.form == null">
                            <div class="history-form-item">
                                <b>ID</b> {{ item.id || item.resource_id }}
                            </div>
                            <div class="history-form-item">
                                <b>Page</b> {{ item.landing_page_url && item.landing_page_url.split('?')[0]}}
                            </div>
                            <audio v-if="(item.audio != null && item.audio != '') || (item.recording != null && item.recording != '')" style="width:500px;" :src="item.audio || item.recording" controls ></audio>
                        </div>
                       
                        
                        <div v-if="item.form_data != null" class="history-form">
                            <div class="history-form-item">
                                <b>ID</b> {{ item.id || item.resource_id }}
                            </div>
                            <div class="history-form-item">
                                <b>Name</b> {{ item.form_data.Name }}
                            </div>
                            <div class="history-form-item">
                                <b>Email</b> {{ item.form_data.Email }}
                            </div>
                            <div class="history-form-item">
                                <b>Phone</b> {{ item.form_data.Phone }}
                            </div>
                            <div class="history-form-item">
                                <b>Insurance</b> {{ item.form_data['Insurance Company Name*'] }}
                            </div>
                            <div class="history-form-item">
                                <b>Member ID #</b> {{ item.form_data['Insurance Member ID #*'] }}
                            </div>
                            <div class="history-form-item">
                                <b>Insurance Group #*</b> {{ item.form_data['Insurance Group #*'] }}
                            </div>
                            <div class="history-form-item">
                                <b>Relationship to Insured*</b> {{ item.form_data['Relationship to Insured*'] }}
                            </div>
                            <div class="history-form-item">
                                <b>Message</b> {{ item.form_data['Message'] }}
                            </div>
                            <div class="history-form-item">
                                <b>Page</b> {{ item.landing_page_url && item.landing_page_url.split('?')[0]}}
                            </div>
                        </div>
                        <div v-else-if="item.form != null" class="history-form">
                            <div class="history-form-item">
                                <b>ID</b> {{ item.id || item.resource_id }}
                            </div>
                            <div class="history-form-item">
                                <b>Name</b> {{ item.form.custom.filter((item2)=>item2.id == 'contact_name').length ? item.form.custom.filter((item2)=>item2.id == 'contact_name')[0].value : '' }}
                            </div>
                            <div class="history-form-item">
                                <b>Email</b> {{ item.form.email }}
                            </div>
                            <div class="history-form-item">
                                <b>Phone</b> {{ item.form.customer_number_to_dial }}
                            </div>
                            <div class="history-form-item">
                                <b>Insurance</b> {{ item.form.custom.filter((item2)=>item2.id == 'insurance_company').length ? item.form.custom.filter((item2)=>item2.id == 'insurance_company')[0].value : '' }}
                            </div>
                            <div class="history-form-item">
                                <b>Member ID #</b> {{ item.form.custom.filter((item2)=>item2.id == 'member_id').length ? item.form.custom.filter((item2)=>item2.id == 'member_id')[0].value : '' }}
                            </div>
                            <div class="history-form-item">
                                <b>Insurance Group #*</b> {{ item.form.custom.filter((item2)=>item2.id == 'group_number').length ? item.form.custom.filter((item2)=>item2.id == 'group_number')[0].value : '' }}
                            </div>
                            <div class="history-form-item">
                                <b>Relationship to Insured*</b> {{ item.form.custom.filter((item2)=>item2.id == 'relationship_to_insured').length ? item.form.custom.filter((item2)=>item2.id == 'relationship_to_insured')[0].value : '' }}
                            </div>
                            <div class="history-form-item">
                                <b>Message</b> {{ item.form.custom.filter((item2)=>item2.id == 'message').length ? item.form.custom.filter((item2)=>item2.id == 'message')[0].value : '' }}
                            </div>
                            <div class="history-form-item">
                                <b>Page</b> {{ item.landing_page_url && item.landing_page_url.split('?')[0]}}
                            </div>
                        </div>
                        <!-- <div>
                            <div v-for="(it, p) in Object.keys(item)" :key="p">
                                {{ it }}:{{item[it]}}
                            </div>
                            
                        </div> -->

                    </div>
                </div>
                </section>
            </div>

            
            <div class="note-section">
                <div class="active-lead-notes">
                    <div
                        style="text-align:left; margin-bottom:15px; font-weight:bold; font-size:12pt; display:flex; align-items:center; gap:10px">
                        Notes <div v-if="showAddNote == false" @click="showAddNote = true" class="add-note-icon"><i
                                class="fal fa-plus"></i></div>
                        <div v-else @click="showAddNote = false" class="add-note-icon"><i class="fal fa-minus"></i></div>
                        <div v-if="notesLoading"
                            style="display:flex;align-items:center;justify-content:center;width:100%;min-height:160px;">
                            <v-progress-circular width="3" indeterminate size="30" :color="appColor"></v-progress-circular>
                        </div>
                    </div>
                    <v-expand-transition>
                        <div v-if="showAddNote && !notesLoading" class="show-add-note">
                            <v-textarea outlined v-model="newNoteMessage" placeholder="Note Message" :color="appColor"
                                label="Note"></v-textarea>
                            <v-btn :disabled="newNoteMessage == ''" small @click="addNote" :color="appColor" depressed
                                :dark="newNoteMessage != ''">Add Note</v-btn>
                        </div>
                    </v-expand-transition>

                    <div v-if="leadNotes.length == 0 && !notesLoading" class="active-lead-notes-section">
                        <div class="no-notes">No Notes</div>
                    </div>
                    <div v-if="leadNotes.length > 0 && !notesLoading" class="active-lead-notes-section">
                        <div class="note-item" v-for="(item,i) in leadNotes" :key="i">
                            <div class="note-title">{{moment(item.date).format('MMM DD,YYYY HH:mm A')}} {{item.user_name}}
                                <i @click="deleteNote(item._id)" class="fal fa-trash"></i></div>
                            <div class="note-body">{{item.message}}</div>
                        </div>
                    </div>
                </div>

                <div class="active-lead-notes">
                    <div
                        style="text-align:left;  margin-bottom:15px; font-weight:bold; font-size:12pt; display:flex; align-items:center; gap:10px">
                        Reminders <div v-if="showAddReminder == false" @click="showAddReminder = true"
                            class="add-note-icon"><i class="fal fa-plus"></i></div>
                        <div v-else @click="showAddReminder = false" class="add-note-icon"><i class="fal fa-minus"></i>
                        </div>
                        <div v-if="reminderLoading"
                            style="display:flex;align-items:center;justify-content:center;width:100%;min-height:160px;">
                            <v-progress-circular width="3" indeterminate size="30" :color="appColor"></v-progress-circular>
                        </div>
                    </div>
                    <v-expand-transition>

                        <div v-if="showAddReminder && !reminderLoading" class="show-add-note">
                            <div class="active-lead-item" style="margin-bottom:15px;">
                                <div class="active-lead-item-title">Remind Me</div>
                                <div class="active-lead-item-value select">
                                    <v-select @change="$event=>changeRemindMeOption({label:'date',value:$event})"
                                        v-model="remindMe" :color="appColor" style="padding-top:0px !important; " hide-no-data
                                        outlined dense hide-details :items="availableDateOptions"></v-select>
                                </div>
                            </div>
                            <div class="active-lead-item">
                                <div class="active-lead-item-title">Date</div>
                                <div class="active-lead-item-value select">
                                    <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="dateFormatted" style="padding-top:0 !important;"
                                                clearable @blur="date = parseDate(dateFormatted), remindMe = 'custom'"
                                                class="fixMe" outlined dense :color="appColor" readonly v-bind="attrs"
                                                v-on="on" @click:clear="date = null"></v-text-field>
                                        </template>
                                        <v-date-picker :color="appColor" :min="new Date().toISOString().substr(0, 10)"
                                            v-model="date" @change="menu1 = false"></v-date-picker>
                                    </v-menu>

                                </div>
                            </div>

                            <div class="active-lead-item">
                                <div class="active-lead-item-title">Message</div>
                                <div class="active-lead-item-value select">
                                    <v-textarea style="padding-top:0 !important;" outlined v-model="reminderMessage"
                                        placeholder="Reminder Message" :color="appColor"></v-textarea>
                                </div>
                            </div>


                            <v-btn :disabled="reminderMessage == ''" small @click="addReminder" :color="appColor" depressed
                                :dark="reminderMessage != ''">Add Reminder</v-btn>
                        </div>
                    </v-expand-transition>

                    <div v-if="leadReminders.length == 0 && !reminderLoading" class="active-lead-notes-section">
                        <div class="no-notes">No Reminders</div>
                    </div>
                    <div v-if="leadReminders.length > 0 && !reminderLoading" class="active-lead-notes-section">
                        <div class="note-item" v-for="(item,i) in leadReminders" :key="i">
                            <div class="note-title"><i class="fal fa-bell"></i> &nbsp;&nbsp;{{moment(item.date).fromNow()}}
                                <i @click="deleteReminder(item._id)" class="fal fa-trash"></i></div>
                            <div class="note-body">{{item.message}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex padding">
                <div style="margin-left:auto">
                    <v-btn depressed @click="$emit('close')">Close</v-btn>
                    <v-btn style="margin-left:15px" depressed @click="$router.push('/lead/' + activeLead._id)">Go to Lead
                        Page</v-btn>
                </div>
            </div>

        </v-card>
    </transition>
</template>
<script>
import Vue from 'vue';
import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';
import axios from 'axios';
import AdmissionLevel from './admissionLevel.vue';
import _ from 'lodash';

export default {
    name:'popup',
    data:vm => ({
            rehabPathSource:require('@/assets/RP-LOGO-1.png'),
            psychtodaySource:require('@/assets/psychtoday.png'),
            googleAdsSource:require('@/assets/Google-Ads-Logo-PNG.png'),
            gmbSource:require('@/assets/gmb.png'),
            websiteSource:require('@/assets/website.png'),
            editingField:-1,
            hasError:false,
            errorMessage:'',
            controller: new AbortController(),
            statusDropdown:false,
            remindMe:'custom',
            activeLead:null,
            moment,
            leadNotes:[],
            savingEntry:false,
            showAddNote:false,
            newNoteMessage:'',
            notesLoading:false,
            ctm_loading:false,
            reminderLoading:false,
            leadReminders:[],
            showAddReminder:false,
            reminderMessage:'',
            forms:[],
            disabledDates: {
            to: new Date(Date.now() - 8640000)
            },
            reminderDate:new Date(),
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            menu1: false,
            menu2: false,
            gettingCallInfo:false,
            callInfoMessage:'Request Call Recording',

            admitDateFormatted:'',
            admitDateMenu:false,
            dischargeDateFormatted:'',
            dischargeDateMenu:false,
            
            proposedAdmitDateFormatted:'',
            proposedAdmitDateMenu:false,

            date_of_birth_formatted:'',
            date_of_birth_menu:false,
            //
            detoxAdmitDateFormatted:'',
            detoxAdmitDateMenu:false,
            detoxDischargeDateFormatted:'',
            detoxDischargeDateMenu:false,
            //
            residentialAdmitDateFormatted:'',
            residentialAdmitDateMenu:false,
            residentialDischargeDateFormatted:'',
            residentialDischargeDateMenu:false,
            //
            iopAdmitDateFormatted:'',
            iopAdmitDateMenu:false,
            iopDischargeDateFormatted:'',
            iopDischargeDateMenu:false,
            //
            outpatientAdmitDateFormatted:'',
            outpatientAdmitDateMenu:false,
            outpatientDischargeDateFormatted:'',
            outpatientDischargeDateMenu:false,
            //
            phpAdmitDateFormatted:'',
            phpAdmitDateMenu:false,
            phpDischargeDateFormatted:'',
            phpDischargeDateMenu:false,
            //
            updateDialog:false,
            tempAssignedUser:null,

            availableInsurances:[],
            newInsuranceName:'',
            leadTempLoading:false,
            sendingWelcomePacket:false,
            editingAddress:false
        }
    ),
    props:{
        entry:{
            type:Object,
            default:()=>{}
        },
        direction:{
            type:Boolean,
            default:false
        }
    },
    components:{
        AdmissionLevel
    },
    computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        ...mapGetters({
            user:"AUTH_USER",
            users:"AUTH_USERS"
        }),
        resourceId(){
            if(this.activeLead.reqBody){
                const req = JSON.parse(this.activeLead.reqBody);
                if(req.resource_id != null && req.resource_id != ''){
                    return req.resource_id;
                }
            }else{
                return 'none'
            }
        },
        leadHistory(){
            let h = [];
            if(this.activeLead.reqBody != null){
                h.push(JSON.parse(this.activeLead.reqBody))
            }
            if(this.activeLead.activity != null && this.activeLead.activity.length > 0){
                h = this.activeLead.activity;
            }
            return h;
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        temp(){
            if(this.activeLead.temp == null){
                return 'cold';
            }else{
                return this.activeLead.temp
            } 
        },
        admissionLevels(){
            return [
                // {
                //     name:'Detox (DTX)',
                //     startDate:this.activeLead.detoxAdmitDate,
                //     endDate:this.activeLead.detoxDischargeDate,
                //     type:'detox'
                // },
                // {
                //     name:'Residential (RTC)',
                //     startDate:this.activeLead.residentialAdmitDate,
                //     endDate:this.activeLead.residentialDischargeDate,
                //     type:'residential'
                // },
                // {
                //     name:'Partial Hospitalization Program (PHP)',
                //     startDate:this.activeLead.phpAdmitDate,
                //     endDate:this.activeLead.phpDischargeDate,
                //     type:'php'
                // },
                // {
                //     name:'Intensive Outpatient (IOP)',
                //     startDate:this.activeLead.iopAdmitDate,
                //     endDate:this.activeLead.iopDischargeDate,
                //     type:'iop'
                // },
                // {
                //     name:'Outpatient (OP)',
                //     startDate:this.activeLead.outpatientAdmitDate,
                //     endDate:this.activeLead.outpatientDischargeDate,
                //     type:'outpatient'
                // }
            ]
        },
        availableDateOptions(){
            return [
            {text:"Tomorrow", value:"1"},
            {text:"7 days", value:"7"},
            {text:"12 days", value:"12"},
            {text:"On Date", value:"custom"},
            ]
        },
        availableTags(){

        return [
                    {text:"VOB", value:"VOB"},
                    {text:"Possible Insurance", value:"Possible Insurance"},
                    {text:"Possible Private Pay", value:"Possible Private Pay"},
                    {text:"Medicaid/Medicare", value:"Medicaid/Medicare"},
                    {text:"Referred Out", value:"Referred Out"},
                    {text:"Solicitation", value:"Solicitation"},
                    {text:"Wrong Number", value:"Wrong Number"},
                    {text:"Adolescent", value:"Adolescent"},
                    {text:"Alumni", value:"Alumni"},
                    {text:"Call Dropped", value:"Call Dropped"},
                    {text:"Incoherent", value:"Incoherent"},
                    {text:"Incoherent", value:"Incoherent"},
                    {text:"Looking for Patient/Employee", value:"Looking for Patient/Employee"},
                    {text:"Loved One", value:"Loved One"},
                    {text:"Other", value:"Other"},
                    {text:"Bad Insurance", value:"Bad Insurance"},
                    {text:"Other Facility", value:"Other Facility"},
                    {text:"Test", value:"Test"}         

            ]
        },
        availableStatuses(){
            return [
                {text:'Recent', value:'recent'},
                {text:'VOB', value:'vob'},
                {text:'Qualified', value:'qualified'},
                {text:'Unqualified', value:'unqualified'},
                {text:'Lost', value:'lost'},
                {text:'Closed', value:'closed'},
                // {text:'Referred', value:'referred'},
                {text:'Admitted', value:'admitted'},
                //{text:'Archived', value:'archive'},
                {text:'Waitlist', value:'waitlist'},
                {text:'Discharged', value:'discharged'}
            ]
        },

        availableUsers(){
            return this.users.map((item)=>{
                return {text:`${item.first_name} ${item.last_name}`, value:item._id};
            })
        },
    },
    async mounted(){
        
        await this.getInsurances();
        this.activeLead ={...this.entry}
        //await this.getActivityCTMinfo();
        // if(this.activeLead.activity != null){
        //         for(let i = 0; i < this.activeLead.activity.length; i++){
        //             this.activeLead.activity[i].loading = false;
        //             this.activeLead.activity[i].call_recording = null;
        //             this.activeLead.activity[i].callInfoMessage = '';
        //         }
        //     }
        this.proposedAdmitDateFormatted = (this.entry.proposedAdmitDate != '' && this.entry.proposedAdmitDate != null) ? moment(this.activeLead.proposedAdmitDate).utc().format('MM/DD/YYYY') : '';
        this.admitDateFormatted = (this.entry.admitDate != '' && this.entry.admitDate != null) ? moment(this.activeLead.admitDate).utc().format('MM/DD/YYYY') : '';
        this.dischargeDateFormatted = (this.entry.dischargeDate != '' && this.entry.dischargeDate != null) ? moment(this.activeLead.dischargeDate).utc().format('MM/DD/YYYY') : '';
        // //
        // this.detoxAdmitDateFormatted = this.entry.detoxAdmitDate && moment(this.activeLead.detoxAdmitDate).format('MM/DD/YYYY') || '';
        // this.detoxDischargeDateFormatted = this.entry.detoxDischargeDate && moment(this.activeLead.detoxDischargeDate).format('MM/DD/YYYY') || ''
        // //
        // this.residentialAdmitDateFormatted = this.entry.residentialAdmitDate && moment(this.activeLead.residentialAdmitDate).format('MM/DD/YYYY') || '';
        // this.residentialDischargeDateFormatted = this.entry.residentialDischargeDate && moment(this.activeLead.residentialDischargeDate).format('MM/DD/YYYY') || ''
        // //
        // this.iopAdmitDateFormatted = this.entry.iopAdmitDate && moment(this.activeLead.iopAdmitDate).format('MM/DD/YYYY') || '';
        // this.iopDischargeDateFormatted = this.entry.iopDischargeDate && moment(this.activeLead.iopDischargeDate).format('MM/DD/YYYY') || ''
        // //
        // this.outpatientAdmitDateFormatted = this.entry.outpatientAdmitDate && moment(this.activeLead.outpatientAdmitDate).format('MM/DD/YYYY') || '';
        // this.outpatientDischargeDateFormatted = this.entry.outpatientDischargeDate && moment(this.activeLead.outpatientDischargeDate).format('MM/DD/YYYY') || ''
        // //
        // this.phpAdmitDateFormatted = this.entry.phpAdmitDate && moment(this.activeLead.phpAdmitDate).format('MM/DD/YYYY') || '';
        // this.phpDischargeDateFormatted = this.entry.phpDischargeDate && moment(this.activeLead.phpDischargeDate).format('MM/DD/YYYY') || ''
        // //
        this.getNotes(this.entry._id);
        this.getReminders(this.entry._id);
        // this.getCTMinfo();
        this.getForms();
        
    },
    watch:{
        date (val) {
            this.dateFormatted = this.formatDate(this.date)
        },
        entry(v){
            this.activeLead = null;
            setTimeout(()=>{
                this.activeLead = {...v};
            this.proposedAdmitDateFormatted = this.activeLead.proposedAdmitDate && moment(this.activeLead.proposedAdmitDate).tz('America/Los_Angeles').format('MM/DD/YYYY');
            this.admitDateFormatted = this.activeLead.admitDate && moment(this.activeLead.admitDate).utc().format('MM/DD/YYYY');
            this.dischargeDateFormatted = this.activeLead.dischargeDate && moment(this.activeLead.dischargeDate).utc().format('MM/DD/YYYY')

            this.detoxAdmitDateFormatted = this.activeLead.detoxAdmitDate && moment(this.activeLead.detoxAdmitDate).utc().format('MM/DD/YYYY');
            this.detoxDischargeDateFormatted = this.activeLead.detoxDischargeDate && moment(this.activeLead.detoxDischargeDate).utc().format('MM/DD/YYYY')

            this.residentialAdmitDateFormatted = this.activeLead.residentialAdmitDate && moment(this.activeLead.residentialAdmitDate).utc().format('MM/DD/YYYY');
            this.residentialDischargeDateFormatted = this.activeLead.residentialDischargeDate && moment(this.activeLead.residentialDischargeDate).utc().format('MM/DD/YYYY')

            this.iopAdmitDateFormatted = this.activeLead.iopAdmitDate && moment(this.activeLead.iopAdmitDate).utc().format('MM/DD/YYYY');
            this.iopDischargeDateFormatted = this.activeLead.iopDischargeDate && moment(this.activeLead.iopDischargeDate).utc().format('MM/DD/YYYY')

            this.outpatientAdmitDateFormatted = this.activeLead.outpatientAdmitDate && moment(this.activeLead.outpatientAdmitDate).utc().format('MM/DD/YYYY');
            this.outpatientDischargeDateFormatted = this.activeLead.outpatientDischargeDate && moment(this.activeLead.outpatientDischargeDate).utc().format('MM/DD/YYYY')

            this.phpAdmitDateFormatted = this.activeLead.phpAdmitDate && moment(this.activeLead.phpAdmitDate).utc().format('MM/DD/YYYY');
            this.phpDischargeDateFormatted = this.activeLead.phpDischargeDate && moment(this.activeLead.phpDischargeDate).utc().format('MM/DD/YYYY')
            
            
            // if(this.activeLead.admitDate == null || this.activeLead.admitDate == ''){
            //     this.activeLead.admitDate = '';
            //     this.admitDateFormatted = '';
            // }else{
            //     this.admitDateFormatted = this.formatDate(this.admitDate)
            // }
            // if(this.activeLead.dischargeDate == null || this.activeLead.dischargeDate == ''){
            //     this.activeLead.dischargeDate = '';
            //     this.dischargeDateFormatted = '';
            // }else{
            //     this.dischargeDateFormatted = this.formatDate(this.dischargeDate)
            // }
            this.callInfoMessage = 'Request Call Recording';
            this.controller.abort();
            this.controller = new AbortController();
            
            this.getNotes(this.activeLead._id);
            this.getReminders(this.entry._id);
            this.getCTMinfo();
            this.getForms();
            },100)
            
        }
    },
    methods:{
        async deleteActivity(idx){
            let activity = JSON.parse(JSON.stringify(this.leadHistory))
            activity.splice(idx, 1);
            console.log(activity);
            let {data} = await axios.put('/api/leads/edit/' + this.activeLead._id + `?userId=${this.user.id}`, {activity});
            this.$emit('getLeads');
            this.$set(this.activeLead, 'activity', activity);
            
            this.savingEntry = false;
        },
        stopEditing(){
            this.$nextTick(()=>{
                this.editingAddress = false
            })
        },
        getForms(){
            let forms = [];
            if(this.activeLead.reqBody && JSON.parse(this.activeLead.reqBody).form){
                forms.push(JSON.parse(this.activeLead.reqBody).form);
            }
            if(this.activeLead.activity && this.activeLead.activity.length){
                for(let i = 0; i < this.activeLead.activity.length; i++){
                    if(this.activeLead.activity[i].form){
                        if(forms.filter((item)=>item.form_id == this.activeLead.activity[i].form.form_id).length < 1){
                            forms.push(this.activeLead.activity[i].form);
                        }
                    }
                }
            }
            this.forms = forms;

        },
        onClickOutside(){
                if(this.editingField != -1){
                    this.editingField = -1;
                }
                
        },
        async sendWelcomePacket(){
            try{
                this.sendingWelcomePacket = true;
                let post = {
                    email:this.activeLead.email,
                    leadId:this.activeLead._id
                }
                let { data } = await axios.post('/api/auth/welcome_packet', post);
                this.activeLead.welcome_packet_sent = true;
                this.sendingWelcomePacket = false;
            }catch(e){
                this.sendingWelcomePacket = false;
            }

        },
        async setLeadTemp(temp){
            try{
                this.leadTempLoading = true;
                this.activeLead.temp = temp;
                await this.changeItem({label:'temp', value:temp});
                
                
                this.leadTempLoading = false;
            }catch(e){
                this.leadTempLoading = false;
            }

        },
        async getInsurances(){
            try{
                let { data } = await axios.get('/api/insurance/all');
                this.availableInsurances = [{text:'None', value:''}, ...data.items.map((item)=>{
                return {
                    value:item.name,
                    text:this.getInsuranceLabel(item.name)
                }
                }), {text:'New Insurance', value:'new'}];
            }catch(e){
                console.log(e);
            }
        },
        getInsuranceLabel(item){
            let mapping =  [
            {text:'None', value:''},
            {text:'Aetna', value:'aetna'},
            {text:'Cigna', value:'cigna'},
            {text:'Medicaid', value:'medicaid'},
            {text:'UMR', value:'umr'},
            {text:'Humana', value:'humana'},
            {text:'Optum', value:'optum'},
            {text:'Beacon', value:'beacon'},
            {text:'United Health', value:'united'},
            {text:'BCBS', value:'bcbs'},
            {text:'HMSA', value:'hmsa'},
            {text:'HMAA', value:'hmaa'},
            {text:'UHA', value:'uha'},
            {text:'Private Pay', value:'private_pay'}
            ]
            if(mapping[mapping.findIndex((item2)=>item2.value == item)]){
                    return mapping[mapping.findIndex((item3)=>item3.value == item)].text;
            }else{
                return item;
            }
            
    },
        async addInsurance(name){
      try{
        let post = {
          name
        }
        let {data} = await axios.post('/api/insurance/add', post);
        this.getInsurances();
        
      }catch(e){
        console.log(e);
      }
    },
        addAssignedRep(){
            if(this.newNoteMessage == ''){
                this.updateDialog = false;
                this.activeLead = {...this.entry}
            }
            this.addNote();
            this.changeItem(this.tempAssignedUser, true);
            this.updateDialog = false;
            
        },
        showUpdateDialog(){
            this.updateDialog = true;
        },
        formatPhoneNumber(phoneNumberString) {
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
            return null;
        },
        activateEditingField(field){
            setTimeout(()=>{
                this.editingField = field;
            },10)
            
        },
        clearDate(){
            console.log('clear')
        },
        getHistoryCall(a, historyId){
            console.log('TODO')
            // this.$nextTick(async ()=>{
            //     this.$set(this.activeLead.activity[a], 'loading', true);

            // try{
            //     let {data} = await axios.post(`/api/ctm/get_call_link?token=${localStorage.getItem("ctm_token")}`, {phone:this.activeLead.phone, historyId},{signal: this.controller.signal})
            // if(data && data.link){
            //     this.$set(this.activeLead.activity[a], 'call_recording', data.link);
            //     this.$set(this.activeLead.activity[a], 'loading', false);
            // }else{
            //     this.$set(this.activeLead.activity[a], 'callInfoMessage', 'No Call Info Found');

            //     this.$set(this.activeLead.activity[a], 'loading', false);
            // }
            // this.$set(this.activeLead.activity[a], 'loading', false);
            // }catch(e){
            //     console.log(e);
               
            //     this.$set(this.activeLead.activity[a], 'loading', false);
               
            // }
            // })
            
        },
        async getCallInfo(phone){
            this.callInfoMessage = 'TODO';
            // this.gettingCallInfo = true;
            // try{
            //     let {data} = await axios.post(`/api/ctm/get_call_link?token=${localStorage.getItem("ctm_token")}`, {phone},{signal: this.controller.signal})
            // if(data && data.link){
            //     this.activeLead.call_recording = data.link;
            // }else{
            //     this.callInfoMessage = 'No Call Info Found'
            // }
            // this.gettingCallInfo = false;
            // }catch(e){
            //     console.log(e);
                
            //     this.gettingCallInfo = false;
               
            // }
            
        },
        changeRemindMeOption(date){
            if(date.value != 'custom'){
                this.date = this.parseDate(moment().add(Number(date.value), 'd').format('MM/DD/YYYY'))
                //console.log(this.date);
               //this.date = ; 
            }
            
        },
        formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
    parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
        async changeTag(item){
            console.log('TODO');
            window.alert('CTM is out, tags will not work')
            this.savingEntry = true;
            let post = {
                tag: item.value,
                callerNumber: this.entry.phone
            }

            this.savingEntry = false;
            try{
                let {data:data2} = await axios.put('/api/leads/edit/' + this.activeLead._id + `?userId=${this.user.id}`, post);
                    this.savingEntry = false;
                if(this.entry.ctm_id == null && this.entry.activity != null && this.entry.activity.length > 0){
                    let ctm_id = this.entry.activity[0].id
                }else{
                   // let {data} = await axios.post(`/api/ctm/edit_tag/${this.entry.ctm_id}?token=${localStorage.getItem('ctm_token')}`, post, {signal: this.controller.signal});
                    // let {data:data2} = await axios.put('/api/leads/edit/' + this.activeLead._id + `?userId=${this.user.id}`, post);
                    this.savingEntry = false;
                }
                
            }catch(e){
                this.savingEntry = false;
            }
        },
        async getCTMinfo(){
            console.log('TODO');
            // this.ctm_loading = true;
            // try{    
            //     if(this.entry.ctm_id != null){
            //     const {data} = await axios.get(`/api/ctm/get_call/${this.entry.ctm_id}?token=${localStorage.getItem('ctm_token')}`,{signal: this.controller.signal})
            //     if(data.sale){
            //       this.activeLead.tag = data.sale.name;
            //     //  await this.changeItem({label:'tag', value:data.sale.name})
            //     }
            //     this.ctm_loading = false;
            //     }else{
            //         this.ctm_loading = false;
            //     }
            // }catch(e){
            //     this.ctm_loading = false;
            // }
        },
        // async getActivityCTMinfo(){
        //    // this.ctm_loading = true;
        //    console.log(this.activeLead.activity.length);
        //     for(let i = 0; i < this.activeLead.activity.length; i++){
        //         try{    
        //     if(this.activeLead.activity[i].id != null){
        //         const {data} = await axios.get(`/api/ctm/get_call/${this.activeLead.activity[i].id}?token=${localStorage.getItem('ctm_token')}`,{signal: this.controller.signal})
        //         if(data.sale){
        //             console.log('eyo')
        //             this.activeLead.activity[i].tag = data.sale.name;
        //           //this.activeLead.tag = data.sale.name;
        //           //   await this.changeItem({label:'tag', value:data.sale.name})
        //         }else if(data.tag_list != null){
        //             console.log(data.tag_list);
        //             this.activeLead.activity[i].tag = data.tag_list.join(',').toString();
        //         }else{
        //             this.activeLead.activity[i].tag = "NONE"
        //         }
        //        // this.ctm_loading = false;
        //         }else{
                    
        //             this.activeLead.activity[i].tag = "NONE"
        //            // this.ctm_loading = false;
        //         }
        //     }catch(e){
        //         console.log('AHHHHH');
        //         this.ctm_loading = false;
        //     }
        //     }
            
        // },
    async changeItem(item, force = false){

        if(item.label == null) return;
        this.savingEntry = true;
        if(item.label == 'assignedUser' && !force){
            this.showUpdateDialog();
            this.tempAssignedUser = item;
            return;
        }
        if(item.label == 'status' && item.value == 'admitted'){
            if(this.activeLead.gender == null || this.activeLead.gender == ''){
                
                this.hasError = true;
                this.errorMessage = 'A gender must be assigned before a lead can be placed in admitted';
                this.$nextTick(()=>{
                    this.activeLead.status = this.entry.status;
                })
                return;
            }
        }
        if(item.label == 'admitDate' || item.label == 'dischargeDate' || item.label == 'proposedAdmitDate'){
            let type2 = item.label.split('Date')[0];
            
            if(item.type == ''){
                this[type2 +'DateFormatted'] = moment(item.value).format('MM/DD/YYYY')
            }else{
                this[item.type + 'DateFormatted'] = moment(item.value).format('MM/DD/YYYY')
            }
            item.value = new Date(item.value);
            
        }
        if(item.label == 'date_of_birth'){
            this['date_of_birth_formatted'] = moment(item.value).format('MM/DD/YYYY')
        }
        if(item.label == 'phone'){
            item.value = parseInt(item.value.replace(/[^0-9]/g,''));
        }
        
        this.savingEntry = true;
        let post = {
            [item.label]: item.value
        }
        this.savingEntry = false;
        try{
            let {data} = await axios.put('/api/leads/edit/' + this.activeLead._id + `?userId=${this.user.id}`, post);
            this.$emit('getLeads');
            this.$set(this.activeLead, item.label, item.value);
            
            this.savingEntry = false;
        }catch(e){
            this.savingEntry = false;
        }
          
    },
    async addNote(){
        this.notesLoading = true;
        let note = {
            lead_id:this.activeLead._id,
            date:new Date(),
            message:this.newNoteMessage,
            user_name:this.user.first_name + ' ' + this.user.last_name,
            user_id:this.user.id
        }
        await axios.post(`/api/leads/add_note/${this.activeLead._id}`, note,{signal: this.controller.signal});
        this.showAddNote = false;
        this.newNoteMessage = '';
        this.getNotes(this.activeLead._id);
    },
    async deleteNote(id){
        this.notesLoading = true;
        await axios.delete(`/api/leads/delete_note/${id}`,{signal: this.controller.signal});
        this.getNotes(this.activeLead._id);
    },
    async getNotes(id){
            this.notesLoading = true;
            let {data} = await axios.get('/api/leads/get_notes/' + id,{signal: this.controller.signal});
            this.leadNotes = data.notes.reverse();
            this.notesLoading = false;
    },
    async addReminder(){
        this.reminderLoading = true;
        let post = {
            lead_id:this.activeLead._id,
            date:this.date,
            message:this.reminderMessage,
            user_name:this.user.first_name + ' ' + this.user.last_name,
            user_id:this.user.id
        }
        await axios.post(`/api/reminders/add`, post);
        this.showAddReminder = false;
        this.reminderMessage = '';
        this.getReminders(this.activeLead._id);
    },
    async deleteReminder(id){
        this.reminderLoading = true;
        await axios.delete(`/api/reminders/${id}`);
        this.getReminders(this.activeLead._id);
    },
    async getReminders(id){
            this.reminderLoading = true;
            let {data} = await axios.get('/api/reminders/get_all/' + id,{signal: this.controller.signal});
            this.leadReminders = data.items.reverse();
            this.reminderLoading = false;
    },
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss.scss";
.mobile-basic{
    display:none !important;
    @include media-query($small){
        display:flex !important;
        flex-direction: column;
        gap:15px;
    }
}
.basic-format{
    display:inline-flex; 
    flex-direction: column;
    gap:0px;
    @include media-query($small){
        display:none;
    }
    position:relative;
    
    

        
            
    .basic-format-item{
        position:relative;
        display:flex;
        align-items: center;
        width:100%;
        gap:5px;
        height:25px;
        min-width:260px;
        &.name{
            font-size:15pt;
        }
        i{
                color:#808089;
                font-size:.8em;
                &.fa-female{
                    color:#ff0099;
                }
                &.fa-male{
                    color:#3080e8;
            }
        }
        span{
            font-size:12pt;
            display:flex;
            gap:5px;
            align-items:center;
            cursor:pointer;
            &:hover{
                color:var(--app-color);

            }
            
        }
        &.select{
            input{
                border:none !important;
                &:focus{
                    background:none !important;
                }
            }
            &:hover{
                input{
                   background:none !important; 
                    
                }
                
                
            }
        }
        &:hover{
            
            input{
                
                // border:solid 1px #000;
                
                @include media-query($small) {
                    background:transparent;
                }
            }
            
        }
        
        
        margin-left:auto;
        @include media-query($small) {
            margin-left:0;
            min-width:100%;
        }
        input{
            transition:all .1s ease;
            padding:0px;
            height:25px;
            width:100%;
            background:#fff;
            transform:translateX(-10px);
            padding-left:10px;
            // box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.4);
            box-shadow:0px 0px 0px 1px rgba(0,0,0,1);
            border-radius: 3px;
            position:absolute;
            top:0;
            font-size:12pt;
            &:focus{
                
                @include media-query($small) {
                    background:transparent;
                }
            }
            text-align: left;

            @include media-query($small) {
                text-align: left;
                font-size:10pt;
                padding:10px 6px;
            }
        }
        
    }
    
}
</style>
<style lang="scss">
@import "@/assets/scss.scss";
.activity-type-tag{
    background:#28b69e;
    color:#fff;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    border-radius:100px;
    gap:10px;
    
    padding:4px 15px;
    font-size:10pt;
    
    &.form{
        background:#e7546f;
    }
}
.activity-form-display{
    background:#fff;
    padding:15px;
    border-radius: 5px;
    border:solid 1px #eaeaea;
    display:flex;
    align-items: center;
    flex-wrap:wrap;
    gap:20px;
    .aform-item{
        flex-basis: 30%;
        position:relative;
        transform:translateY(6px);
        border:solid 1px #eaeaea;
        padding:5px;
        
        .aform-value{
            color:#000;
            font-size:9pt;
        }
        .aform-label{
            position:absolute;
            top:0px;
            transform:translateY(-100%);
            font-size: 7pt;
            color:#808080;

            left:0px;

        }
    }
}
.active-lead{
    background:#e3e6ec  !important;
    section{
            padding:15px;
            
            width:calc(100% - 70px);
            @include media-query($small){
                margin:15px;
                width:calc(100% - 30px);
            }
            
            border-radius: 5px;
            text-align: left;
            background:#fff;
            box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
            .section-title{
                font-size: 10pt;
                color: #fff;
                min-width:100px;
                white-space:wrap;
                background:var(--app-color);
                text-align: center;
                text-transform: uppercase;
                display: inline-block;
                padding: 5px 15px;
                border-radius: 5px;
                
                @include media-query($small){
                    flex-wrap:wrap;
                }
            }
            .section-body{
                display:flex;
                flex-direction: column;
                gap:5px;
                
                .contact-info-item{
                    display:flex;
                    align-items: center;
                    gap:10px;

                    .contact-info-icon{
                        color:var(--app-color);
                        display:flex;
                        align-items: center;
                        justify-content: center;
                        height:30px;
                        font-size:12pt;
                        width:40px;
                    }
                    .contact-info-value{
                        &.address{
                            
                            width:170px;
                            border-radius: 4px;
                            display:flex;
                            flex-direction: column;
                            gap:5px;
                            min-height:30px;
                            input{
                                transform:translateX(0)
                            }
                            &:hover{
                                background:#00000008;
                            }
                        }
                        color:#000;
                        max-width:350px;
                        input{
                            min-width:300px;
                            color:#000;
                            &:hover{
                                background:#00000008;
                            }
                            transition:background-color .1s ease;
                            padding:5px;
                            
                            background:#fff;
                            &:focus{
                                transition: box-shadow .3s ease;
                                
                                box-shadow: 3px 3px 10px -5px rgba(0,0,0,0.3), 0px 0px 0px 1px #00000008;
                                
                                
                            }
                            border-radius: 5px;
                            border:none;
                        }
                    }
                }
            }
        }
    .active-lead-title{
        font-size:15pt;
        text-transform: capitalize;
        background:#e3e6ec !important;
        color:#999ea8 !important;
        align-items:center;
        display:flex;
        justify-content: space-between;
        
         @include media-query($small) {
            font-size:10pt;
        }
    }
    .active-lead-text{
        
        .flex{
            flex-wrap: wrap !important;
        }
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:15px;
       
        text-align:left;
        @include media-query($small) {
            grid-template-columns: 1fr !important;
        }
        @include media-query($medium) {
            grid-template-columns: 1fr 1fr !important;
        }
 
    }
}

.active-lead-item{
            width:100%;
            
    text-align: left;
            .active-lead-item-title{
                font-size:9pt;
                font-weight: bold;
                align-items: center;
                display:flex;
                .edit-value-icon{
                    &.disabled{
                           // pointer-events: none !important;
                            opacity: .3 !important;
                        }
                    @include media-query($medium-down){
                        opacity: 1;
                    }
                    &.active{
                        opacity: 1;
                        i{
                            color:#000;
                        }
                    }
                    transition: all .3s ease;
                    height:25px;
                    width:25px;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    cursor: pointer;
                    
                    
                    i{
                        
                        font-size:8pt;
                        color:#808080;
                        
                        
                    }

                    opacity: 0;
                    }   
            }   

            .active-lead-item-value{
                position: relative;
        min-width:60%;
        &.select{
            input{
                border:none !important;
                &:focus{
                    background:none !important;
                }
            }
            &:hover{
                input{
                   background:none !important; 
                    
                }
                
                
            }
        }
        &:hover{
            
            input{
                
                border:solid 1px #000;
                
                @include media-query($small) {
                    background:transparent;
                }
            }
            
        }
        
        
        margin-left:auto;
        @include media-query($small) {
            margin-left:0;
            min-width:100%;
        }
        input{
            transition:all .1s ease;
            padding:0px;
            width:100%;
            border:solid 1px rgba(0,0,0,.38);
            border-radius: 3px;
            padding:10px 15px;
            font-size:9pt;
            &:focus{
                
                @include media-query($small) {
                    background:transparent;
                }
            }
            text-align: left;

            @include media-query($small) {
                text-align: left;
                font-size:10pt;
                padding:10px 6px;
            }
        }
            }
            &:hover{
                .edit-value-icon{
                    opacity: 1;
                    &:hover{
                        i{
                            color:#000;
                        }
                    }
                }
            }
        }
.active-lead-notes{
    flex:1;
    .active-lead-notes-section{
        background:#fff;
        border-radius: 5px;
        overflow: auto;
        margin-top:15px;
        display:flex;
        align-items: center;
        flex-direction: column;
      
        max-height:250px;
        
        
        .no-notes{
            display:flex;
            align-items: center;
            justify-content: center;


            color:#808080;
            font-size:12pt;
            text-transform: uppercase;
            letter-spacing: 2px;
            height:100px;
            width:100%;
            
        }
    }
    padding:15px;
}
.fixMe{
    input{
        padding-left:0 !important; 
        font-size:12pt !important;
    }
}
.note-item{
        padding:15px 8px;
        width:100%;
        border:solid 1px #eaeaea;
        border-radius: 4px;
        margin-bottom:15px;
        text-align: left;
        .note-title{
            display:flex;
            margin-bottom:10px;
            font-size:10pt;
            text-transform: uppercase;
            letter-spacing: 1px;
            color:#808080;
            display: flex;
            align-items: center;
            .fa-trash{
                margin-left:10px;
                transition:all .3s ease;
                cursor: pointer;
                &:hover{
                    color:#ff0000;
                }
            }
        }
        .note-body{
            font-size: 12pt;
            white-space:pre-line;
            
        }
    }
    .show-add-note{
        padding-bottom:15px;
    }
    .note-section{
        padding:0px 35px;
        display: flex; 
        gap:15px;
        flex-wrap: wrap;
        .active-lead-notes{
            background:#fff;
        border-radius: 5px;
        box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
           @include media-query($medium-down){
            flex-basis: 100%;
        } 
        }
        
    }
    .activity-section{
        display: flex; 
        flex-direction: column;
        gap:15px;
        padding:15px;
        .activity-item{
            text-align: left;
            display:flex;
            align-items: center;
            gap:15px;
            overflow:hidden;
            .activity-label{
                flex:1;
                padding:15px 15px;
                display:flex;
                flex-direction:column;
                gap:10px;
                background:#f8fafb;
                border:solid 1px #eaeaea;
                border-radius: 6px;
            }
        }
    }
    .done-editing-icon{
        display:none;
        position:absolute;
        right:10px;
        top:50%;
        transform: translateY(-50%);
        z-index: 100;
        height:25px;
        cursor: pointer;
        border-radius: 3px;
        width:25px;
        
        align-items: center;
        justify-content: center;
        i{
            font-size:12pt;
            font-weight: bold;
            color:var(--app-color);
        }
    }
    .lead-status-tag{
    &.recent{
      background:#30838e;
    }
    &.qualified{
    background:#ff9900;
  }
  &.vob{
      background:#aa9821;
  }
  &.ready{
    background:#8314dd;
  }
  &.referred{
    background:#28b69e;
  }
  &.unqualified{
    background:#808080;
  }
  &.lost{
    background:#808080;
  }
  &.closed{
    background: #205cb5;
  }
  &.admitted{
    background:#ab4c84;
  }
  &.archived{
    background:#676668;
  }
  border-radius: 100px;
  background: #aed7de;
  color: #fff !important;
  height: 24px;
  font-size: 9pt;
  padding: 2px 15px;
  justify-content: center;
  display:inline-flex; 
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.status-dropdown{

}
.admit-date{
    border-left:solid 3px transparent;
    padding-left:10px;
    .a-title{
        padding:5px 0px;
        border-bottom:solid 1px #ebebeb;
        font-weight: bold;
        
    }
    &.detox{
        border-left:solid 3px #308151;
        .a-title{
            color:#308151
        }
        padding-left:10px;
    }
    &.residential{
        border-left:solid 3px #308ee8;
        .a-title{
            color:#308ee8
        }
        padding-left:10px;
    }
    &.iop{
        border-left:solid 3px #9d54e7;
        .a-title{
            color:#9d54e7
        }
        padding-left:10px;
    }
    &.op{
        border-left:solid 3px #cb50a0;
        .a-title{
            color:#cb50a0
        }
        padding-left:10px;
    }
    &.php{
        border-left:solid 3px #ff9900;
        .a-title{
            color:#ff9900
        }
        padding-left:10px;
    }
}
.admission-dates{
    display:flex;
    flex-direction: column;
    gap:15px;
}
.hot-cold{
    flex:1;
    background:#fff;
    border-radius: 100px;
    
    margin:0 15%;
    display:none;
    
    box-shadow: 3px 13px 20px -5px rgba(0,0,0,0.2);
    @include media-query($small) {

        box-shadow: 3px 5px 4px -5px rgba(0, 0, 0, 0.2);
        width:100%;
        margin:5px 15%;
        margin-right:0;
    }
    .hc-tab{
        flex:1;
        padding:5px 15px;
        height:100%;
        display:flex;
        align-items:center;
        font-size:10pt;
        justify-content: center;
        color:#aeaeae;
        &:nth-child(1){
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
        }
        &:nth-child(3){
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
        }
        transition:all .2s ease;
        &:hover{
            cursor: pointer;
            
            &.hot{
                background:lighten(#ff0000, 30%);
                color:#ff0000

            }
            &.warm{
                background:lighten(#ff9900, 30%);
                color:#ff9900
            }
            &.cold{
                background:lighten(#0059ff, 30%);
                color:#0059ff
            }
        }
        &.active{
            &.hot{
                background:#ff0000;
                color:#fff;
            }
            &.warm{
                background:#ff9900;
                color:#fff;
            }
            &.cold{
                background:#0059ff;
                color:#fff;
            }
        }
    }
}
.lead-dropdown-item{
    cursor: pointer;
    transition:all .3s ease;
    &:hover{
        background:#eaeaea;
    }
}
.gender-buttons{
    display:flex;
    align-items:center;
    gap:10px;
    .gender-button{
        border-radius: 4px;
        height:24px;
        padding:0px 10px;
        display:flex;
        align-items:center;
        color:#fff;
        background:var(--app-color);
        opacity: .5;
        transition:all .3s ease;
        gap:5px;
        &:hover{
            background:color-mix(in srgb,var(--color-primary),#000 15%);
            opacity: 1;
        }
        cursor: pointer;

        &.male{
            &.active{
                opacity: 1;
                background:#0059ff !important;
            }
            
        }
        &.female{
            &.active{
                opacity: 1;
                background:#ff0099 !important;
            }
            
        }
        &.active{
            filter:unset !important;
        }
    }
}
.history-container{
    display:flex;
    flex-direction: column;
    gap:10px;
    margin-top:10px;

    .history-item{
        padding:10px;
        border-radius: 3px;
        width:100%;
        border:solid 1px #eaeaea;
        display:flex;
        align-items: flex-start;
        gap:15px;
        
        .history-item-title{
            
            max-width:70px;
            
            i{
                color:var(--app-color);
                font-size:12pt;
            }
        }
        .history-form{
            padding:0px 20px;
            display:flex;
            flex-wrap:wrap;
            .history-form-item{
                flex:1;
                flex-basis: 50%;
                display:flex;
                align-items: flex-start;
                gap:15px;
                b{
                    min-width:150px;
                    display:block;
                    text-align:right;
                }
            }
        }
    }
}
</style>