<template>
    <div class="inner-stage">
        <v-dialog width="500" :dismissable="false" v-model="sigOpen">
            <v-card>
                <v-card-title>
                    Add Signauture
                </v-card-title>
                <v-card-text>
                    <div class="sigy">
                    <span>Signature</span>
                    <vueSignature
                        
                        ref="signature"
                        :sigOption="option"
                        :w="'100%'"
                        :h="'150px'"
                        
                    ></vueSignature>
                    <br>
                    
                    <v-btn text small compact @click="$refs.signature.clear()">Clear</v-btn><br>
                    <div class="sig-error" v-if="sigError">Signature cannot be blank</div>
                </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn small @click="sigOpen = false; $refs.signature.clear()" :disabled="signLoading" text depressed>Cancel</v-btn>
                    <v-btn small dark :color="appColor" depressed :loading="signLoading" @click="sign">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div v-if="isLoading"><v-progress-circular color="#eaeaea" indeterminate :size="50" :width="5"></v-progress-circular></div>

        
        

        <div  v-if="formDetails != null && !isLoading" class="form-container">
           <div class="f-name"><i class="fad fa-file"></i>{{formDetails.name}} <v-btn @click="$router.push(`/inventory/${$route.params.id}/new`)" text depressed small dense dark :color="appColor">Add&nbsp; <i style=" margin:0;" class="fal fa-plus"></i> </v-btn></div>
           <v-tabs background-color="#f8fafb" :color="appColor" v-model="activeTab">
            <v-tab :key="0" >Inventory List</v-tab>
            <v-tab :key="1" >History</v-tab>
           </v-tabs>
           <v-menu
            style="margin-top:10px; display:block;"
            v-model="dateMenu"
            :close-on-content-click="false"
            v-if="formDetails.name == 'First Aid/PPE Inventory Log'"
            max-width="290"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                :color="appColor"
                v-model="monthFormatted"
                style="padding-top:0 !important; max-width:250px;"
                @blur="month = parseDate(monthFormatted), monthFormatted = formatDate(month)"
                class="fixMe"
                outlined
                hide-details
                small
                dense
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="month = null"
                ></v-text-field>
            </template>
            <v-date-picker
                :color="appColor"
                type="month"
                v-model="month"
                @change="($event)=>{month = $event; monthFormatted = formatDate($event), dateMenu = false; getHistory()}"
                
            ></v-date-picker>
            </v-menu>
           <v-tabs-items background-color="transparent" style="overflow:visible !important" v-model="activeTab">
            
            <v-tab-item  :key="0" >
                <div style="padding-bottom:100px;">
            <div class="f-table-total">{{ inventory.length }} Total Records </div>
            <v-client-table
            v-if="!isLoading"
            style="overflow:visible !important"
            :data="inventory"
            :columns="columns"
            :options="{perPage:50}"
            @sorted="sortMe"
            >
            <div slot="date" slot-scope="{row}">
                {{ moment(row.date).tz('America/Los_Angeles').format('MM/DD/YYYY') }}
            </div>
            <div slot="name" style="white-space: nowrap;" slot-scope="{row}">
                <v-btn v-if="role == 'admin'" fab dense @click="deleteInventoryItem(row)" :disabled="incrementing" x-small compact mini text><i class=" fal fa-trash"></i></v-btn>
                {{ row.name }}
                <!-- <a @click="$router.push(`/forms/${$route.params.id}/edit/${row.id}`)" style="white-space: nowrap;">View Form</a> -->
            </div>
            <div slot="quantity" style="white-space: nowrap; display:flex; gap:5px; align-items: center;" slot-scope="{row}">
                {{ row.quantity }} 
                <div style="display:flex; align-items: center; margin-left:auto;">
                    <div class="refill-container">
                        <v-menu :close-on-content-click="false">

                            <template v-slot:activator="{on}">
                                <v-btn v-on="on" @click.stop="toggleRefill(row._id, true)"  dense :disabled="incrementing" x-small compact mini text>Use/Dispose</v-btn>
                            </template>
                            <div ref="refillpanel" class="refill-panel" >
                                <v-text-field outlined small label="Quantity" :color="appColor" v-model="refillQuantity" />
                                <v-btn @click.stop="activeRow = row, dec = true, toggleSig()" :loading="refillLoading" dense :disabled="incrementing" depressed dark :color="appColor" small compact mini>Submit</v-btn>
                            </div>
                        </v-menu>
                        

                        
                    </div>
                    <!-- <v-btn fab dense @click="incrementQuantity(row, -1)" :disabled="incrementing" compact mini  x-small text><i class=" fa fa-chevron-down"></i></v-btn></div> -->
                    <div class="refill-container">
                        <v-menu :close-on-content-click="false">

                        <template v-slot:activator="{on}">
                            <v-btn @click.stop="toggleRefill(row._id)" v-on="on" dense :disabled="incrementing" x-small compact mini text>Refill</v-btn>
                        </template>
                        <div ref="refillpanel" class="refill-panel" >
                            <v-text-field outlined small label="Quantity" :color="appColor" v-model="refillQuantity" />
                            <v-btn @click.stop="activeRow = row, toggleSig()" :loading="refillLoading" dense :disabled="incrementing" depressed dark :color="appColor" small compact mini>Refill</v-btn>
                        </div>
                        </v-menu>

                        </div>

                        
                </div>
                
                <!-- <a @click="$router.push(`/forms/${$route.params.id}/edit/${row.id}`)" style="white-space: nowrap;">View Form</a> -->
            </div>
            </v-client-table>
            </div>
            </v-tab-item>
            <v-tab-item :key="1">
                <div style="padding-bottom:100px;">
                <div class="f-table-total">{{ allHistory.length }} Total Records </div>
            <v-client-table
            v-if="!isLoading"
            style="overflow:visible !important"
            :data="allHistory"
            :columns="historyColumns"
            :options="{perPage:50}"
            @sorted="sortMe"
            >
            <div slot="date" slot-scope="{row}">
                {{ moment(row.date).tz('America/Los_Angeles').format('MM/DD/YYYY') }}
            </div>
            <div slot="name" style="white-space: nowrap;" slot-scope="{row}">
                <v-btn v-if="role == 'admin'" fab dense @click="deleteInventoryHistory(row)" x-small compact mini text><i class=" fal fa-trash"></i></v-btn>
                {{ row.name }}
                <!-- <a @click="$router.push(`/forms/${$route.params.id}/edit/${row.id}`)" style="white-space: nowrap;">View Form</a> -->
            </div>
            <div slot="quantity" style="white-space: nowrap; display:flex; gap:5px; align-items: center;" slot-scope="{row}">
                {{ row.quantity }} 
            </div>
            <div slot="staff_member" style="white-space: nowrap; display:flex; gap:5px; align-items: center;" slot-scope="{row}">
                {{ row.user.first_name }} {{ row.user.last_name }} 
            </div>
            <div slot="signature" slot-scope="{row}">
                <img v-if="row._id != null" style="height:45px;" :src="`/api/static/forms/${row.formId}/${row._id}_signature.jpg`" />
            </div>
            </v-client-table>
        </div>
            </v-tab-item>
           </v-tabs-items>
           
        </div>

    </div>
</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import vueSignature from "vue-signature";
import { mapGetters } from "vuex";
export default {
    name:'inventory',
    data(){
        return {
            dateMenu:false,
            month: moment().tz('America/Los_Angeles').startOf('M').format('YYYY-MM-DD'),
            monthFormatted:this.formatDate((new Date(moment().tz('America/Los_Angeles').startOf('M').format('YYYY-MM-DD'))).toISOString().substr(0, 10)),
            moment,
            isLoading:true,
            formDetails:null,
            formsFilled:[],
            filters:[],
            filterSort:'dec',
            isRunning:false,
            isInventory:false,
            total:0,
            historyTotal:0,
            deletingFormEntryLoading:false,
            allForms:[],
            inventory:[],
            incrementing:false,
            refillIndex:-1,
            refillQuantity:0,
            windowClickEvent: null,
            refillLoading:false,
            dec:false,
            sigOpen: false,
            activeRow:null,
            activeTab:0,
            allHistory:[],
            signLoading:false,
            sigError:false,
            option:{penColor: "rgb(0, 0, 0)", backgroundColor: "rgb(255,255,255)"},
        }
    },
    async mounted(){
        let q = this.$route.query;
        if(q.sort == null){
            this.$router.push({
            query: { ...this.$route.query, filter: 'date', sort: 'dec' },
        });
        }
        if (q.term != null) {
        this.term = q.term;
        }
        await this.getFormDetails();
        await this.getHistory();
        await this.getInventory();
    },
    methods:{
        async sign(){
            const item = this.activeRow;
            const dec = this.dec;
            this.signLoading = true;
            let errorCheck = false;
            this.sigError = false;
            if(this.$refs.signature.isEmpty()){
                errorCheck = true;
                this.sigError = true;
            }
            if(errorCheck){
                this.signLoading = false;
                console.log('HAS ERROR')
                return;
            }
            let amount2 = dec ? Number(this.refillQuantity) * -1 : Number(this.refillQuantity);
            console.log(amount2);
            let post2 = {
                quantity: Number(item.quantity) + amount2 < 0 ? 0 : Number(item.quantity) + amount2,
            }
            await axios.put(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}inventory/edit/${item._id}`, post2);
            let formData = new FormData();
            const dataURLtoFile = (dataurl, filename) => {
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[arr.length - 1]), 
                    n = bstr.length, 
                    u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {type:mime});
            }

            let amount = dec ? Number(this.refillQuantity) * -1 : Number(this.refillQuantity);
                let post = {
                    name:item.name,
                    itemId:item._id,
                    quantity: amount,
                    user:this.user,
                    formId:this.formDetails._id
                }
            let {data} = await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}inventory/add_history`, post);
            let insertedId = data.insertedId;
            let jpeg = this.$refs.signature.save("image/jpeg");
            formData.append('sig', dataURLtoFile(jpeg), `${insertedId}_signature.jpg`);
            await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/upload_signature/${this.formDetails._id}`, formData);
            this.signLoading = false;
            this.sigOpen = false;
            this.getInventory();
            this.getHistory();
        }, 
        toggleSig(){
            this.sigOpen = true;
        },
        async refill(){
            const item = this.activeRow;
            const dec = this.dec;
            try{

                
                this.refillLoading = true;
                let amount = dec ? Number(this.refillQuantity) * -1 : Number(this.refillQuantity);
                let post = {
                    quantity: Number(item.quantity) + amount < 0 ? 0 : Number(item.quantity) + amount,
                }
                let { data } = await axios.put(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}inventory/edit/${item._id}`, post);
                if(item.alert_quantity > 0){
                    if(Number(post.quantity) <= Number(item.alert_quantity)){
                        this.addReminder(item.name);
                    }
                }
                let apost = {
                    userId:this.user._id,
                    type:'inventory',
                    quantity: amount,
                    date:new Date(),
                    signature:null
                }


                await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}activity/inventory_add`, apost);
                this.inventory[this.inventory.findIndex((item2)=>item2._id == item._id)].quantity = Number(item.quantity) + amount;
                this.refillLoading = false;
                this.refillIndex = -1;
            }catch(e){
                this.refillLoading = false;
                console.log('error')
            }
        },
        toggleRefill(id,dec=false){
            this.dec = dec;
            this.refillQuantity = 0;
            if(this.refillIndex == id){
                this.refillIndex = -1;
            }else{

                window.addEventListener(
				"click",
                    (this.windowClickEvent = (e) => {
                        const x = e.clientX;
                        const y = e.clientY;
                        const els = document.elementsFromPoint(x, y);
                
                        let arr = Array.from(els);
                        let classes = [];
                        
                        for(let i = 0; i < arr.length; i++){
                            classes = classes.concat(Array.from(arr[i].classList))
                        }
                        
                        if (classes.indexOf("refill-panel") < 0) {
                            this.refillIndex = -1
                            window.removeEventListener('click', this.windowClickEvent)
                        }
                    })
                );
                
                this.refillIndex = id;
            }
        },
        async deleteInventoryItem(item){
            try{
                this.incrementing = true;
                let { data } = await axios.delete(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}inventory/delete/${item._id}`);
                this.inventory.splice(this.inventory.findIndex((item2)=>item2._id == item._id), 1)
                this.incrementing = false;
            }catch(e){
                console.log(e);
                this.incrementing = false;
                console.log('error')
            }
        },
        async deleteInventoryHistory(item){
            try{
                let { data } = await axios.delete(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}inventory/delete_history/${item._id}`);
                this.getHistory();
            }catch(e){
                console.log(e);
            }
        },
        async addReminder(name){
            this.reminderLoading = true;
            let post = {
                date:new Date(),
                message:`INVENTORY: ${name} has reached a low quantity. Please refill ASAP.`,
            }
            await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}reminders/add`, post);
            let { data } = await axios.get(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}reminders/all`);
            this.$store.dispatch('setReminders', data.items);

        },
        async getHistory(){
            try{
                if(this.isRunning || this.formDetails.name == 'First Aid/PPE Inventory Log'){
                    this.filters = [
                        {
                            name:'date',
                            type:'date',
                            operator:'after',
                            startDate:moment(this.month).tz('America/Los_Angeles').utc().startOf('M').toDate()
                        },
                        {
                            name:'date',
                            type:'date',
                            operator:'before',
                            startDate:moment(this.month).tz('America/Los_Angeles').utc().endOf('M').toDate()
                        }
                    ]
                }

                let post = {
                    formId:this.formDetails._id,
                    filters: this.filters
                };
                let {data} = await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}inventory/get_history/${this.formDetails._id}`, post )
                this.allHistory = data.items;
            } catch(e){

            }
        },
        async incrementQuantity(item, amount){
            try{
                this.incrementing = true;
                let post = {
                    quantity: Number(item.quantity) + Number(amount),
                }
                let { data } = await axios.put(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}inventory/edit/${item._id}`, post);
                if(item.alert_quantity > 0){
                    if(Number(post.quantity) <= Number(item.alert_quantity)){
                        this.addReminder(item.name);
                    }
                }
                this.inventory[this.inventory.findIndex((item2)=>item2._id == item._id)].quantity = Number(item.quantity) + Number(amount);
                this.incrementing = false;
            }catch(e){
                this.incrementing = false;
                console.log('error')
            }
        },
        
        async getInventory(){
            try{
                this.isLoading = true;
                let {data} = await axios.get(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}inventory/${this.$route.params.id}`);
                this.inventory = data.items;
                this.isLoading = false;
            }catch(e){
                this.isLoading = false;
                window.alert('There was a problem fetching inventory')
            }
        },
        async getFormDetails(){
            
            try{
                let {data} = await axios.get(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_form_details/${this.$route.params.id}`)
                this.formDetails = data.result;
                this.isRunning = data.result.type == 'running';
                this.isInventory = data.result.type == 'inventory'
            }catch(e){
                console.log(e);
            }
        },
        formatDate (date) {
            if (!date) return null
            const [year, month] = date.split('-')
            return `${month}/${year}`
        },
        parseDate (date) {
            console.log(date);
            if (!date) return null
            const [month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-01`
        },
        sortMe(eve) {
            let f = "asc";
            if (this.$route.query.sort == "asc") {
                f = "dec";
            } else {
                f = "asc";
            }
            this.columnSort = eve.column;
            this.$router.push({
                query: { ...this.$route.query, filter: eve.column, sort: f },
            });

           // this.getFormsFilled();
        },

    },
    components:{
        vueSignature
    },
    computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        ...mapGetters({
            user:'AUTH_USER',
            facility:'FACILITY'
        }),
        role(){
            return this.user.role;
        },
        columns(){
            return [
                "name",
                "quantity",
            ]
        },
        historyColumns(){
            return [
                "date",
                "name",
                "quantity",
                "staff_member",
                "signature"
               
            ]
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss.scss";
.form-container{
  
    padding:15px;
    .f-name{
        font-size:15pt;
        
       font-weight: bold;
        
        i{
            color:var(--app-color);
            margin-right:10px;
        }
        
    }
    text-align: left;


}
.f-table-total{
    margin:15px 0px;
    color:#000;
    font-weight: bold;
}
.trashentry{
    padding:0px 5px;
    opacity: .3;
    transition: all .3s ease;
    cursor: pointer;
    &:hover{
        opacity: 1;

    }
}
.refill-container{
    position:relative;
    
}
.refill-panel{
        min-width: 150px;
        
        z-index:1000;
        top:100%;
        right:0;
        padding:15px;
        background:#fff;
        border-radius: 4px;
        box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    }
.table-responsive{
    table{
        overflow: hidden !important;
        border-radius: 10px;
        border:solid 1px #aeaeae;
        box-shadow: 0px 10px 20px -8px rgba(0,0,0,0.15);
    }
}
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>