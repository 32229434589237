<template>
    <div class="c">
        <!-- <v-dialog v-model="uploadPic" width="500">
            
                <v-card style="max-width:100%; padding:15px;">
                    <div @click="chooseImage" class="client-image">
                        <img :src="clientImageSrc" alt="">
                        
                    </div>
                    <div class="actions">
                        <v-btn depressed color="#eaeaea" @click="uploadPic = false">Cancel</v-btn>&nbsp;&nbsp;
                        <v-btn @click="uploadClientImage" :color="appColor" depressed dark :loading="uploading">Upload</v-btn>
                    </div>
                    
                </v-card>
            
        </v-dialog> -->
        <input @change="changeImage" ref="clientImage" class="image-input" type="file">
        <div v-if="!isLoaded"  class="loaderContainer animated-fast">
        <div   class="wrapper" style="margin-top:0;">
          <div class="wrapper-cell" style="padding:0">
            <div class="text">
              <div class="text-line" style=" height:unset; padding:25px; margin-bottom:55px; padding-top:45px; display:flex; align-items:center;">
                  <div class="text-line2" style="height:300px; width:300px; margin-right:20px;"></div>
                  <div style="display:grid; grid-template-columns:1fr 1fr; grid-gap:15px; flex:1;">
                      <div class="text-line2" style="height:80px; "></div>
                      <div class="text-line2" style="height:80px; "></div>
                      <div class="text-line2" style="height:80px; "></div>
                      <div class="text-line2" style="height:80px; "></div>
                  </div>
              </div>
              <div class="text-line" style="height:unset; padding:25px;margin-bottom:55px;">
                  <div style="display:grid; grid-template-columns:1fr 1fr 1fr; grid-gap:15px;">
                      <div class="text-line2" style="height:170px; "></div>
                      <div class="text-line2" style="height:170px; "></div>
                      <div class="text-line2" style="height:170px; "></div>
                      <div class="text-line2" style="height:170px; "></div>
                      <div class="text-line2" style="height:170px; "></div>
                      <div class="text-line2" style="height:170px; "></div>
                  </div>
                  
              </div>
              <div class="text-line" style="height:400px;"></div>
            </div>
          </div>

        </div>
        </div>
        <!-- <div v-if="!isLoaded" style="height:calc(100vh - 200px); display:flex; align-items:center; justify-content:center;">
            <img  src="@/assets/double_loader.svg" alt="">
        </div> -->
        <div class="page">
            <div style="display:flex; width:100%; position:relative;">
            <div class="top-tabs">
                <div @click="activeTab = 'patient'" class="top-tab-item" :class="{active:activeTab == 'patient'}">Employee Info</div>
                <div @click="activeTab = 'documents'" class="top-tab-item" :class="{active:activeTab == 'documents'}">Documents</div>
                <div @click="activeTab = 'history'" class="top-tab-item" :class="{active:activeTab == 'history'}">History</div>
            </div>
            <div class="lead-actions">
                <span @click="saveEdit" class="save-change"><i class="fa fa-sync animated infinite" :class="{spin:saving}"></i> <span>Save Changes</span></span>
                <span v-if="$route.params.id != 'new'" @click="archiveLead" class="archive-change"> Archive</span>
            </div>
            </div>
            <div v-if="activeTab == 'patient'" class="tab-stage">
                
                <div class="gridly">
                <div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Employee Name</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.name">
                        </div>
                    </div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Email</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.email">
                        </div>
                    </div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Phone</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.phone">
                        </div>
                    </div>
                    
                </div>
                
                <div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">DOB</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.dob">
                        </div>
                    </div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Gender</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.gender">
                        </div>
                    </div>
                    
                    
                </div>
                </div>
                <h2>Address</h2>
                <div class="gridly">
                <div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Street</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.street1">
                        </div>
                    </div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">City</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.city">
                        </div>
                    </div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">State</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.state">
                        </div>
                    </div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Zip</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.zip">
                        </div>
                    </div>
                    
                    
                </div>
            </div>
                <h2>Family Contact</h2>
                <div class="gridly">
                    <div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Name</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.family_contact_name">
                        </div>
                    </div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Relationship</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.family_contact_relationship">
                        </div>
                    </div>
                    
                    
                </div>
                <div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Email</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.family_contact_email">
                        </div>
                    </div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Phone</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.family_contact_phone">
                        </div>
                    </div>
                </div>
                
                </div>
                
            </div>

            <div v-if="activeTab == 'documents'" class="tab-stage">
                <div class="gridly">
                <div>
                    <div class="lead-list-item">
                        <div class="lead-list-item-title">Employee name</div>
                        <div class="lead-list-item-value">
                            <input type="text" v-model="editUser.name">
                        </div>
                    </div>

                    
                </div>
                </div>
                <!-- <h2>Documents</h2>
                <div class="gridly">
                    <div @click="beginUpload('DL')" class="blank-document">
                        <input @change="selectFile" type="file" ref="DL">
                        <img v-if="dl_src != null" :src="dl_src" alt="">
                        <i  v-if="dl_src == null" class="fa fa-user"></i>
                        <span v-if="dl_src == null" >Drivers License</span>
                    </div>
                    <div @click="beginUpload('ICF')" class="blank-document">
                        <input @change="selectFile" type="file" ref="ICF">
                        <img v-if="icf_src != null" :src="icf_src" alt="">
                        <span v-else>Insurance Card Front</span>
                    </div>
                    <div @click="beginUpload('ICB')" class="blank-document">
                        <input @change="selectFile" type="file" ref="ICB">
                        <img v-if="icb_src != null" :src="icb_src" alt="">
                        <span v-else>Insurance Card Back</span>
                    </div>
                    
                </div> -->
                <div class="document-table">
                    <h2>Documents</h2>
                    <v-client-table :columns="columns" :data="documents">
                        <div class="file-name-slot" slot="name" slot-scope="{ row }">
                           
                            <i v-if="row.name.indexOf('.pdf') > -1" :class="'pdf big'" class="fad fa-file-pdf"></i> 
                            <i v-if="row.name.indexOf('.docx') > -1" :class="'word big'" class="fad fa-file-word"></i> 
                            <i v-if="row.name.indexOf('.xlsx') > -1" :class="'xlsx big'" class="fad fa-file-excel"></i> 
                            <i v-if="row.name.indexOf('.csv') > -1" :class="'xlsx big'" class="fad fa-file-csv"></i> 
                            <i v-if="row.name.indexOf('.jpg') > -1" :class="'jpg big'" class="fad fa-file-image"></i> 
                            <i v-if="row.name.indexOf('.jpeg') > -1" :class="'jpg big'" class="fad fa-file-image"></i> 
                            <i v-if="row.name.indexOf('.png') > -1" :class="'jpg big'" class="fad fa-file-image"></i> 
                           
                           {{ row.name }}
                        </div>
                        <div slot="date" slot-scope="{ row }">
                           <i class="fad fa-clock"></i> {{ moment(row.date).format('MM/DD/YYYY hh:mm') }}
                        </div>
                    </v-client-table>
                </div>
                
            </div>
            <div v-if="activeTab == 'history'" class="tab-stage">
                
                <h2 style="display:flex; align-items:center;">History</h2>
                
                <div v-if="historyLoading" style="display:flex;align-items:center;justify-content:center;height:1px;width:100%">
                        <v-progress-circular width="3" indeterminate size="30" :color="appColor"></v-progress-circular>
                    </div>
                <div class="note-container"  >
                    <div class="note-item" v-for="(item,i) in filteredHistory" :key="i">
                        <div class="note-title">{{moment(item.date).format('MMM DD YYYY HH:mm A')}} {{item.user_name}}  <i @click="deleteNote(item._id)" class="fal fa-trash"></i> </div>
                        <div class="note-body">{{item.message}}</div>
                    </div>
                    <div class="no-notes" v-if="notes.length == 0 && !historyLoading">
                        No History
                    </div>
                   
                    
                </div>
            </div>
        </div>
        <div v-if="false" class="page">
            <div class="c-card">
                <div style="display:flex" class="c-title" @click="expand('basic')"><i :class="{rotateme:expanded.indexOf('basic') > -1}" class="fal fa-chevron-down"></i> Basic Information </div>
                
                <div  class="flex" style="display:flex; flex-wrap:nowrap; align-items:center">
                    <div class="div">
                        <div @click="chooseImage" class="profile-pic">
                        <img :src="clientImageSrc" alt="">
                    </div>
                    </div>
                    
                    <div  class="c-inner" style="display:grid;grid-gap:15px; grid-template-columns:1fr 1fr">
                        <div class="c-item">
                            <div class="c-label"><i class="fal fa-user"></i> Name</div>
                            <div v-if="editingField != 'name'" class="c-value" @click="editingField = 'name'">{{lead.name || 'N/A'}}</div>
                            <div v-if="editingField == 'name'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.name" @keypress.enter="saveEdit" label="Name"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                        <div class="c-item">
                            <div class="c-label"><i class="fal fa-envelope"></i> Email</div>
                            <div v-if="editingField != 'email'" class="c-value" @click="editingField = 'email'">{{lead.email || 'N/A'}}</div>
                            <div v-if="editingField == 'email'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.email" @keypress.enter="saveEdit" label="Email"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                        <div class="c-item">
                            <div class="c-label"><i class="fal fa-phone"></i> Phone</div>
                            <div v-if="editingField != 'phone'" class="c-value" @click="editingField = 'phone'">{{formatPhoneNumber(lead.phone) || 'N/A'}}</div>
                            <div v-if="editingField == 'phone'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.phone" @keypress.enter="saveEdit" label="Phone"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>

                        <div class="c-item">
                            <div class="c-label"><i class="fal fa-calendar"></i> DOB</div>
                            <div v-if="editingField != 'dob'" class="c-value" @click="editingField = 'dob'">{{moment(lead.dob).add(1, 'd').tz('America/Los_Angeles').format('MM/DD/YYYY')  || 'N/A'}}</div>
                            <div v-if="editingField == 'dob'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.dob" @keypress.enter="saveEdit" label="DOB"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                        
                        
                    
                        
                    
                    </div>
                </div>
                
            </div>
            <div class="c-card">
                <div style="display:flex" class="c-title" @click="expand('address')"><i  :class="{rotateme:expanded.indexOf('address') > -1}" class="fal fa-chevron-down"></i> Address Information </div>
                <v-expand-transition>
                    <div v-if="expanded.indexOf('address') > -1" class="flex" style="display:flex; align-items:center">

                    
                    <div v-if="lead.address != '' && !editAddress" class="address-c">
                        <span style="font-size:1.5em; font-weight:bold"><v-btn @click="editAddress = true" icon depressed><i  class="fal fa-pencil"></i></v-btn> {{lead.address}} {{lead.city}} {{lead.state}} {{lead.zip}}</span>
                    </div>
                    <div v-if="lead.address == '' && !editAddress" class="none" style="margin-bottom:15px">
                        <div >No Address on File <br><br>
                        <v-btn @click="editAddress = true" depressed small dark :color="appColor"><i class="fal fa-pencil"></i> &nbsp;Add Address</v-btn>
                        </div>
                    </div>
                    <div v-if="editAddress">

                    
                    
                        <v-form class="edit-c" v-model="addressValid">

                        
                        <v-text-field :color="appColor" :rules="reqRules" label="Street" v-model="editUser.address" outlined />
                    
                        <v-text-field :color="appColor" :rules="reqRules" label="City" v-model="editUser.city" outlined />
                        <v-select :color="appColor" :rules="reqRules" :items="states"  label="State" v-model="editUser.state" outlined ></v-select>
                        <v-text-field :color="appColor" :rules="reqRules" label="Zip"  v-model="editUser.zip" outlined />
                        </v-form>
                        
                
                    <div style="width:100%; display:flex;">
                        <div style="margin-left:auto; display:flex; align-items:center;">
                        <v-btn @click="cancelEditing" depressed style="margin-left:auto" color="#eaeaea">Cancel</v-btn>
                        <v-btn :disabled="!addressValid" @click="saveEdit" depressed style="margin-left:10px" :dark="addressValid" :loading="saving" :color="appColor">Save</v-btn>
                        </div>
                    </div>
                    </div>
                    <div v-if="markers != null && lead.address != '' && !editAddress" style="z-index:1; height:400px; flex:1; margin-bottom:15px; position:relative;">
                    <GmapMap
                    :center="{lat, lng}"
                    :zoom="15"
                    map-type-id="terrain"
                    class="map"
                    >
                    <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        @click="center=m.position"
                    />
                    </GmapMap>
                    </div>
                    </div>
                </v-expand-transition>
            </div>
            <div class="c-card">
                <div style="display:flex" class="c-title" @click="expand('insurance')"><i  :class="{rotateme:expanded.indexOf('insurance') > -1}" class="fal fa-chevron-down"></i> Insurance Information </div>
                <v-expand-transition>
                <div v-if="expanded.indexOf('insurance') > -1" class="c-inner">
                    <div class="c-item">
                            <div class="c-label">Provider</div>
                            <div v-if="editingField != 'provider'" class="c-value" @click="editingField = 'provider'">{{lead.provider || 'N/A'}}</div>
                            <div v-if="editingField == 'provider'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-select outlined :items="providers" v-model="editUser.provider" @keypress.enter="saveEdit" label="Provider"></v-select>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                    <div class="c-item">
                            <div class="c-label">Primary Holder</div>
                            <div v-if="editingField != 'primary_holder'" class="c-value" @click="editingField = 'primary_holder'">{{lead.primary_holder || 'N/A'}}</div>
                            <div v-if="editingField == 'primary_holder'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.primary_holder" @keypress.enter="saveEdit" label="Primary Holder"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                    <div class="c-item">
                            <div class="c-label">Policy Holder Name</div>
                            <div v-if="editingField != 'policy_holder_name'" class="c-value" @click="editingField = 'policy_holder_name'">{{lead.policy_holder_name || 'N/A'}}</div>
                            <div v-if="editingField == 'policy_holder_name'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.policy_holder_name" @keypress.enter="saveEdit" label="Policy Holder Name"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                    
                     <div class="c-item">
                            <div class="c-label">Member ID</div>
                            <div v-if="editingField != 'member_id'" class="c-value" @click="editingField = 'member_id'">{{lead.member_id || 'N/A'}}</div>
                            <div v-if="editingField == 'member_id'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.member_id" @keypress.enter="saveEdit" label="Member ID"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                    
                </div>
                </v-expand-transition>
            </div>
            <div class="c-card">

                <div class="c-title"  style="display:flex" @click="expand('payment')"><i :class="{rotateme:expanded.indexOf('payment') > -1}" class="fal fa-chevron-down"></i>  Stay & Payment Information </div>
                <v-expand-transition>
                    <div v-if="expanded.indexOf('payment') > -1" class="c-inner" style="grid-template-columns:1fr 1fr">
                        <div class="c-item">
                                <div class="c-label"><i class="fal fa-calendar"></i> Planned Admission Date</div>
                                <div v-if="editingField != 'treatment_start_date'" class="c-value" @click="editingField = 'treatment_start_date'">{{lead.treatment_start_date != null ? moment(lead.treatment_start_date).add(1, 'd').tz('America/Los_Angeles').format('MM/DD/YYYY')  : 'N/A'}}</div>
                                <div v-if="editingField == 'treatment_start_date'" class="c-edit">
                                    
                                    <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                    <v-menu
                                        ref="startDate"
                                        v-model="startDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            :color="appColor"
                                            v-model="date"
                                            label="Treatment Start Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            :active-picker.sync="activePicker"
                                            :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                            
                                            @change="save($event, 'startDate', 'treatment_start_date')"
                                        ></v-date-picker>
                                        </v-menu>&nbsp;&nbsp;
                                    <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                    
                                </div>
                            </div>
                        <div class="c-item">
                                <div class="c-label"><i class="fal fa-calendar"></i> Treatment End Date</div>
                                <div v-if="editingField != 'treatment_end_date'" class="c-value" @click="editingField = 'treatment_end_date'">{{lead.treatment_end_date != null ?moment(lead.treatment_end_date).add(1, 'd').tz('America/Los_Angeles').format('MM/DD/YYYY')  : 'N/A'}}</div>
                                <div v-if="editingField == 'treatment_end_date'" class="c-edit">
                                    
                                    <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                    <v-menu
                                        ref="endDate"
                                        v-model="endDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            :color="appColor"
                                            v-model="date"
                                            label="Treatment End Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            :active-picker.sync="activePicker"
                                            :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                            
                                            @change="save($event, 'endDate', 'treatment_end_date')"
                                        ></v-date-picker>
                                        </v-menu>&nbsp;&nbsp;
                                    <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                    
                                </div>
                            </div>

                        <div class="c-item">
                            <div class="c-label"><i class="fal fa-user"></i> Payment Type</div>
                            <div v-if="editingField != 'payment_type'" class="c-value" @click="editingField = 'payment_type'">{{lead.payment_type || 'N/A'}}</div>
                            <div v-if="editingField == 'payment_type'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.payment_type" @keypress.enter="saveEdit" label="Payment Type"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                        <div class="c-item"></div>
                        <div class="c-item">
                            <div class="c-label"><i class="fal fa-user"></i> Total Paid To Date</div>
                            <div v-if="editingField != 'total_paid_to_date'" class="c-value" @click="editingField = 'total_paid_to_date'">${{lead.total_paid_to_date || '0'}}</div>
                            <div v-if="editingField == 'total_paid_to_date'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.total_paid_to_date" @keypress.enter="saveEdit" label="Total Paid to Date"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                        <div class="c-item">
                            <div class="c-label"><i class="fal fa-user"></i> Total Amount Due</div>
                            <div v-if="editingField != 'amount_due'" class="c-value" @click="editingField = 'amount_due'">${{lead.amount_due || '0'}}</div>
                            <div v-if="editingField == 'amount_due'" class="c-edit">
                                
                                <v-btn @click="cancelEditing" icon depressed><i class="fal fa-times"></i></v-btn>&nbsp;&nbsp;
                                <v-text-field :color="appColor" outlined v-model="editUser.amount_due" @keypress.enter="saveEdit" label="Amount Due"></v-text-field>&nbsp;&nbsp;
                                <v-btn icon depressed @click="saveEdit" :loading="saving"><i class="fal fa-check"></i></v-btn>
                                
                            </div>
                        </div>
                    </div>
                </v-expand-transition>
                </div>
                <div class="c-card">

                <div class="c-title" style="display:flex" @click="expand('page')"><i  :class="{rotateme:expanded.indexOf('page') > -1}" class="fal fa-chevron-down"></i> Page </div>
                <v-expand-transition>

                <v-expand-transition>
                    <div v-if="expanded.indexOf('page') > -1"  class="c-inner">
                        
                    <div class="c-item">
                        <div class="c-label"><i class="fal fa-sync"></i> From Page</div>
                        <div class="c-value">{{lead.from  || 'N/A'}}</div>
                    </div>
                </div>
                </v-expand-transition>
                
                </v-expand-transition>
                </div>
        </div>
        
        
    </div>
</template>
<script>
import axios from 'axios';
import states from '@/assets/states.json';
import moment from 'moment-timezone';
import _ from 'lodash';
import {mapGetters} from 'vuex';
export default {
    name:'viewUser',
    data(){
        return {
            
            notes:[],
            historyLoading:false,
            showAddNote:false,
            newNoteMessage:'',
            activeTab:'patient',
            expanded:['basic'],
            clientImageSrc:require('@/assets/male.webp'),
            clientImageFile:null,
            uploadPic:false,
            editingField:'',
            saving:false,
            states,
            moment,
            markers: null,
            lat:null,
            lng:null,
            user:null,
            editUser:null,
            isLoaded:false,
            editAddress:false,
            addressValid:false,
            reqRules: [
                v => !!v || 'Required',
            ],
            activePicker: null,
            startDate:false,
            endDate:false,
            vob_src:null,
            icf_src:null,
            icb_src:null,
            dl_src:null,
      date: null,
      menu: false,
      activeUploadType:null,
      providers:[
          '',
        'aetna',
        'bcbs',
        'beacon',
        'cigna',
        'medicaid',
        'healthnet',
        'hmsa',
        'humana',
        'magellan',
        'optim',
        'united_healthcare',
      ]
            
        }
    },
    mounted(){
        this.getUser();
    },
    methods:{
        async getNotes(){
            this.historyLoading = true;
            let n = await axios.get(`/api/users/get_notes/${this.$route.params.id}`);
            
            this.notes = n.data.notes;

            
            this.historyLoading = false;
        },
        async addNote(){
            this.historyLoading = true;
            let note = {
                lead_id:this.$route.params.id,
                date:new Date(),
                message:this.newNoteMessage,
                user_name:this.user.first_name + ' ' + this.user.last_name,
                user_id:this.user.id
            }
            await axios.post(`/api/leads/add_note/${this.$route.params.id}`, note);
            this.showAddNote = false;
            this.newNoteMessage = '';
            this.getNotes();
        },
        async deleteNote(id){
            this.historyLoading = true;
            await axios.delete(`/api/leads/delete_note/${id}`);
            this.getNotes();
        },
        downloadFile(src){
            let a = document.createElement('a');
            a.href = src;
            a.target = '_blank';
            a.click();
        },
        async selectFile(){
            let formData = new FormData();
            formData.append('file', this.$refs[this.activeUploadType].files[0], this.$refs[this.activeUploadType].files[0].name);
            if(this.activeUploadType == 'DL' || this.activeUploadType == 'ICF' || this.activeUploadType == 'ICB'){
                this[this.activeUploadType.toLowerCase() + '_src'] = URL.createObjectURL(this.$refs[this.activeUploadType].files[0]);
            }
            formData.append('type',this.activeUploadType.toLowerCase());
            await axios.post(`/api/leads/upload_file/${this.$route.params.id}`, formData);


            console.log(this.$refs[this.activeUploadType].files[0]);
        },
        beginUpload(type){
           
           this.activeUploadType = type;
            this.$refs[type].click();
        },
        expand(type){
            if(this.expanded.indexOf(type) > -1){
                this.expanded.splice(this.expanded.indexOf(type), 1);
            }else{
                this.expanded.push(type);
            }
        },
        async uploadImage(){
            let formData = new FormData();
            formData.append('photo', this.clientImageFile, 'profile.jpg');
            await axios.post('/api/leads/upload_image/' + this.$route.params.id, formData);
        },
        changeImage(e){
            this.clientImageSrc = URL.createObjectURL(e.target.files[0]);
            this.clientImageFile = e.target.files[0];
            this.uploadImage();
        },
        chooseImage(){
            this.$refs.clientImage.click();
        },
        save (date, ref, field) {
            console.log(ref);
            this.editUser[field] = new Date(date);
            console.log(this.editUser);
            this.$refs[ref].save(date)
      },
        cancelEditing(){
            this.editUser = JSON.parse(JSON.stringify(this.user));
            this.closeEdits();
        },
        closeEdits(){
            this.editAddress = false;
            this.editingField = '';
            this.saving = false;
        },
        async saveEdit(){
            
            this.saving = true;
            let lead = JSON.parse(JSON.stringify(this.editUser));
            delete lead._id;
            let post = lead;
            try{
                if(this.$route.params.id == 'new'){
                    let f = await axios.post('/api/leads/add', post);
                    this.$router.push('/lead/' + f.data.result.insertedId);
                }else{
                   await axios.put('/api/leads/edit/' + this.$route.params.id + `?userId=${this.user.id}`, post) 
                }
                
                
                this.getUser();
                
                
            }catch(e){
                if(e){
                    window.alert('There was an error updating. Please Try Again Later.')
                }
            }
            
        },
        async archiveLead(){
            
            this.saving = true;
            let lead = JSON.parse(JSON.stringify(this.editUser));
            delete lead._id;
            lead.status = 'archive';
            let post = lead;
            try{
                
                   await axios.put('/api/leads/edit/' + this.$route.params.id  + `?userId=${this.user.id}`, post) 
                
                
                
                this.$router.push('/leads');
                
                
            }catch(e){
                if(e){
                    window.alert('There was an error updating. Please Try Again Later.')
                }
            }
            
        },
        async getAddress(){
            let a = `${this.user.address} ${this.user.city} ${this.user.state} ${this.user.zip}`;
            let {data} = await axios.post('/api/geocode', {address:a});
            console.log(data);
            this.markers = [data.markers];
            this.lat = data.lat;
            this.lng = data.lng;
        },
        geocodedAddress() {
        //var self = this;
        //let geocoder = new google.maps.Geocoder();
        let a = `${this.user.address} ${this.user.city} ${this.user.state} ${this.user.zip}`;
        let theLocations = [a];

        return Promise.all(_.map(theLocations, addr => { 

        var geocoder = new google.maps.Geocoder();

            var locationss = { 
                lat : parseFloat(addr.lat),
                lng : parseFloat(addr.lng)
            } ; 

            // var sampleLocation = { lat: 1.39, lng: 103.8 };

            return new Promise(function(resolve, reject) {
                geocoder.geocode({'location': locationss }, function(results, status){
                    if (status === 'OK') {
                    if (results[0]) {
                        return results[0].formatted_address;
                    } else {
                        console.log(status);
                        window.alert('No results found');
                        return null
                    }
                    }
                })
            });
        })).then(data => {
            console.log(data)
            this.formatedAddresses = data
        })
    },
        async geocodeAddress(){
            let a = `${this.user.address} ${this.user.city} ${this.user.state} ${this.user.zip}`;
            const address = encodeURI(a);
        const { data } = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAy3Rt7-IMFBi39YXHjjKhI0icjWDBM8DM`
        );

        if (data.status !== "OK") {
            //no results
            console.log('no results')
        }
        
        const geocodedLocation = data.results[0];
        
        //create record for firestore
        const objGeolocation = {
            address: geocodedLocation.formatted_address,
            geoPoint: new admin.firestore.GeoPoint()
        }
        this.lat = geocodedLocation.geometry.location.lat
        this.lng = geocodedLocation.geometry.location.lng

        this.markers.push({position:{lat:geocodedLocation.geometry.location.lat, lng: geocodedLocation.geometry.location.lng}})
        },
        formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    async getUser(){

            let l = await axios.get('/api/users/user/' + this.$route.params.id);
        
            try{
                let pic = await axios.get('/api/static/' + this.$route.params.id + '/profile.jpg');
                if(pic.status == 200){
                    this.clientImageSrc = '/api/static/' + this.$route.params.id + '/profile.jpg'
                }
            }catch(e){
                
            }
            
            this.user = l.data.result;
            if(this.user.dl_src != null){
                this.dl_src = this.user.dl_src;
            }
            if(this.user.icf_src != null){
                this.icf_src = this.user.icf_src;
            }
            if(this.user.icb_src != null){
                this.icb_src = this.user.icb_src;
            }
            if(this.user.vob_src != null){
                this.vob_src = this.user.vob_src;
            }
            this.editUser = JSON.parse(JSON.stringify(l.data.result));
            this.isLoaded = true;
            this.getAddress();
            this.closeEdits();
    }
    },
    watch:{
        menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
    computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
    ...mapGetters({
        user:'AUTH_USER',
        users:'AUTH_USERS'
    }),
    columns(){
        return [
            'name',
            'date',
        ]
    },
    documents(){
        return [
            {
                name:'Docx.docx',
                date:new Date(),
                size:1000023
            },
            {
                name:'xlsx.xlsx',
                date:new Date(),

            },
            {
                name:'jpg.jpg',
                date:new Date(),

            },
            {
                name:'pdf.pdf',
                date:new Date(),
            }
        ]
    },
    availableTags(){

        return [
                    {text:"VOB", value:"VOB"},
                    {text:"Possible Insurance", value:"Possible Insurance"},
                    {text:"Possible Private Pay", value:"Possible Private Pay"},
                    {text:"Medicaid/Medicare", value:"Medicaid/Medicare"},
                    {text:"Referred Out", value:"Referred Out"},
                    {text:"Solicitation", value:"Solicitation"},
                    {text:"Wrong Number", value:"Wrong Number"},
                    {text:"Adolescent", value:"Adolescent"},
                    {text:"Alumni", value:"Alumni"},
                    {text:"Call Dropped", value:"Call Dropped"},
                    {text:"Incoherent", value:"Incoherent"},
                    {text:"Incoherent", value:"Incoherent"},
                    {text:"Looking for Patient/Employee", value:"Looking for Patient/Employee"},
                    {text:"Loved One", value:"Loved One"},
                    {text:"Bad Insurance", value:"Bad Insurance"},
                    {text:"Other", value:"Other"},
                    {text:"Other Facility", value:"Other Facility"},
                    {text:"Test", value:"Test"}         

            ]
        },
    availableStatuses(){
            return [
            {text:'Recent', value:'recent'},
                {text:'VOB', value:'vob'},
                {text:'Qualified', value:'qualified'},
                {text:'Unqualified', value:'unqualified'},
                {text:'Lost', value:'lost'},
                {text:'Closed', value:'closed'},
                // {text:'Referred', value:'referred'},
                {text:'Admitted', value:'admitted'},
               // {text:'Archived', value:'archive'},
                {text:'Waitlist', value:'waitlist'},
                {text:'Discharged', value:'discharged'}
            ]
        },
    availableUsers(){
      return this.users.map((item)=>{
          return {text:`${item.first_name} ${item.last_name}`, value:item._id};
      })
    },
    filteredHistory(){
        return [];
    },
    filteredNotes(){
        return this.notes.reverse();
    }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss.scss";
.file-name-slot{
    display:flex; 
    align-items: center;
    gap:15px;
}
.document-table{
    text-align: left;
    margin-top:25px;
    .pdf{
        color:#ff0000;
    }
    .word{
        color:#435ca7;
    }
    .xlsx{
        color:#266e26;
    }
    .jpg{
        color:#856853;
    }
    .big{
        font-size:15pt;

    }
}
.show-add-note{
    
}
.add-note-icon{
    padding:10px;
    transition:all .3s ease;
    cursor: pointer;
    &:hover{
        transform:scale(1.3, 1.3);
    }
}
.note-container{
    display:flex;
    flex-direction: column;
    margin-top:25px;
    
    .note-item{
        padding:15px 8px;
        border:solid 1px #eaeaea;
        border-radius: 4px;
        margin-bottom:15px;
        text-align: left;
        .note-title{
            display:flex;
            margin-bottom:10px;
            font-size:10pt;
            text-transform: uppercase;
            letter-spacing: 1px;
            color:#808080;
            display: flex;
            align-items: center;
            .fa-trash{
                margin-left:10px;
                transition:all .3s ease;
                cursor: pointer;
                &:hover{
                    color:#ff0000;
                }
            }
        }
        .note-body{
            font-size: 12pt;
            white-space:pre-line;
            
        }
    }
}
.no-notes{
    background:#f1f1f1;
    height:200px;
    display:flex;
    align-items: center;
    width:100%;
    justify-content:center;
    font-size:15pt;
    color:#808080;
    margin-top:25px;
}
.non-blank-document{
    max-width:400px;
    input{
        opacity:0;
        height:1px;
        position:absolute;
        width:1px;
        pointer-events:none;
    }
    align-items: flex-start;
    display: flex;
    .vob-file{
        text-align: left;
        border:solid 1px #eaeaea;
        border-radius: 3px;
        padding:16px 8px;
        font-size:12pt;
        color:#000;
        width:100%;
        flex:1;
        
        transition:all .3s ease;
        display:flex;
        align-items:center;
        &:hover{
           
            
            
        }
        i{
            color:#ff0000;
            margin-right:10px;
            font-size:12pt;
        }
        .fa-arrow-down{
            padding:15px;
            border-radius: 4px;
            margin-left:auto;
            &:hover{
                background:#eaeaea;
                cursor: pointer;
            }
        }
    }
}
.blank-document{
    background:#f5f5f5;
    color:#aeaeae;
    display:flex;
    align-items: center;
    justify-content: center;
    position:relative;
    font-size:15pt;
    transition:all .3s ease;
    img{
        object-fit: cover;
        height:100%;
        width:100%;
    }
    input{
        opacity:0;
        height:1px;
        position:absolute;
        width:1px;
        pointer-events:none;
    }
    &:hover{
        background:#e7e7e7;
        cursor:pointer;
    }
    i{
        position:absolute;
        left:15px;
        top:15px;
        font-size:20pt;
        color:#aeaeae;
        background:#e9e9e9;
        padding:15px;
    }
    border:dashed 2px #aeaeaeae;
    height:250px;
}
.archive-change{
    margin-left:10px;
    
    font-size:8pt;
    padding:7px 15px; 
    background:#aeaeae;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
    color:white;
    cursor: pointer;
    transition:all .3s ease;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    @include media-query($small) {
        border-radius: 4px;
    }
    &:hover{
        background:#F5F5F5;
        color:var(--app-color);
        
    }
}
.save-change{
    margin-left:auto;
    
    font-size:8pt;
    padding:7px 15px; 
    background:var(--app-color);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
    color:white;
    cursor: pointer;
    transition:all .3s ease;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    @include media-query($small) {
        border-radius: 4px;
    }
    span{
        @include media-query($small) {
        display:none;
    }
    }
    &:hover{
        background:#F5F5F5;
        color:var(--app-color);
        
    }
}
.lead-list-item{
    display:flex;
    padding-top:10px;
    padding-bottom:10px;
    border-bottom:solid 1px #eaeaea;
    align-items: center;
    @include media-query($small) {
        flex-direction: column;
        align-items:flex-start;
    }
    .lead-list-item-title{
        text-transform: uppercase;
        font-size:8pt;
        text-align: left;
        letter-spacing: 1px;
        font-weight: 900;
    }
    .lead-list-item-value{
        min-width:60%;
        &.select{
            input{
                &:focus{
                    background:none !important;
                }
            }
            &:hover{
                input{
                   background:none !important; 
                
                }
                
            }
        }
        &:hover{
            
            input{
                background:#f5f5f5;
                @include media-query($small) {
                    background:transparent;
                }
            }
            
        }
        margin-left:auto;
        @include media-query($small) {
            margin-left:0;
            min-width:100%;
        }
        input{
            padding:10px;
            width:100%;
            font-size:12pt;
            &:focus{
                background:#f5f5f5;
                @include media-query($small) {
                    background:transparent;
                }
            }
            text-align: right;

            @include media-query($small) {
                text-align: left;
                font-size:10pt;
                padding:10px 0px;
            }
        }
    }

}
.tab-stage{
    background:#fff;
    box-shadow: 1px 17px 10px -6px rgba(0,0,0,0.1);
    padding:45px;
    @include media-query($small) {
            padding:15px;
            padding-top:65px;
            padding-bottom:150px;
        }
    h2{
        text-align: left;
        font-size:12pt;
        color:var(--app-color);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 900;
        margin-top:55px;
    }
    .gridly{
        display:grid;
        grid-gap:100px;
        grid-template-columns: 1fr 1fr 1fr;
        @include media-query($small) {
            grid-template-rows: 1fr ;
            grid-template-columns: 1fr;
            grid-gap:10px;
        }
    }
    
}
.top-tabs{
    display:flex;
    align-items: flex-end;
    @include media-query($small) {
        padding-top:25px;
    }
    .top-tab-item{
        user-select: none;
        border-top-right-radius: 5px;
        padding:7px 15px;
        border-top-left-radius: 3px;
        background:#f5f5f5;
        border:solid 1px #eaeaea;
        cursor: pointer;
        font-weight: bold;
        font-size:9pt;
        text-transform: uppercase;
        letter-spacing: 1px;
        position:relative;
        color:#aeaeae;
        &:hover{
            background: #f1f1f1;
        }
        &.active{
            color:var(--app-color);
            background:#fff;
            box-shadow: 0px -3px 14px -8px rgba(0, 0, 0, .4);
            &::before{
                
                transform:scaleX(1);
            }
        }
        &::before{
            content:'';
            transition:all .2s ease;
            position:absolute;
            bottom:0;
            left:0;
            height:2px;
            background:var(--app-color);
            width:100%;
            transform:scaleX(0);
            transform-origin: center center;
        }
    }
}
.rotateme{
    transform:rotate(180deg);
}
.text-line2 {
  -webkit-animation-duration: 2.25s;
  animation-duration: 2.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #dadada;
  background: linear-gradient(to right, #dadada 8%, #f0f0f0 18%, #dadada 33%);
  background-size: 800px 104px;
  height: 146px;
  position: relative;
}
.client-image{
    max-height:300px;
    height:300px;
    position:relative;
    img{
        height:100%;
    }
}
.image-input{
    display:none;
}
.actions{
    margin-top:15px;
    display:flex;
    justify-content: flex-end;
}
.lead-actions{
    margin-left:auto; 
    transform:translateY(10px);
    @include media-query($small) {
        position:absolute;
        top:100%;
        right:10px;
    }

}
.profile-pic{
    height:180px;
    width:180px;
    flex:1;
    
    margin-right:25px;
    margin-bottom:25px;
    transition:all .3s ease;
    
    
    img{
        width:100%;
        height:100%;
        object-fit: cover;
    }
    &:hover{
        box-shadow: $box-shadow;
        cursor: pointer;
    }

}
.c-edit{
    height:55px;
    margin-left:auto;
    display:flex;
    align-items: center;
    justify-content: flex-end;
}
.edit-c{
    display:grid;
    width:100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}
.address-c{
    justify-content: center;
    display:flex;
    flex:1; 
    span{
       
    }
    
}
.map{
  width: 100%;
  height:400px;
  flex:1;
   @include media-query($small) {
      width: 300px;
      height:300px;
    }
}
.c{
    padding:45px;
    background:#ecf0f2;
   
    padding-top:85px;
    padding-left:265px;
    min-height:calc(100vh);
    width:100%;
    position:relative;
    @include media-query($small) {
        padding:0;
        padding-top:70px;
    }
}
.c-card{
    background:#fff;
    box-shadow: $box-shadow;
    border-radius: 5px;
    margin-bottom:15px;
    padding:25px;
    
    
    .c-title{
        cursor:pointer;
        width:100%; 
        
        i{
            margin-right: 10px;
            transform-origin: center center;
            
            height:25px;
            width:25px;
            display:flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition:all .3s ease;
        }
        
        font-size:15pt;
        text-transform: uppercase;
        color:var(--app-color);
        letter-spacing: 2px;
        font-weight: bold;
        text-align: left;
    }
}
.c-inner{
    margin-top:25px;
    box-sizing: border-box;
    width:100%;
    display:grid;
    grid-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
    font-size:12pt;
    &::after{
        content:'';
        height:25px;
        width:100%;
    }
    .c-item{
        transition:all .3s ease;
        &:hover{
            box-shadow: $box-shadow;
        cursor: pointer;
            .c-label{
                color:var(--app-color)
            }
            .c-value{
                background:var(--app-color);
                color:#fff;
            }
        
    }
        display:flex;
        align-items: center;
        padding:15px;
        border:solid 1px #eaeaea;
        flex-wrap: wrap;
        .c-value{
            transition:all .3s ease;
            
            margin-left:auto;
            font-size:12pt;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            padding:15px;
            background:#eef0f1;
            border-radius: 5px;
            white-space: nowrap;
        }
        .c-label{
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size:12pt;
            white-space: nowrap;
            color:$blue;
            transition:all .3s ease;
        }
    }
}
</style>