<template>
    <div class="login-container">
        <img class="bigo" src="@/assets/o.svg">
        <div class="login-box animated-med fadeInUp">
            <div class="login-logo">
                <img src="@/assets/blue_logo_small.png" alt="">
            </div>
            <div class="login-inputs">
                <v-text-field outlined :color="appColor" label="Email" v-model="email" ></v-text-field>
                <v-text-field outlined :color="appColor" type="password" label="Password" v-model="password" ></v-text-field>
                
                <v-btn :loading="isLoading" large block :color="this.$vuetify.theme.themes.dark.primary" @click="submitLogin" dark depressed>Login</v-btn>
                <div class="reset-password" @click="$router.push('/forgot')">Forgot Password</div>
                <div class="error-message animated-med fadeInUp" v-if="errorMessage != ''">{{errorMessage}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import auth from '../auth';
export default {
    name:'Login',
    data(){
        return {
            email:'',
            password:'',
            isLoading:false,
            errorMessage:'',
        }
    },
    methods:{
        submitLogin(){
            this.errorMessage = '';
            this.isLoading = true;
            auth.login({ email: this.email.toLowerCase(), password: this.password }).then(res => {
                    this.$router.push('/splash');
                }, err => {
                    this.isLoading = false
                    this.errorMessage = err.response.data.error;
                })
        }
    }
}
</script>
<style lang="scss"  >
@import "@/assets/scss.scss";
.reset-password{
    padding:15px;
    opacity: .5;
    transition:all .3s ease;
    cursor: pointer;
    color:#808080;
    &:hover{
        opacity: 1;
    }
}
.error-message{
    font-size:12pt;
    padding:15px;
    color:#ff0000;
    text-align: center;
    width:100%;
}
.login-inputs{
    padding:25px;
}
.login-logo{
    margin-bottom:15px;
    padding:35px;
    img{
        width:200px;
    }
}
.login-container{
    background-image:url('https://theohanahawaii.com/_nuxt/img/beach2.ac105d4.webp');
    background-size:cover;
    background-position:center;
    display:flex;
    align-items: center;
    justify-content:center;
    width:100%;
    height:100vh ;
}
.login-box{
    background:#fff;
    

    width:350px;
    border-radius: 10px;
    box-shadow: 0px 20px 20px rgba(0,0,0,0.4);
}
.bigo{
    position:absolute;
    top:-10%;
    right:-15%;
    z-index: 0;
    width:50vw;
    img{
        width:50%;
    }
}
</style>