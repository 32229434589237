<template>
    <v-card>
          <v-card-title>
              {{addUser ? 'Add' : 'Manage'}} Users
          </v-card-title>
          <v-card-text>
            <div v-if="editUser != null "> 
              <v-select small dense hide-details :color="appColor" :items="['admin','basic', 'manager']" label="User Role" outlined v-model="editUser.role"></v-select>
              <v-select small dense hide-details :color="appColor" :items="[{text:'RCT', value:'rct'},{text:'Food Service', value:'food service'}, {text:'Medical', value:'medical'},{text:'Clinical', value:'clinical'}, {text:'Admissions', value:'admissions'}, {text:'All', value:'all'}]" label="Department" outlined v-model="editUser.department"></v-select>
              <div style="display:flex;">
                <v-text-field small dense hide-details :color="appColor" label="First Name" outlined v-model="editUser.first_name"></v-text-field>
                <v-text-field small dense hide-details :color="appColor" style="margin-left:10px" label="Last Name" outlined v-model="editUser.last_name"></v-text-field>
              </div>
              <v-text-field small dense hide-details :color="appColor" label="Email" outlined v-model="editUser.email"></v-text-field>
              <!-- <v-text-field v-if="addUser" small dense hide-details :color="appColor" label="Password" type="password" outlined v-model="editUser.password"></v-text-field> -->
              <div style="font-weight: bold; margin-top:35px; text-align:left; font-size:1.2em; color:#000;">Permissions</div>
              <div style="display:flex;flex-wrap:wrap;">
                <v-checkbox hide-details :color="appColor" class="role-check-box" v-for="(item,i) in roleOptions" :key="i" v-model="editUser.permissions" :value="item.value" :label="item.text"></v-checkbox>
              </div>
              <!-- <div style="display:flex; align-items:center;">
                <div style="margin-left:auto; display:flex;">
              <v-btn :color="appColor" @click="submitAddUser" :loading="addUserLoading" dark depressed small>Add User</v-btn><v-btn @click="editUser = null" style="margin-left:10px;" dark depressed small>Cancel</v-btn>
                  
            </div>
            <div v-if="errorMessage != ''" class="error-message">{{ errorMessage }}</div>
              </div> -->
            </div>
          </v-card-text>
          <v-card-text>
            <div style="text-align: left; color:#ff0000" v-if="errorMessage">{{ errorMessage }}</div>
          </v-card-text>
          <v-card-actions>
              <v-btn small @click="close()" text depressed>Cancel</v-btn>
              <v-btn v-if="!addUser" small dark :color="appColor" depressed @click="updateUser(editUser)" :loading="savingUser" >Save</v-btn>
              <v-btn v-if="addUser" small dark :color="appColor" depressed @click="submitAddUser(editUser)" :loading="savingUser" >Save</v-btn>
              <v-btn v-if="!addUser" small style="margin-left:auto;" @click="editUser.archive = true, updateUser(editUser)" text depressed>Archive</v-btn>
          </v-card-actions>
      </v-card>
</template>
<script>
import axios from 'axios';
export default {
    name:'manageUser',
    props:['addUser', 'editUser'],
    data(){
        return {
            editUser:{},
            errorMessage:'',
            savingUser:false,
            addUserLoading:false
        }
    },
    mounted(){
        this.editUser = JSON.parse(JSON.stringify(this.editUser));
    },
    
    methods:{
        close(){
            this.$emit('close');
        },
        async updateUser(user){
      this.savingUser = true;
      this.errorMessage = '';
      this.addUserLoading = true;
      let post = this.editUser;
      delete post.password;
      try{
        let {data} = await axios.put(`/api/users/edit/${user._id}`, post);
        this.addUserLoading = false;
        this.editUser = null;
        this.savingUser = false;
        this.$emit('close');
        this.manageUserOpen = false;
      }catch(e){
        this.addUserLoading = false;
        if(e.response){
          this.errorMessage = e.response.data.message;
        }
        
        this.savingUser = false;
      }
    },
        async submitAddUser(){
            this.errorMessage = '';
            this.addUserLoading = true;
            let post = this.editUser;
            try{
                let {data} = await axios.post('/api/users/add', post);
                this.addUserLoading = false;
                this.editUser = null;
                this.close();
            }catch(e){
                this.addUserLoading = false;
                if(e.response){
                    this.errorMessage = e.response.data.message;
                }
                
            }
        },
    },
    computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        roleOptions(){
            return [
                {text:'Dashboard', value:'dashboard'},
                {text:'Leads', value:'leads'},
                {text:'Reminders', value:'reminders'},
                {text:'Users', value:'users'},
                {text:'Forms', value:'forms'},
                {text:'Inventory', value:'inventory'},
                {text:'Bed Chart', value:'bed_chart'}
            ]
        }
    },
    watch:{
        editUser:{
            deep:true,
            handler(v){
                this.editUser = v;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss.scss";

.c{
    background:transparent;
    @include media-query(small) {
        
    }
}
</style>