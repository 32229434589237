<template>
    <div class="inner-stage-room" id="room">
      <v-dialog v-model="leadOpen"  width="1200px">
            <EntryPopup @close="closeLead" :entry="activePat" />
      </v-dialog>
      <div class="ff-loading" v-if="loadingPatients"> <v-progress-circular :size="70" :width="2" indeterminate color="#000"></v-progress-circular></div>
      <v-dialog v-model="addPatient" width="400">
        <v-card >
          <div style="font-size:9pt; padding:10px; background:#f8fafb; border-bottom:solid 1px #eaeaea;  text-align: left;">Add Patient</div>
          <div style="padding:15px;">
            
            <v-text-field :color="appColor" outlined  dense label="Patient Name" v-model="editPatient.name"></v-text-field>
            <v-radio-group label="Gender"  v-model="editPatient.gender">
              <div style="display:flex; align-items: start;">
              <v-radio value="m" :color="appColor" label="Male" :key="0"></v-radio>
              <v-radio value="f" :color="appColor" label="Female" :key="1"></v-radio>
            </div>
            </v-radio-group>
            <div style="display:flex; gap:10px;">
            <v-menu
                v-model="editPatient.startmenu"
                :close-on-content-click="false"
                :readonly="editing"
                max-width="290"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    :color="appColor"
                    v-model="editPatient.admitDateFormatted"
                    style="padding-top:0 !important; margin-bottom:15px; max-width:250px;"
                    label="Admit Date"
                    @blur="editPatient.admitDate = parseDate(editPatient.admitDateFormatted), editPatient.admitDateFormatted = formatDate(editPatient.admitDate), remindMe = 'custom'"
                    class="fixMe"
                    outlined
                    hide-details
                    small
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="editPatient.admitDate = null"
                    ></v-text-field>
                </template>
                <v-date-picker
                    :color="appColor"
                    :readonly="editing"
                    
                    v-model="editPatient.admitDate"
                    @change="($event)=>{editPatient.admitDate = $event; editPatient.admitDateFormatted = formatDate($event), editPatient.startmenu = false}"
                    
                ></v-date-picker>
                </v-menu>  
                <v-menu
                v-model="editPatient.endmenu"
                :close-on-content-click="false"
                :readonly="editing"
                max-width="290"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    :color="appColor"
                    v-model="editPatient.dischargeDateFormatted"
                    style="padding-top:0 !important; max-width:250px;"
                    label="Exit Date"
                    @blur="editPatient.dischargeDate = parseDate(editPatient.dischargeDateFormatted), editPatient.dischargeDateFormatted = formatDate(editPatient.dischargeDate), remindMe = 'custom'"
                    class="fixMe"
                    outlined
                    hide-details
                    small
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="editPatient.dischargeDate = null"
                    ></v-text-field>
                </template>
                <v-date-picker
                    :color="appColor"
                    :readonly="editing"
                    v-model="editPatient.dischargeDate"
                    @change="($event)=>{editPatient.dischargeDate = $event; editPatient.dischargeDateFormatted = formatDate($event), editPatient.endmenu = false}"
                    
                ></v-date-picker>
                </v-menu> 
              </div> 
          </div>
          <div class="flex padding">
            <div style="margin-left:auto">
                <v-btn @click="addPatient = false" depressed >Close</v-btn>
                <v-btn style="margin-left:15px" :color="appColor" dark @click="submitAddPatient" depressed >Add</v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <div class="room-inner">
        <div class="room-inner-2">
        <h3 style="display:flex; align-items:center; gap:15px;">Bed Chart <v-btn @click="addPatient = true" depressed :color="appColor" dark fab x-small> <i class="fal fa-plus"></i></v-btn></h3>
        <div class="fac-container" v-for="(fac,i) in facilities" :key="`${i}f`">
          <div class="facility-title">Residential</div>
          <div class="room-container">
            <div class="room-item" v-for="(room,b) in fac.rooms" :key="`${b}r`">
              <div class="room-name"><i class="fad fa-room-alt"></i> {{room.name[0].toUpperCase() +  room.name.substr(1, room.name.length).toLowerCase() }}</div>
              <div v-show="false" @click.prevent="activePat = pat, leadOpen = true" class="patient filled" @mousedown="startDrag($event, `${fac.name}-${room.id}`, pat)"  :id="`${fac.name}-${room.id}`" :class="[pat.gender, {'future':new Date(pat.admitDate) > new Date()}]" v-for="(pat,p) in patients.filter((item)=>item.facility == fac.name && item.roomId == room.id)" :key="p">
                
                <i v-if="new Date(pat.admitDate) > new Date()" class="fad fa-hourglass"></i>&nbsp;{{pat.roomId}} {{ pat.name }} <span class="pat-insurance" :class="pat.insurance_company && pat.insurance_company.toLowerCase()">{{ pat.insurance_company != null ? pat.insurance_company : 'None' }}</span><br>
                <div style="margin-left:auto; display:flex; gap:5px;">
                <div v-if="pat.admitDate != null && pat.dischargeDate != null && pat.dischargeDate != '' && pat.admitDate != ''" class="pat-date-range">
                  <div>{{ moment(pat.admitDate).utc().format('MM/DD') }}</div>
                  &nbsp;-&nbsp;
                  <div>{{ moment(pat.dischargeDate).utc().format('MM/DD') }}</div>
                  
                </div>
                <!-- <div class="delete-pat" @mousedown.stop="deletePatient(pat._id)"><i class="fal fa-trash"></i></div> -->
                </div>
              </div>
              <div class="room-slot " :id="`${fac.name}-${room.id}-${c+1}`" v-for="(bslot, c) in Number(room.capacity) " :key="`${c}c`">
                <div @click.prevent="activePat = pat, leadOpen = true" class="patient filled" @mousedown="startDrag($event, `${fac.name}-${room.id}-${c+1}`, pat)"  :id="`${fac.name}-${room.id}-${c+1}`" :class="[pat.gender, {'future':new Date(pat.admitDate) > new Date()}]" v-for="(pat,p) in patients.filter((item)=>item.facility == fac.name && item.roomId == room.id && item.bedId == (Number(c)+1).toString())" :key="p">
                
                  <i v-if="new Date(pat.admitDate) > new Date()" class="fad fa-hourglass"></i>&nbsp;{{pat.roomId}} {{ pat.name }} <span class="pat-insurance" :class="pat.insurance_company && pat.insurance_company.toLowerCase()">{{ pat.insurance_company != null ? pat.insurance_company : 'None' }}</span><br>
                  <div style="margin-left:auto; display:flex; gap:5px;">
                  <div v-if="pat.admitDate != null && pat.dischargeDate != null && pat.dischargeDate != '' && pat.admitDate != ''" class="pat-date-range">
                    <div>{{ moment(pat.admitDate).utc().format('MM/DD') }}</div>
                    &nbsp;-&nbsp;
                    <div>{{ moment(pat.dischargeDate).utc().format('MM/DD') }}</div>
                    
                  </div>
                  <!-- <div class="delete-pat" @mousedown.stop="deletePatient(pat._id)"><i class="fal fa-trash"></i></div> -->
                  </div>
                </div>

                  <!-- <div v-for="(pat, p) in patients.filter((item)=>item.facility == fac.name && item.roomId == room.id && item.bedId == (Number(c)+1).toString())" :key="p">
                    <i v-if="new Date(pat.admitDate) > new Date()" class="fad fa-hourglass"></i>&nbsp;{{pat.roomId}} {{ pat.name }}<br>
                    <div style="margin-left:auto; display:flex; gap:5px;">
                    <div v-if="pat.admitDate != null && pat.dischargeDate != null && pat.dischargeDate != '' && pat.admitDate != ''" class="pat-date-range">
                      <div>{{ moment(pat.admitDate).format('MM/DD') }}</div>
                      &nbsp;-&nbsp;
                      <div>{{ moment(pat.dischargeDate).format('MM/DD') }}</div>
                      
                    </div>
                    <div class="delete-pat" @mousedown.stop="deletePatient(pat._id)"><i class="fal fa-trash"></i></div> 
                    </div>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
        <div :class="{'hideme':this.isDragging}" ref="patientBucket" class="fac-container patient-bucket-container">
          <div class="facility-title">Patients</div>
          <div class="patient-bucket">
          <div @click.prevent="activePat = pat, leadOpen = true" @mousedown="startDrag($event, 'patients', pat)" :id="`patients-${i}`" :class="[pat.gender, {'future':new Date(pat.admitDate) > new Date()}]" v-for="(pat,i) in patients.filter((item)=>{return (item.roomId == -1 && item.status != 'waitlist' || item.roomId == null && item.status != 'waitlist')})" :key="i" class="patient ">
            <i v-if="new Date(pat.admitDate) > new Date()" class="fad fa-hourglass"></i>&nbsp;  {{ pat.name }} <span class="pat-insurance" :class="pat.insurance_company && pat.insurance_company.toLowerCase()">{{ pat.insurance_company != null ? pat.insurance_company : 'None' }}</span><br>
            <div style="margin-left:auto; display:flex; gap:5px;">
                <div v-if="pat.admitDate != null && pat.dischargeDate != null && pat.dischargeDate != '' && pat.admitDate != ''" class="pat-date-range">
                  <div>{{ moment(pat.admitDate).utc().format('MM/DD') }}</div>
                  &nbsp;-&nbsp;
                  <div>{{ moment(pat.dischargeDate).utc().format('MM/DD') }}</div>
                  
                </div>
                <!-- <div class="delete-pat" @mousedown.stop="deletePatient(pat._id)"><i class="fal fa-trash"></i></div> -->
                </div>
          </div>
        </div>
        </div>
        <div :class="{'hideme':this.isDragging}" ref="patientBucket" class="fac-container patient-bucket-container">
          <div class="facility-title">Waitlisted Patients</div>
          <div class="patient-bucket">
          <div @click.prevent="activePat = pat, leadOpen = true" @mousedown="startDrag($event, 'patients', pat)" :id="`patients-${i}`" :class="[pat.gender, {'future':new Date(pat.admitDate) > new Date()}]" v-for="(pat,i) in patients.filter((item)=>{return (item.roomId == -1 && item.status == 'waitlist' || item.roomId == null && item.status == 'waitlist')})" :key="i" class="patient ">
            <i v-if="new Date(pat.admitDate) > new Date()" class="fad fa-hourglass"></i>&nbsp;  {{ pat.name }} <span class="pat-insurance" :class="pat.insurance_company && pat.insurance_company.toLowerCase()">{{ pat.insurance_company != null ? pat.insurance_company : 'None' }}</span><br>
            <div style="margin-left:auto; display:flex; gap:5px;">
                <div v-if="pat.proposedAdmitDate != null && pat.proposedAdmitDate != ''" class="pat-date-range">
                  <div>{{ moment(pat.proposedAdmitDate).utc().format('MM/DD') }}</div>
                  
                </div>
                <!-- <div class="delete-pat" @mousedown.stop="deletePatient(pat._id)"><i class="fal fa-trash"></i></div> -->
                </div>
          </div>
        </div>
        </div>
      </div>
      
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import { mapGetters } from 'vuex';
import EntryPopup from '../components/entryPopup.vue';
export default {
  components:{
    EntryPopup
  },
    name:'room chart',
    data(){
        return {
          loadingPatients:false,
          moment,
          addPatient:false,
          editing:false,
          defaultPatient:{
            name:'',
            status:'',
            admitDate:'',
            admitDateFormatted:'',
            dischargeDateFormatted:'',
            startmenu:false,
            endmenu:false,
            dischargeDate:'',
            facility:'',
            roomId:-1,
            gender:'m'
          },
          editPatient:{
            name:'',
            status:'',
            admitDate:'',
            admitDateFormatted:'',
            dischargeDateFormatted:'',
            startmenu:false,
            endmenu:false,
            dischargeDate:'',
            facility:'',
            roomId:-1,
            gender:'m'
          },
            
            patients:[],
            waitlist:[],
            clone:null,
            hoverBucket:null,
            hoverIdx:0,
            startIdx:0,
            startBucket:null,
            timeout:null,
            activePat:null,
            isDragging:false,
            activeLead:null,
            leadNotes:[],
            leadOpen : false
        }
    },
    mounted(){
       this.getPatients();
       window.addEventListener('mouseup', ()=>{
        clearTimeout(this.timeout);
       })
    },
    methods:{
      closeLead(){
            this.activeLead = {};
            this.leadNotes = [];
            this.leadOpen = false;
            this.getPatients();
        },
      async submitAddPatient(){
        try{
          let post = this.editPatient;
          let {data} = await axios.post('/api/bedchart/add_patient', post);
          this.addPatient = false;
          this.editPatient = this.defaultPatient;
          this.getPatients();
        }catch(e){
          console.log(e);
        }
      },
      async deletePatient(id){
        try{
          this.loadingPatients = true;
           let {data} = await axios.delete(`/api/bedchart/delete_patient/${id}`);
           this.getPatients();
        }catch(e){
          this.loadingPatients = false;
        }
      },
      handleMouseMove(event) {
        var eventDoc, doc, body;

        event = event || window.event; // IE-ism

        // If pageX/Y aren't available and clientX/Y are,
        // calculate pageX/Y - logic taken from jQuery.
        // (This is to support old IE)
        if (event.pageX == null && event.clientX != null) {
            eventDoc = (event.target && event.target.ownerDocument) || document;
            doc = eventDoc.documentElement;
            body = eventDoc.body;

            event.pageX = event.clientX +
              (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
              (doc && doc.clientLeft || body && body.clientLeft || 0);
            event.pageY = event.clientY +
              (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
              (doc && doc.clientTop  || body && body.clientTop  || 0 );
        }
        
        
        this.clone.style.top = event.pageY - window.scrollY - (this.dh * .5) + 'px';
        this.clone.style.left = event.pageX - 15 + 'px';
        let els = document.elementsFromPoint(event.pageX, (event.pageY - window.scrollY));
        for(let i in els){
            if(els[i].className.indexOf('patient ') > -1){
                this.hoverIdx = els[i].id.split('-')[1];
                this.hoverBucket = els[i].id.split('-')[0];
                
                    if(this.startBucket != this.hoverBucket){
                        // this[this.startBucket].splice(this[this.startBucket].findIndex((item)=>item._id == this.activePat._id), 1);
                        this.startBucket = this.hoverBucket;
                    }
                  //  this[this.hoverBucket].splice(this[this.hoverBucket].findIndex((item)=>item._id == this.activePat._id), 1);
                  //  this[this.hoverBucket].splice(this.hoverIdx, 0, this.activePat); 
                
                
                //console.log(this[this.startBucket].findIndex((item)=>item._id == this.activePat._id));
            }
            if(els[i].className.indexOf('room-slot ') > -1){
              console.log(els[i].id);
                    this.hoverBucket = els[i].id
                    if(this[this.hoverBucket] == null) return;
                  //  this[this.hoverBucket].splice(this.hoverIdx, 0, this.activePat); 
                    if(this.startBucket != this.hoverBucket){
                   //     this[this.startBucket].splice(this[this.startBucket].findIndex((item)=>item._id == this.activePat._id), 1);
                        this.startBucket = this.hoverBucket;
                    }
            }
            if(els[i].className.indexOf('patient-bucket') > -1){
              
                    this.hoverBucket = 'patients'
                    if(this[this.hoverBucket] == null) return;
                  //  this[this.hoverBucket].splice(this.hoverIdx, 0, this.activePat); 
                    if(this.startBucket != this.hoverBucket){
                   //     this[this.startBucket].splice(this[this.startBucket].findIndex((item)=>item._id == this.activePat._id), 1);
                        this.startBucket = this.hoverBucket;
                    }
            }
        }
         return {x:event.pageX,y:event.pageY}
    },
      startDrag(e, start, pat){
            this.activePat = pat;
            e.preventDefault();
            this.startBucket = start;
            //this.startIdx = e.target.id
            
            this.timeout = setTimeout(()=>{
                let el = e.target;
                function getParent(node){
                    node = node.parentNode;
                    if(node.className.indexOf('patient ') == -1){
                        node = getParent(node);
                    }
                        return node;
                }
                if(el.className.indexOf('patient ') > -1){

                }else{
                    el = getParent(el);
                }
                
                let bb = el.getBoundingClientRect();
                this.dw = bb.width;
                this.dh  = bb.height;
                this.clone = el.cloneNode(true);
                this.clone.style.width = this.dw + 'px';
                let body = window.document.getElementById('app');
                body.appendChild(this.clone);
                this.clone.classList.add('dragging');
                this.isDragging = true;
                this.handleMouseMove(e);
                window.addEventListener('mousemove', this.handleMouseMove);
                window.addEventListener('mouseup', this.endDrag)
            },500)
        },
        async endDrag(){
            if(this.isDragging){
            
              
              this.loadingPatients = true;
                this.isDragging = false;
                window.removeEventListener('mouseup', this.endDrag)
                window.removeEventListener('mousemove', this.handleMouseMove);
                let body = window.document.getElementById('app');
                let lead = JSON.parse(JSON.stringify(this.activePat));
                
                if(this.hoverBucket == 'patients'){
                  body.removeChild(this.clone);
                  lead.facility = '';
                  lead.roomId = '-1';
                  if(this.patients[this.patients.findIndex((item)=>item._id == lead._id)] != null){
                    this.patients[this.patients.findIndex((item)=>item._id == lead._id)].facility = '';
                    this.patients[this.patients.findIndex((item)=>item._id == lead._id)].roomId = '-1';
                    this.patients[this.patients.findIndex((item)=>item._id == lead._id)].bedId = '-1';
                  }else{
                    
                  }
                  delete lead._id;
                  await axios.put('/api/bedchart/edit/' + this.activePat._id, lead)
                  this.loadingPatients = false;
                  return;
                }
                let facility = this.hoverBucket.split('-')[0];
                let roomId = this.hoverBucket.split('-')[1]
                let bedId = this.hoverBucket.split('-')[2]

                if(facility == null || roomId == null || bedId == null){
                  body.removeChild(this.clone);
                  this.loadingPatients = false;
                  return;
                }
                if(facility == this.activePat.facility && roomId == this.activePat.roomId && bedId == this.activePat.bedId){
                  console.log("same to same")
                  body.removeChild(this.clone);
                  this.loadingPatients = false;
                } else{
                  
                lead.facility = facility;
                lead.roomId = roomId;
                lead.bedId = bedId;
                  this.patients[this.patients.findIndex((item)=>item._id == lead._id)].facility = facility;
                  this.patients[this.patients.findIndex((item)=>item._id == lead._id)].roomId = roomId;
                  this.patients[this.patients.findIndex((item)=>item._id == lead._id)].bedId = bedId;
                
                delete lead._id;
                  //  this[this.startBucket].splice(this[this.startBucket].findIndex((item)=>item._id == this.activePat._id), 1);
                
                
                 body.removeChild(this.clone);
                 console.log(lead);
                 await axios.put('/api/bedchart/edit/' + this.activePat._id, lead)
                 this.loadingPatients = false;
                // this.getPatients();
                }
                
                
            }else{
                clearTimeout(this.timeout);
                this.isDragging = false;
            }
        },
      formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        async getPatients(){
          try{
            this.loadingPatients = true;
            let {data} = await axios.get('/api/bedchart/get_patients')
            // let {data:dataW} = await axios.get('/api/bedchart/get_waitlisted')

            // for(let f = 0; f < this.facilities.length;f++){
            //   for(let b = 0; b < this.facilities[f].rooms.length; b++){
            //     if
            //   }
            // }
            // this.wailtlist = dataW.items;
            this.patients = data.items;
            this.loadingPatients = false;

          }catch(e){
            this.loadingPatients = false;
          }
        }
    },  
    computed:{
      ...mapGetters({
        facility:"FACILITY"
      }),
      facilities(){
        if(this.facility == 'ohana'){
          return [
              {
                name:'residential',
                rooms:[
                  {
                    id:1,
                    name:'MASTER-HUALALAI',
                    capacity:2
                  },
                  
                  {
                    id:3,
                    name:'MAKAI-HUALALAI',
                    capacity:2
                  },
                  {
                    id:4,
                    name:'KALOKO-1',
                    capacity:2
                  },
                  {
                    id:5,
                    name:'KALOKO-2',
                    capacity:2
                  },
                  
                ]
              }
            ]
        }else{
          return [
              {
                name:'residential',
                rooms:[
                  {
                    id:10,
                    name:'Oasis 1',
                    capacity:2
                  },
                  
                  {
                    id:30,
                    name:'Oasis 2',
                    capacity:2
                  },
                  {
                    id:40,
                    name:'Oasis 3',
                    capacity:2
                  },
                  {
                    id:50,
                    name:'Oasis 4',
                    capacity:2
                  },
                  
                ]
              }
            ]
        }
      },
      
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        
    },
    watch:{
      leadOpen(v){
        if(!v){
          this.closeLead()
        }
      }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss.scss";
.pat-insurance{
  padding:3px 5px; border-radius: 100px;
  margin-left:10px;
  color:#fff;
  border:solid 1px #fff;
}
.inner-stage-room{
  padding: 0px;
    padding-top: 85px;
    padding-left: 250px;
    height:calc(100vh - 100px);
    padding-bottom: 100px;

    @include media-query($medium-down){
      padding-left:0px;
    }
}
.room-inner-2{

  width:100%;
  height:auto;
  z-index: 99;
  position: relative;

  top:0;
}
.room-inner{
  width:100%;
  padding:25px;
  top:0;
  position: relative;
 min-height: calc(100vh - 100px);
}
.fac-container{
  text-align: left;
  margin-bottom:50px;
  width:100%;
  .facility-title{
    font-size:10pt;


    padding:5px;

    color:#000;
  }
  .room-container{
    display:flex;
    width:100%;
    gap:10px;
    max-width:800px;
    flex-wrap:wrap;
    .room-item{
      flex:1;
      flex-basis: 100%;
      @include media-query($small){
        flex-basis: 100%;
      }
      background:#fff;
      border-radius: 10px;
      box-shadow: 0px 7px 20px -8px rgba(0,0,0,0.15);
      padding:15px;
      
      .room-name{
        font-size:10pt;
        font-weight: bold;
      }
      .patient{
        display:flex;
        align-items: center;
        cursor: pointer;
        &.dragging{
            position:fixed !important;
            z-index:1000 !important;
            cursor:grabbing !important;
            i{
                cursor:grabbing;
            }
        }
        &.future{
            color: #000 !important;
            background:#ffdd0080 !important;
            &.m{
              border:dashed 2px #3080e8
            }
            &.f{
              border:dashed 2px #e74faa
            }
            opacity: .6;
        }
        &.filled{
          
          border:none;
          &.m{
            border:solid 2px #3080e8;
            background:#3080e8;
            color:#fff;
          }
          &.f{
            border:solid 2px #e74faa;
            background:#e74faa;
            color:#fff;
          }
        }
        border:dashed 1px #aeaeae;
        background:#f8fafb;
        padding:10px;
        border-radius: 4px;
        width:100%;
        min-height:50px;
      }
    }
  }
}
.room-slot{
  min-height:50px;
        display:flex;
        align-items: center;
        gap:5px;
        cursor: pointer;
        
        transition:all .3s ease;
        &:hover{
          background:#00000030;
        }
        &.dragging{
            position:fixed !important;
            z-index:1000 !important;
            cursor:grabbing !important;
            i{
                cursor:grabbing;
            }
        }
        &.filled{
          border:none;
          &.m{
            background:#3080e8;
            color:#fff;
          }
          &.f{
            background:#e74faa;
            color:#fff;
          }
        }
        border:dashed 1px #aeaeae;
        background:#f8fafb;
        
        border-radius: 4px;
        margin-top:10px;

      }
.patient{
        display:flex;
        align-items: center;
        padding:15px 15px;
        cursor: pointer;
        &.dragging{
          pointer-events: none;
            position:fixed !important;
            z-index:1000 !important;
            cursor:grabbing !important;
            i{
                cursor:grabbing;
            }
        }
        &.filled{
          border:none;
          &.m{
            background:#3080e8;
            color:#fff;
          }
          &.f{
            background:#e74faa;
            color:#fff;
          }
          @include media-query($small){
            flex-wrap: wrap;
          }
        }
        border:dashed 1px #aeaeae;
        &.m{
            border:solid 2px #3080e8;
        }
        &.f{
          border:solid 2px #e74faa;
        }
        background:#f8fafb;
        padding:10px;
        border-radius: 4px;
        

      }
.pat-date-range{
  display:flex;
  background:#00000030;
  border-radius: 3px;
  transition:all .3s ease;
  cursor: pointer;
  &:hover{
    background: #00000040;
  }
  padding:2px 10px;
  font-family: monospace;
  margin-top:5px;
  margin-left:auto;
}
.patient-bucket{
  display:flex;
  flex-wrap: wrap;
  
  gap:10px;
  @include media-query($small) {
    
  }
  background:#f8fafb;
  border:dashed 1px #aeaeae;
  border-radius: 3px;
  padding:15px;
  transition:all .3s ease;
  .patient{
    white-space: nowrap;
    flex-basis:19%;
    display:flex; gap:15px;
    @include media-query($small) {
      flex-basis:100% !important;
    }
  }
  &:hover{
    background:#00000010;
  }
}
.ff-loading{
  width:100%;
  height:100vh;
  z-index: 10000;
  top:0;
  left:0;
  position: fixed;
  background: #00000060;
  display:flex;
  align-items: center;
  justify-content: center;
}
.delete-pat{
  display:flex;
  background:#00000030;
  border-radius: 3px;
  transition:all .3s ease;
  &:hover{
    background: #00000040;
  }
  padding:2px 10px;
  font-family: monospace;
  margin-top:5px;
  margin-left:auto;
  align-items: center;
  font-size:8pt;
}
.patient-bucket-container{

  height:100%;
  z-index: 10;
  overflow: auto;
  width:100%;
  &:hover{
    z-index: 100;
  }
  &.hideme{
    z-index: 10;
  }
}
</style>