<template>
  <div class="inner-stage" style="text-align:left;">
    
    <div v-if="isLoaded" class="forms-container">
      <div style="margin-bottom:15px;">
        <div class="f-name"><i class="fad fa-file"></i>{{inventory ? 'Inventory': 'Forms'}} List </div><br>
        <v-btn :color="appColor" small dark depressed @click="$router.push('/forms/create')">Create New Form</v-btn>
        <!-- <v-select v-model="fac" outlined small compact dense :items="[{text:'Residential', value:'residential'},{text:'TLC', value:'tlc'}]" :color="appColor" style="max-width:250px" label="Facility"></v-select> -->
      </div>
      
      <v-client-table
        :perPage="50"
        :data="forms"
        :columns="columns"
        :options="{perPage:50}"
        >
        <span slot="h__name" >
            {{inventory ? 'Inventory' : 'Form'}} Name
        </span>
        <div slot="department" slot-scope="{ row }">
          <div v-if="typeof row.department == 'object'">{{ row.department.join(', ') }}</div>
          <div v-else>{{ row.department}}</div>
        </div>
        <div slot="name" style="text-align:left;" slot-scope="{ row }">
            <a @click="row.type != 'inventory' ? $router.push(`/forms/${row._id}`) : $router.push(`/inventory/${row._id}`)" style="white-space: nowrap;"><i style="color:var(--app-color)" class="fad fa-file"></i>&nbsp;&nbsp;{{row.name}}</a>
        </div>
        
        </v-client-table>

      <!-- <div @click="$router.push(`/forms/${form._id}`)" class="form-item" v-for="(form, i) in forms" :key="i">
        <i style="color:var(--app-color);margin-right:10px; font-size:18pt;" class="fad fa-file"></i>
        <div class="form-name">{{ form.name }}</div>
      </div> -->

     

    </div>
    
    
    
    
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Users",
  data() {
    return {
      moment,
      isLoaded: false,
      forms:[],
      editUser:null,
      addUserLoading:false,
      errorMessage:'',
    };
  },
  mounted() {
    
    this.getForms();

    
  },
  
  methods: {
    ...mapActions({
      facility_set:'facility_set'
    }),
    async getForms() {
      let {data} = await axios.get(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_forms`);
      this.forms = data.items.filter((item)=>{
        if(item.department == 'all' && !this.$route.path.includes('inventory')){
          return true;
        }
        return this.inventory ? 
        item.type == 'inventory' && (this.authUser.department != 'all' ? item.department.includes(this.authUser.department) : true) 
      : item.type != 'inventory' && (this.authUser.department != 'all' ? item.department.includes(this.authUser.department)  : true)
      });
      this.isLoaded = true;
   
    },
  },
  computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
    ...mapGetters({
      authUser:'AUTH_USER',
      facility:'FACILITY'
    }),
    inventory(){
      return this.$route.path == '/inventory'
    },
    fac:{
      get(){
        return this.facility || '';
      },
      set(v){
        console.log(v);
        this.facility_set(v);
      }
    },
    page(){
      
      return this.$route.path;
    },
    
    columns(){
      return [
        'name',
        'department',
        'frequency'
      ]
    }
  },
  watch:{
    page(v){
      this.isLoaded = false;
      this.forms = [];
      this.getForms();
    }
  }
 
};
</script>
<style scoped lang="scss">
@import "@/assets/scss.scss";
.f-name{
        font-size:15pt;
        
       font-weight: bold;
        
        i{
            color:var(--app-color);
            margin-right:10px;
        }
        
    }
.inner-stage{
  display:flex;
  justify-content: center;
}

.forms-container{
  display:flex;
  flex-direction: column;
  width:100%;
  padding:16px;
  gap:15px;
.form-item{
  cursor: pointer;
  max-width:100%;
  text-align: left;
  width:100%;
  height:100px;
  display:flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: $box-shadow;
  background:#fff;
  padding:25px;
  transition:all .3s ease;
  &:hover{
    box-shadow:rgba(23, 48, 73, 0.32) 0px 7px 12px;
  }
  i{
    margin-right:20px !important;
  }
  .form-name{
    font-size:15pt;
    font-weight: bold;
  }
}

}
</style>